import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './CardIcon.css';

const styles = theme => ({
  actions: {
    display: 'flex',
    padding: 0
  },
  expand: {
    opacity: 1,
    float: 'right',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    }),
    [theme.breakpoints.up('sm')]: {
      marginTop: -54
    }
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    marginTop: -54
  },
  div: {
    position: 'relative',
    paddingTop: 100
  },
  avatar: {
    borderRadius: 5,
    backgroundColor: '#42ADE8',
    width: 54,
    height: 54,
    left: 15,
    margin: 0,
    marginRight: '15px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)'
  },
  divTitulo: {
    background: theme.palette.card,
    height: 60,
    marginRight: -30,
    marginTop: -30,
    marginLeft: -30,
    alignItems: 'center',
    display: 'flex'
  },
  divTituloExpandBotton: {
    flexDirection: 'row',
    paddingTop: 0,
    paddingBottom: 0,
    padding: 0,
    height: 'auto'
  },
  titulo: {
    marginLeft: 30,
    fontWeight: 500,
    fontSize: 20,
    color: 'rgb(0,0,0,0.6)'
  }
});

class CardIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = { expanded: true };
    this.handleExpandClick = this.handleExpandClick.bind(this);
  }

  handleExpandClick() {
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    const { classes } = this.props;
    const stylesDiv = {
      paddingLeft: this.props.usePaddings ? 15 : 0,
      paddingRight: this.props.usePaddings ? 15 : 0,
      paddingTop: 1,
      ...this.props.extraStyles,
      ...this.props.containerCardStyle
    };
    const stylesCard = {
      borderRadius: this.props.usePaddings ? 5 : 0,
      overflow: 'visible',
      padding: 30,
      paddingBottom: this.state.expanded
        ? this.props.customPadding
          ? this.props.customPadding
          : 30
        : 0,
      marginBottom: this.props.useMarginBottom ? this.props.useMarginBottom : 0,
      ...this.props.extraStyles,
      ...this.props.cardStyles
    };

    return (
      <div className={classes.div} style={stylesDiv}>
        <Card style={stylesCard}>
          {!this.props.disableHeader && (
            <CardContent className={classes.divTituloExpandBotton}>
              <div className={classes.divTitulo} style={this.props.margins}>
                {this.state.expanded ? (
                  <div className={classes.titulo}>{this.props.titulo}</div>
                ) : (
                  <div className={classes.titulo}>{this.props.header}</div>
                )}
                {/* header:Componentes do card fechado*/}
              </div>
              <div>
                {!this.props.disableExpandButton && (
                  <IconButton
                    className={classnames(classes.expand, {
                      [classes.expandOpen]: this.state.expanded
                    })}
                    onClick={this.handleExpandClick}
                    aria-expanded={this.state.expanded}
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                )}
              </div>
            </CardContent>
          )}
          <CardActions className={classes.actions} />
          <Collapse
            in={this.state.expanded}
            timeout='auto'
            unmountOnExit
            style={{
              overflow: this.props.filtros ? null : 'visible',
              width: '100%'
            }}
            className={this.state.expanded ? 'run-animation-open' : 'run-animation-close'}
          >
            <CardContent style={{ padding: 0, ...this.props.cardContentStyle }}>
              {this.props.children}
            </CardContent>
          </Collapse>
        </Card>
      </div>
    );
  }
}

CardIcon.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CardIcon);
