import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getUserLang } from '@utils/localeUtils';
import { withStyles } from '@material-ui/core/styles';
import CardFilters from '@components/CardFilters/CardFilters';
import FilterUtil from '../filterUtil';
import FabDiamond from '@components/FABDiamond/FABDiamond';
import SwalMessage from '@utils/functions/SwalMessage/SwalMessage';
import { getAll, deleteConfig } from '@resources/api/configuracaoAgenda';
import { DataTable, GroupButtonGrid } from '@kepha/sumora-react-components';
import * as moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import SwalConfirmacao from '@utils/functions/SwalConfirmacao/SwalConfirmacao';
import swal from '@sweetalert/with-react';

import '@root/App.css';

const iconAdd = require('@images/ic_add_white.png');

const style = () => ({
  fab: {
    position: 'fixed',
    right: '15px',
    bottom: '40px',
    zIndex: '2000'
  },
  containerTable: {
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'column'
  },
  card: {
    height: 70,
    width: '100%',
    marginTop: '30px'
  }
});

/**
 * Tela de listagem de configuração de agenda
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 */
class ConfiguracaoAgenda extends Component {
  constructor(props) {
    super(props);

    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.tratarTp = this.tratarTp.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.tratarSituacao = this.tratarSituacao.bind(this);
    this.tratarAgenda = this.tratarAgenda.bind(this);
    this.tratarTpProducao = this.tratarTpProducao.bind(this);
    this.handleDelete = this.handleDelete.bind(this);

    this.state = {
      configAgenda: {},
      infoExtra: [],
      validarSenha: false,
      selected: undefined, // controla a linha selecionada
      dataAppSync: [], // dados que vem da api
      dataInTable: [],
      operationValues: [
        {
          label: 'Descrição',
          field: 'dsConfigAgenda',
          type: 'string'
        },
        {
          label: 'Tipo de agenda',
          field: 'tpAgenda',
          type: 'enum',
          options: [
            {
              label: 'Comercial',
              field: 'comercial',
              value: '0'
            },
            {
              label: 'Produção',
              field: 'producao',
              value: '1'
            },
            {
              label: 'Ambas',
              field: 'ambas',
              value: '2'
            }
          ]
        },
        {
          label: 'Início',
          field: 'dtInicial',
          type: 'date'
        },
        {
          label: 'Término',
          field: 'dtFinal',
          type: 'date'
        },
        {
          label: 'Slots',
          field: 'nrSlots',
          type: 'number'
        },
        {
          label: 'Produção',
          field: 'stBloqueio',
          type: 'enum',
          options: [
            {
              label: 'Não',
              field: 'nao',
              value: '1'
            },
            {
              label: 'Sim',
              field: 'sim',
              value: '0'
            }
          ]
        },
        {
          label: 'Recorrência',
          field: 'stRecorrente',
          type: 'enum',
          options: [
            {
              label: 'Não',
              field: 'nao',
              value: '0'
            },
            {
              label: 'Sim',
              field: 'sim',
              value: '1'
            }
          ]
        },
        {
          label: 'Situação',
          field: 'stConfigAgenda',
          type: 'enum',
          options: [
            {
              label: 'Ativo',
              field: 'ativo',
              value: '1'
            },
            {
              label: 'Inativo',
              field: 'inativo',
              value: '0'
            }
          ]
        }
      ]
    };
    this.props.dispatch({
      type: 'UPDATE_TOOLBAR',
      toolbar: 'CONFIGURAÇÃO'
    });
  }

  /**
   * método chamado ao inicializar a tela
   */
  componentDidMount() {
    this.props.dispatch({
      type: 'CLEAN_CHIPS'
    });
    this._isMounted = true;
    this.onFilter();
  }

  /**
   * Controla item seleciona na grid
   *
   * @param selected - item selecionado
   */
  onSelect(selected) {
    if (this._isMounted === true) {
      this.setState({
        selected: selected
      });
    }
  }

  /**
   * Trata variavel de situação para label correspondente
   *
   * @param situacao - stSituacao
   */
  tratarSituacao(situacao) {
    switch (parseInt(situacao)) {
      case 0:
        return 'Inativo';
      case 1:
        return 'Ativo';
      default:
        break;
    }
  }

  /**
   * Trata a lista da grid
   *
   * @param {} dataChips - lista de filtros
   */
  async onFilter(dataChips) {
    dataChips = dataChips || [];

    if (this.state.dataAppSync.length > 0) {
      return;
    } else {
      getAll()
        .then(async res => {
          this.setState({
            dataInTable: FilterUtil.applyFilters(res.data, dataChips),
            dataAppSync: res.data
          });
        })
        .catch(err => {
          SwalMessage({
            title: 'Falha',
            text: 'Falha ao carregar página',
            err,
            callback: () => {
              this._isMounted && this.setState({ dataAppSync: [] });
            }
          });
        });
    }
  }

  /**
   * Trata os filtros da grid
   *
   * @param dataChips - filtros
   */
  onFilterChanged(dataChips) {
    this.setState({
      dataInTable: FilterUtil.applyFilters(this.state.dataAppSync, dataChips)
    });
  }

  /**
   * Executado ao sair da lista
   */
  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * Clique do FAB de adicionar
   */
  handleAdd() {
    this.props.history.push('/app/config-agenda/novo');
  }

  /**
   * Clique do botãod e editar
   */
  handleEdit() {
    this.props.history.push({
      pathname: '/app/config-agenda/editar/' + this.state.selected.idConfigAgenda,
      state: { configAgenda: this.state.selected }
    });
  }

  /**
   * Retorna o label da situação correspondente
   *
   * @param situacao - número da situação
   */
  tratarTp(situacao) {
    switch (parseInt(situacao)) {
      case 0:
        return 'Não';
      case 1:
        return 'Sim';
      default:
        break;
    }
  }

  /**
   * Retorna o label da situação correspondente
   *
   * @param situacao - número da situação
   */
  tratarTpProducao(situacao) {
    switch (parseInt(situacao)) {
      case 0:
        return 'Sim';
      case 1:
        return 'Não';
      default:
        break;
    }
  }

  /**
   * Trata tipo da agenda
   *
   * @param situacao - tipo da agenda
   */
  tratarAgenda(situacao) {
    switch (parseInt(situacao)) {
      case 0:
        return 'Comercial';
      case 1:
        return 'Produção';
      case 2:
        return 'Ambas';
      default:
        break;
    }
  }

  //Rotina para remoção de dados
  handleDelete() {
    SwalConfirmacao({
      text: 'Tem certeza que deseja remover essa configuração de agenda?'
    }).then(res => {
      if (res) {
        swal(
          <div>
            <CircularProgress />
          </div>,
          {
            buttons: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
            title: 'Excluindo'
          }
        );
        deleteConfig(this.state.selected.idConfigAgenda)
          .then(res => {
            var data = this.state.dataAppSync;
            let index = data.indexOf(this.state.selected);
            if (index > -1) {
              data.splice(index, 1);
              data = [...data];

              this._isMounted &&
                this.setState(
                  {
                    selected: undefined,
                    dataAppSync: data,
                    dataInTable: FilterUtil.applyFilters(data, [])
                  },
                  () => {
                    SwalMessage({
                      icon: 'success',
                      title: 'Sucesso',
                      text: 'Configuração de agenda removida com sucesso',
                      callback: () => {
                        this.forceUpdate();
                      }
                    });
                  }
                );
            }
          })
          .catch(err => {
            SwalMessage({
              title: 'Erro',
              text: 'Não foi possível deletar essa configuração de agenda, tente novamente'
            });
          });
      }
    });
  }

  render() {
    const { classes } = this.props;

    let head = [
      {
        field: 'dsConfigAgenda',
        headerName: 'Descrição',
        col: 6,
        suppressSizeToFit: true,
        pinned: 'left'
      },
      {
        valueGetter: args => this.tratarAgenda(args.node.data.tpAgenda),
        headerName: 'Tipo de agenda',
        col: 6,
        suppressSizeToFit: true,
        pinned: 'left'
      },
      {
        headerName: 'Início',
        valueGetter: args => moment(args.node.data.dtInicial.split('T')[0]).format('DD/MM/YY'),
        col: 2
      },
      {
        headerName: 'Término',
        valueGetter: args => {
          if (args.node.data.dtFinal) {
            return moment(args.node.data.dtFinal.split('T')[0]).format('DD/MM/YY');
          } else {
            return '';
          }
        },
        col: 2,
        suppressSizeToFit: true
      },
      {
        field: 'nrSlots',
        headerName: 'Slots',
        col: 2,
        suppressSizeToFit: true
      },
      {
        valueGetter: args => this.tratarTpProducao(args.node.data.stBloqueio),
        headerName: 'Produção',
        col: 2
      },
      {
        valueGetter: args => this.tratarTp(args.node.data.stRecorrente),
        headerName: 'Recorrência',
        col: 2
      },
      {
        valueGetter: args => this.tratarSituacao(args.node.data.stConfigAgenda),
        headerName: 'Situação',
        col: 2,
        suppressSizeToFit: true
      }
    ];
    const pagination = {
      length: this.state.dataInTable.length,
      page: 0
    };

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          marginTop: 10
        }}
      >
        <div
          ref={divElement => {
            this.divElement = divElement;
          }}
        >
          <CardFilters
            filtros={this.state.operationValues}
            onFilter={this.onFilter}
            onFilterChanged={this.onFilterChanged}
            usePaddings={false}
            viewKey='lojas'
          ></CardFilters>
        </div>

        <div
          style={{
            marginTop: 10,
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <GroupButtonGrid
            showAdd={false}
            showEdit={true}
            showDelete={true}
            disableDelete={this.state.selected === undefined}
            disableEdit={this.state.selected === undefined}
            onClickDelete={this.handleDelete}
            onClickEdit={this.handleEdit}
            deleteColor='#5F4C3E'
            editColor='#5F4C3E'
          />
          <DataTable
            marginTop
            data={this.state.dataInTable}
            columns={head}
            userlang={getUserLang()}
            selectChange={this.onSelect}
            showPagination={true}
            pagination={pagination}
            handleChangePage={() => {}}
            handleChangeRowsPerPage={() => {}}
          />
        </div>
        <div className={classes.fab} onClick={this.handleAdd}>
          <FabDiamond
            url={iconAdd}
            imageStyle={{
              height: '28px',
              width: '28px',
              padding: '5px 5px 0px 0px'
            }}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  UsuarioLogado: state.usuarioReducer,
  itensState: state.toolbar
});

let enhaced = withStyles(style)(ConfiguracaoAgenda);
export default connect(mapStateToProps)(withRouter(enhaced));
