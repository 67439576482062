//@author Gabriela Farias
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import flattenMessages from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import Logo from '@images/logo_azagros.png';
import { Loading } from '@kepha/sumora-react-components';
import './ConfirmarConta.css';
import classNames from 'classnames';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

var { provider } = new IntlProvider({});

const styles = {
  card: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    height: '100%',
    position: 'absolute',
    width: '100%',
    flexDirection: 'column'
  },
  text: {
    fontSize: 18,
    marginTop: 30,
    fontWeight: 400,
    color: 'rgb(0, 0, 0, 0.6)'
  }
};

class ConfirmarConta extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const aguarde = messagesLinguage['confirmarConta.aguarde'];
    const falhaAoCarregarDados = messagesLinguage['confirmarConta.falhaAoCarregarDados'];
    const confirmacao = messagesLinguage['confirmarConta.confirmacao'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          aguarde,
          falhaAoCarregarDados,
          confirmacao
        }
      },
      {}
    );

    provider = intlProvider.getChildContext();

    this.state = {
      carregando: true,
      erroCarregar: false
    };
  }

  componentDidMount() {}

  render() {
    const { classes } = this.props;

    return (
      <div>
        {!this.state.erroCarregar && this.state.carregando ? <Loading /> : <div />}
        <div
          className={classNames(
            classes.card,
            this.state.carregando ? 'run-write-open' : 'run-write-close'
          )}
        >
          <img src={Logo} alt='Logomarca Azagros' />
          <div className={classes.text}>{provider.intl.formatMessage({ id: 'aguarde' })}</div>
        </div>

        <div
          className={classNames(
            classes.card,
            this.state.carregando ? 'run-write-open-sucess' : 'run-write-close-sucess'
          )}
        >
          <img
            style={{
              position: 'absolute',
              marginTop: -27
            }}
            src={Logo}
            alt='Logomarca Azagros'
          />
          <div
            className={classes.text}
            style={{
              position: 'absolute',
              marginTop: 64
            }}
          >
            {!this.state.erroCarregar && provider.intl.formatMessage({ id: 'confirmacao' })}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ConfirmarConta);
