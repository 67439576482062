var vendedorList = []

const adicionaListaDeVendedor = (state = vendedorList, action) => {
  switch (action.type) {
    case 'ADD_VENDEDORES':
      vendedorList = action.list
      return vendedorList
    default:
      return state
  }
}

export default adicionaListaDeVendedor
