import swal from '@sweetalert/with-react';

const SwalMessage = ({
  title = '',
  text = '',
  icon = 'error',
  config,
  err,
  callback
}) => {
  err &&
    //process.env.REACT_APP_INDEV === 'true' && // TODO: No futuro validar se environment é dev para loggar erro
    console.log(
      '\n',
      new Error(err),
      '\n',
      err,
      '\n',
      JSON.stringify(err, null, 2),
      '\n',
      err.response ? err.response : 'no response'
    );

  callback && callback();

  return swal(title, text, {
    icon,
    buttons: {
      confirm: {
        text: 'Ok',
        value: true,
        visible: true,
        closeModal: true,
        className: 'swal2-error'
      }
    },
    ...config
  });
};

export default SwalMessage;
