import axios from 'axios'

const BASEAPI = process.env.REACT_APP_URL_API
const ENDPOINT = '/municipio'

const getMunicipioById = async id => {
  return await axios.get(`${BASEAPI + ENDPOINT}/${id}`)
}

export { getMunicipioById }
