export default {
  tecnico: {
    title: 'TÉCNICOS',
    tel: 'Telefone',
    nomeCol: 'Nome',
    email: 'E-mail',
    uf: 'Estado',
    cidade: 'Cidade'
  }
};
