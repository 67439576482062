export default {
  cliente: {
    title: 'CLIENTES',
    tel: 'Telefone',
    nomeCol: 'Nome',
    email: 'E-mail',
    uf: 'Estado',
    cidade: 'Cidade',
  },
}
