import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { DataTable, GroupButtonGrid } from '@kepha/sumora-react-components';
import CardFilters from '@components/CardFilters/CardFilters';
import FilterUtil from '../filterUtil';
import style from './style/style';
import { getUserLang } from '@utils/localeUtils';
import '@root/App.css';
import { head, headGerente, headAdmin } from './tableHead';
import SwalMessage from '@utils/functions/SwalMessage/SwalMessage';
import { findAllProjetos } from '@resources/api/projeto';

class Projetos extends React.Component {
  constructor(props) {
    super(props);

    const cliente = {
      label: 'Cliente',
      field: 'nmCliente',
      type: 'string'
    };

    this.operationCliente = cliente;

    const consultor = {
      label: 'Consultor',
      field: 'nmUsuario',
      type: 'string'
    };

    this.operationAdmin = [
      {
        label: 'Loja',
        field: 'nmLoja',
        type: 'string'
      },
      cliente,
      consultor
    ];

    this.operationGerente = [cliente, consultor];

    this.operationValues = [
      {
        label: 'Contrato',
        field: 'nrContrato',
        type: 'string'
      },
      {
        label: 'Data',
        field: 'dtPrevisaoEntrega',
        type: 'date'
      },
      {
        label: 'Ambiente',
        field: 'nmAmbiente',
        type: 'string'
      },
      {
        label: 'Acabamento',
        field: 'nmAcabamento',
        type: 'string'
      },
      {
        label: 'Aprovado',
        field: 'stProjetoExecutivo',
        type: 'enum',
        options: [
          {
            label: 'Não',
            field: 'nao',
            value: '0'
          },
          {
            label: 'Sim',
            field: 'sim',
            value: '1'
          }
        ]
      }
    ];

    this.state = {
      operationValues: [...this.operationValues],
      selected: undefined, // controla a linha selecionada
      dataAppSync: [], // dados que vem da api
      dataInTable: []
    };

    this.props.dispatch({
      type: 'UPDATE_TOOLBAR',
      toolbar: 'PROJETOS'
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleEdit = () => {
    this.props.history.push({
      pathname: '/app/contratos/editar/' + this.state.selected.idContrato,
      state: {
        isFromProjetos: true,
        itemContrato: {
          ...this.state.selected.itemContrato,
          projetoExecutivo: {
            dhAlteracao: this.state.selected.dhAlteracao,
            dsProjetoExecutivo: this.state.selected.dsProjetoExecutivo,
            dsS3: this.state.selected.dsS3,
            idProjetoExecutivo: this.state.selected.idProjetoExecutivo,
            stProjetoExecutivo: this.state.selected.stProjetoExecutivo
          }
        }
      }
    });
  };

  onFilter = async dataChips => {
    dataChips = dataChips || [];

    findAllProjetos()
      .then(async res => {
        var data = res.data.map(async item => {
          item.nmItemContrato = ['Não', 'Sim'][item.stProjetoExecutivo];
          item.nmAcabamento = item.itemContrato.acabamento.nmAcabamento;
          item.nmAmbiente = item.itemContrato.ambiente.nmAmbiente;
          item.nmCliente = item.contrato.cliente.nmCliente;
          item.nmLoja = item.contrato.loja.nmLoja;
          item.nmUsuario = item.contrato.vendedor.nmUsuario;
          item.nrContrato = item.contrato.nrContrato;
          item.dtPrevisaoEntrega = item.itemContrato.dtPrevisaoEntrega;
          item.idContrato = item.contrato.idContrato;

          return item;
        });

        const contratos = await Promise.all(data);
        this.setState({
          dataInTable: FilterUtil.applyFilters(contratos, dataChips),
          dataAppSync: contratos
        });
      })
      .catch(err => {
        SwalMessage({
          title: 'Falha',
          text: 'Falha ao carregar página',
          err,
          callback: () => {
            this._isMounted && this.setState({ dataAppSync: [] });
          }
        });
      });
  };

  onSelect = selected => {
    if (this._isMounted === true) {
      this.setState({
        selected: selected
      });
    }
  };
  onFilterChanged = dataChips => {
    this.setState({
      dataInTable: FilterUtil.applyFilters(this.state.dataAppSync, dataChips)
    });
  };
  componentDidMount() {
    this.props.dispatch({
      type: 'CLEAN_CHIPS'
    });
    this._isMounted = true;
    this.onFilter();
  }

  render() {
    const { classes } = this.props;
    const pagination = {
      length: this.state.dataInTable.length,
      page: 0
    };

    let operationValues = this.state.operationValues;
    let headData = head;
    let keyData = 0;
    if (this.props.usuarioLogado && this.props.usuarioLogado.tpUsuario === 0) {
      headData = headAdmin;
      operationValues = [...this.operationAdmin, ...this.operationValues];
      keyData = 1;
    } else if (this.props.usuarioLogado && this.props.usuarioLogado.tpUsuario === 1) {
      headData = headGerente;
      operationValues = [...this.operationGerente, ...this.operationValues];
      keyData = 2;
    } else {
      operationValues = [this.operationCliente, ...operationValues];
    }

    return (
      <div className={classes.mainDiv}>
        <div>
          <CardFilters
            filtros={operationValues}
            onFilter={this.onFilter}
            onFilterChanged={this.onFilterChanged}
            usePaddings={false}
            viewKey='lojas'
          />
        </div>

        <div className={classes.buttonGrid}>
          <GroupButtonGrid
            showAdd={false}
            showEdit={true}
            showDelete={false}
            disableDelete={this.state.selected === undefined}
            disableEdit={this.state.selected === undefined}
            onClickDelete={this.handleDelete}
            onClickEdit={this.handleEdit}
            deleteColor='#5F4C3E'
            editColor='#5F4C3E'
          />
          <DataTable
            key={keyData}
            marginTop
            data={this.state.dataInTable}
            columns={headData}
            userlang={getUserLang()}
            selectChange={this.onSelect}
            showPagination={true}
            pagination={pagination}
            handleChangePage={() => {}}
            handleChangeRowsPerPage={() => {}}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  usuarioLogado: state.usuarioReducer,
  itensState: state.toolbar,
  estadoList: state.estadoReducer,
  municipioList: state.municipioReducer,
  getMunicipio: state.getMunicipio,
  lojaList: state.lojaReducer
});
let enhanced = withStyles(style)(Projetos);
export default connect(mapStateToProps)(withRouter(enhanced));
