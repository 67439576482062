import React from 'react';
import ReactDOM from 'react-dom';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 9998,
    height: '100vh',
    width: '100vw',
    backgroundColor: 'transparent'
  },
  containerOptions: {
    position: 'absolute',
    zIndex: 9999,
    border: '1px solid rgba(0,0,0,0.42)',
    borderRadius: 5,
    backgroundColor: 'white'
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 15,
    cursor: 'pointer',
    borderRadius: 5,
    '&:hover': {
      backgroundColor: '#eeeeee'
    }
  }
});

/**
 * Componente para exibição de opção em algum lugar da tela
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 * @param {*} props
 */
function Options(props) {
  const {
    classes,
    position: { x, y } = {},
    options = [],
    children,
    onClickOutside,
    RootDivContainerProps = {},
    BackgroundDivProps = {},
    DivContainerOptions = {},
    detalhesOptions = {}
  } = props;

  const component =
    x !== undefined && y !== undefined ? (
      <div {...RootDivContainerProps}>
        <div
          {...BackgroundDivProps}
          className={`${classes.background} ${BackgroundDivProps.className || ''}`}
          onClick={e => onClickOutside && onClickOutside(e)}
        />

        <div
          {...DivContainerOptions}
          style={{ top: y, left: x }}
          className={`${classes.containerOptions} ${DivContainerOptions.className || ''}`}
        >
          {detalhesOptions}
          {!children
            ? (options || []).map((opt, i) => (
                <div {...opt} key={i} className={`${classes.option} ${opt.className || ''}`}>
                  {opt.label}
                </div>
              ))
            : children}
        </div>
      </div>
    ) : (
      <></>
    );

  return ReactDOM.createPortal(component, document.getElementById('root'));
}

export default withStyles(styles)(Options);
