import React, { useRef, useEffect, useState } from 'react';
import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listGridPlugin from '@fullcalendar/list';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './comercial.styles.css';
import moment from 'moment-business-days';
import Tooltip from '@material-ui/core/Tooltip';
import { findItensPendentes, findItensProducao, updateItemContrato } from '@resources/api/comercial';
import swal from '@sweetalert/with-react';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import Options from '@components/Options/Options';
import SwalConfirmacao from '@utils/functions/SwalConfirmacao/SwalConfirmacao';

const heightAvailable = 'calc(100vh - 108px)';

const colorItemCalendar = '#3399B4';
const colorItemCalendarExceeded = 'rgb(243, 58, 48)';

const Comercial = props => {
  moment.locale('pt-BR');
  const calendarRef = useRef(null);

  const [positionOptions, setPositionOptions] = useState();
  const [isLoading, setLoading] = useState(true);
  const [itensToDrag, setItensToDrag] = useState([]);
  const [itens, setItens] = useState([]);
  const [calendarData, setCalendarData] = useState([]);
  const [disabledDays, setDisabledDays] = useState([]);
  const [viewType, setViewType] = useState('');
  const [configsAgenda, setConfigsAgenda] = useState([]);

  const [dateSelected, _setDateSelected] = useState(moment().date(1));
  const dateSelectedRef = useRef(dateSelected);

  useEffect(() => {
    updateCalendarSize();
  }, [props.menuIsOpen]);

  useEffect(() => {
    findDataOfCalendar();
  }, [dateSelectedRef.current]);

  useEffect(() => {
    props.dispatch({
      type: 'UPDATE_TOOLBAR',
      toolbar: 'COMERCIAL'
    });

    const draggableEl = document.getElementById('external-events');
    new Draggable(draggableEl, {
      itemSelector: '.fc-event',
      eventData: eventEl => ({ id: eventEl.getAttribute('id') })
    });

    addHandleEvents();
    return () => removeHandleEvents();
  }, []);
  let valuesItem;
  if (positionOptions) {
    if (itens) {
      valuesItem = itens.find(subItem => subItem.idItemContrato === positionOptions.id);
    } else {
      valuesItem = [];
    }
  }

  return (
    <>
      <Options
        position={positionOptions}
        onClickOutside={() => {
          setPositionOptions(undefined);
        }}
        detalhesOptions={
          valuesItem && (
            <div className={'event-to-drag'}>
              <span>{`${valuesItem.ambiente.nmAmbiente} - ${valuesItem.acabamento.nmAcabamento}`}</span>
              <br />
              <span>{`${valuesItem.contrato.cliente.nmCliente} - Contrato ${valuesItem.contrato.nrContrato}`}</span>
              <br />
              <span>{`Loja ${valuesItem.contrato.loja.nrCodigo}`}</span>
            </div>
          )
        }
        options={
          props.usuarioLogado && props.usuarioLogado.tpUsuario === 0
            ? [
                {
                  label: 'Detalhes',
                  onClick: () => {
                    const item = itens.find(subItem => subItem.idItemContrato === positionOptions.id);
                    props.history.push({
                      pathname: `/app/contratos/editar/${item.contrato.idContrato}`,
                      state: { isFromCalendar: true, itemContrato: item }
                    });
                  }
                },
                {
                  label: 'Remover da produção',
                  onClick: handleRemoveItem
                }
              ]
            : []
        }
      />

      {isLoading && (
        <LinearProgress
          style={{
            position: 'absolute',
            zIndex: 99999,
            height: 3.5,
            width: '100vw',
            top: 0,
            backgroundColor: 'white'
          }}
        />
      )}

      <div
        style={{
          width: '100%',
          paddingTop: 32,
          paddingLeft: 15,
          paddingRight: 15,
          overflow: 'auto',
          display: 'flex',
          height: 'calc(100vh - 78px)'
        }}
      >
        <div id='external-events' className='painel-eventos-pendentes'>
          <div className='header-painel-eventos-pendentes'>
            <p align='center'>Itens para produção</p>
          </div>

          <Tooltip title='Clique e arraste o item em uma data no calendário'>
            <div className='content-painel-eventos-pendentes'>
              {itensToDrag.map((item, i) => (
                <div
                  key={i}
                  className={
                    props.usuarioLogado && props.usuarioLogado.tpUsuario === 0
                      ? 'fc-event event-to-drag'
                      : 'event-to-drag'
                  }
                  style={{ backgroundColor: item.color, borderColor: item.color, color: '#FFFFFF' }}
                  id={item.idItemContrato}
                >
                  <span>{`${item.ambiente.nmAmbiente} - ${item.acabamento.nmAcabamento}`}</span>
                  <br />
                  <span>{`${item.contrato.cliente.nmCliente} - Contrato ${item.contrato.nrContrato}`}</span>
                  <br />
                  <span>{`Loja ${item.contrato.loja.nrCodigo}`}</span>
                  <br />
                  <span>{`Previsão: ${moment(item.dtPrevisaoEntrega).format('DD/MM/YYYY')} - ${
                    item.acabamento.nrDias
                  } dias`}</span>
                </div>
              ))}
            </div>
          </Tooltip>
        </div>

        <div style={{ width: '80%' }}>
          <FullCalendar
            eventContent={renderEventContent}
            plugins={[dayGridPlugin, timeGridPlugin, listGridPlugin, interactionPlugin]}
            height={heightAvailable}
            ref={calendarRef}
            locale={ptBrLocale}
            droppable={!isLoading && props.usuarioLogado && props.usuarioLogado.tpUsuario === 0}
            editable={!isLoading && props.usuarioLogado && props.usuarioLogado.tpUsuario === 0}
            initialView='dayGridMonth'
            headerToolbar={{
              right: 'today,prev,next',
              center: 'title',
              left: 'dayGridMonth,dayGridWeek,dayGridDay'
            }}
            hiddenDays={viewType === 'dayGridWeek' ? [0, 6] : undefined}
            events={[...calendarData, ...disabledDays, ...getWeekendsDisabledDays()]}
            eventReceive={handleDropItemInCalendar}
            eventDrop={handleChangePositionOfItem}
            eventClick={handleClickItem}
            navLinks={true}
          />
        </div>
      </div>
    </>
  );

  function renderEventContent(eventInfo) {
    if (eventInfo.event.extendedProps.item) {
      if (eventInfo.view.type === 'dayGridMonth') {
        return (
          <div
            style={{
              whiteSpace: 'nowrap',
              width: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              padding: 1,
              backgroundColor: '#3399B4 !important'
            }}
          >
            {eventInfo.event.title}
          </div>
        );
      }
      return (
        <div
          style={{
            whiteSpace: 'nowrap',
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            padding: eventInfo.view.type === 'dayGridDay' ? 10 : 1,
            backgroundColor: '#3399B4 !important'
          }}
        >
          <b>{eventInfo.event.extendedProps.item.descricao}</b>
          <b>{eventInfo.event.title}</b>
          <br />
          <span>{`${eventInfo.event.extendedProps.item.ambiente.nmAmbiente} - ${eventInfo.event.extendedProps.item.acabamento.nmAcabamento}`}</span>
          <br />
          <span>{`${eventInfo.event.extendedProps.item.contrato.cliente.nmCliente} - Contrato ${eventInfo.event.extendedProps.item.contrato.nrContrato}`}</span>
          <br />
          <span>{`Previsão: ${moment(eventInfo.event.extendedProps.item.dtPrevisaoEntrega).format(
            'DD/MM/YYYY'
          )} - ${eventInfo.event.extendedProps.item.acabamento.nrDias} dias`}</span>
        </div>
      );
    } else {
      return eventInfo.event.title ? (
        <div
          style={{
            whiteSpace: 'nowrap',
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            padding: 1,
            backgroundColor: 'rgba(149, 152, 154, 1)',
            top: eventInfo.event.extendedProps.level * 19 + 30,
            position: 'relative',
            borderRadius: 3
          }}
        >
          {eventInfo.event.title}
        </div>
      ) : (
        <div />
      );
    }
  }

  function handleRemoveItem() {
    const idItem = positionOptions.id;
    setPositionOptions(undefined);

    SwalConfirmacao({
      text: 'Tem certeza que deseja remover este item da produção?',
      inverteButtonsColors: true
    }).then(confirmation => {
      if (confirmation) {
        setLoading(true);

        const indexItem = itens.findIndex(subItem => subItem.idItemContrato === idItem);
        const item = { ...itens[indexItem] };

        const date = item.dtPrevisaoComercial;
        item.dtPrevisaoComercial = null;

        updateItemContrato(item)
          .then(res => {
            const auxCalendarItens = [...calendarData];
            const indexCalendarItem = auxCalendarItens.findIndex(subItem => subItem.id === idItem);
            auxCalendarItens.splice(indexCalendarItem, 1);
            auxCalendarItens.forEach(calendarItem => {
              if (calendarItem.date === date) {
                calendarItem.backgroundColor = colorItemCalendar;
                calendarItem.borderColor = colorItemCalendar;
              }
            });
            setCalendarData(auxCalendarItens);

            res.data.color = colorItemCalendar;
            setItensToDrag([...itensToDrag, res.data]);

            const auxItens = [...itens];
            auxItens.splice(indexItem, 1);
            setItens(auxItens);
          })
          .catch(err => {
            swal('Erro ao remover o item da produção, tente novamente', {
              icon: 'error',
              confirmButtonColor: '#8CD4F5',
              buttons: {
                confirm: {
                  confirmButtonColor: '#8CD4F5',
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-Ok'
                }
              }
            });
          })
          .finally(() => setLoading(false));
      }
    });
  }

  function handleClickItem(args) {
    if (args.event._def.publicId) {
      setPositionOptions({ x: args.jsEvent.x, y: args.jsEvent.y, id: args.event._def.publicId });
    }
  }

  function handleChangePositionOfItem(args) {
    args.revert();

    const date = moment(args.event.start).format('YYYY-MM-DD');
    if (isDayDisabled(date)) return;
    if (isWeekendDisabled(date)) return;

    const qtdInTheDay = calendarData.filter(item => date === moment(item.date).format('YYYY-MM-DD'))
      .length;

    let limitItens = 5;

    const configItemAgenda = getConfigAgenda(date);
    if (configItemAgenda !== undefined && configItemAgenda.nrSlots) {
      limitItens = configItemAgenda.nrSlots;
    }
    if (qtdInTheDay >= limitItens) {
      swal('Máximo de itens adicionados neste dia excedido', {
        icon: 'error',
        confirmButtonColor: '#8CD4F5',
        buttons: {
          confirm: {
            confirmButtonColor: '#8CD4F5',
            text: 'Ok',
            value: true,
            visible: true,
            closeModal: true,
            className: 'swal2-Ok'
          }
        }
      });
      return;
    }

    setLoading(true);

    const itemEdited = itens.find(item => item.idItemContrato === args.event._def.publicId);
    itemEdited.dtPrevisaoComercial = moment(args.event.start).format('YYYY-MM-DD');

    updateItemContrato(itemEdited)
      .then(res => {
        setItens([...itens, res.data]);

        const auxListItens = [...calendarData];
        const indexCalendarItem = auxListItens.findIndex(item => item.id === args.event._def.publicId);
        auxListItens[indexCalendarItem] = {
          ...auxListItens[indexCalendarItem],
          date: res.data.dtPrevisaoComercial
        };

        const oldDate = moment(args.oldEvent.start).format('YYYY-MM-DD');
        auxListItens.forEach((item, i) => {
          if (item.date === date && qtdInTheDay === 4) {
            auxListItens[i].backgroundColor = colorItemCalendarExceeded;
            auxListItens[i].borderColor = colorItemCalendarExceeded;
          } else if (item.date === oldDate || (item.date === date && qtdInTheDay < 4)) {
            auxListItens[i].backgroundColor = colorItemCalendar;
            auxListItens[i].borderColor = colorItemCalendar;
          }
        });

        setCalendarData(auxListItens);
      })
      .catch(err => {
        swal('Erro ao marcar o item do contrato nesta data, tente novamente', {
          icon: 'error',
          confirmButtonColor: '#8CD4F5',
          buttons: {
            confirm: {
              confirmButtonColor: '#8CD4F5',
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-Ok'
            }
          }
        });
      })
      .finally(() => setLoading(false));
  }

  function isWeekendDisabled(date) {
    const dateMoment = moment(date);

    for (let item of configsAgenda) {
      if (!item.dtFinal && !dateMoment.isSame(item.dtInicial)) {
        continue;
      }

      if (!dateMoment.isSameOrAfter(item.dtInicial) || !dateMoment.isSameOrBefore(item.dtFinal)) {
        continue;
      }

      if (dateMoment.weekday() === 6 && item.stBloqueiaSabado === 1) {
        return true;
      }

      if (dateMoment.weekday() === 0 && item.stBloqueiaDomingo === 1) {
        return true;
      }
    }

    return false;
  }

  function getConfigAgenda(date) {
    const dateMoment = moment(date);

    let returnItem;

    for (let item of configsAgenda) {
      if (!item.dtFinal && !dateMoment.isSame(item.dtInicial)) {
        continue;
      }

      if (!dateMoment.isSameOrAfter(item.dtInicial) || !dateMoment.isSameOrBefore(item.dtFinal)) {
        continue;
      }

      if (returnItem === undefined || returnItem.nrSlots < item.nrSlots) {
        returnItem = item;
      }
    }

    return returnItem;
  }

  async function handleDropItemInCalendar(args) {
    args.revert();

    const date = moment(args.event.start).format('YYYY-MM-DD');
    if (isDayDisabled(date)) return;
    if (isWeekendDisabled(date)) return;
    const qtdInTheDay = calendarData.filter(item => date === moment(item.date).format('YYYY-MM-DD'))
      .length;

    let limitItens = 5;

    const configItemAgenda = getConfigAgenda(date);
    if (configItemAgenda !== undefined && configItemAgenda.nrSlots) {
      limitItens = configItemAgenda.nrSlots;
    }

    if (qtdInTheDay >= limitItens) {
      swal('Máximo de itens adicionados neste dia excedido', {
        icon: 'error',
        confirmButtonColor: '#8CD4F5',
        buttons: {
          confirm: {
            confirmButtonColor: '#8CD4F5',
            text: 'Ok',
            value: true,
            visible: true,
            closeModal: true,
            className: 'swal2-Ok'
          }
        }
      });
      return;
    }

    setLoading(true);

    swal(
      <div>
        <CircularProgress style={{ color: '#EA6909' }} />
      </div>,
      {
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        title: 'Salvando'
      }
    );

    await new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, 500);
    });

    const indexItemDropped = itensToDrag.findIndex(item => item.idItemContrato === args.draggedEl.id);
    const itemDropped = { ...itensToDrag[indexItemDropped] };

    itemDropped.dtPrevisaoComercial = date;

    updateItemContrato(itemDropped)
      .then(res => {
        const auxList = [...itensToDrag];
        auxList.splice(indexItemDropped, 1);
        setItensToDrag(auxList);

        addUpdatedItem(res.data, qtdInTheDay);
      })
      .catch(err => {
        setLoading(false);
        swal('Erro ao marcar o item do contrato nesta data, tente novamente', {
          icon: 'error',
          confirmButtonColor: '#8CD4F5',
          buttons: {
            confirm: {
              confirmButtonColor: '#8CD4F5',
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-Ok'
            }
          }
        });
      })
      .finally(() => {
        swal.close();
        setLoading(false);
      });
  }

  function addUpdatedItem(item, qtdInTheDay) {
    const auxListItens = [...calendarData];

    auxListItens.push({
      backgroundColor: colorItemCalendar,
      borderColor: colorItemCalendar,
      id: item.idItemContrato,
      title: `Contrato ${item.contrato.nrContrato} - Loja ${item.contrato.loja.nrCodigo}`,
      extendedProps: {
        item: item
      },
      date: item.dtPrevisaoComercial
    });
    auxListItens.forEach(calendarItem => {
      if (calendarItem.date === item.dtPrevisaoComercial && qtdInTheDay === 4) {
        calendarItem.backgroundColor = colorItemCalendarExceeded;
        calendarItem.borderColor = colorItemCalendarExceeded;
      }
    });

    setCalendarData(auxListItens);
    setItens([...itens, item]);
  }

  function findDataOfCalendar() {
    if (!isLoading) setLoading(true);

    const dtInicio = dateSelectedRef.current
      .clone()
      .subtract(1, 'month')
      .startOf('month')
      .format('YYYY-MM-DD');
    const dtFim = dateSelectedRef.current
      .clone()
      .add(1, 'month')
      .endOf('month')
      .format('YYYY-MM-DD');

    findItensProducao(dtInicio, dtFim)
      .then(res => {
        res.data.configAgendaList.sort(function(a, b) {
          return moment(b.dtInicial).toDate() - moment(a.dtInicial).toDate();
        });

        setItens(res.data.itemContratoList);
        setConfigsAgenda(res.data.configAgendaList.filter(item => item.stBloqueio === 0));

        setDisabledDays(
          res.data.configAgendaList
            .filter(item => item.stBloqueio === 1)
            .map((item, i, arr) => {
              const crossDates = arr.slice(i).filter(cross => {
                const dtInicial = moment(item.dtInicial);

                if (!cross.dtFinal) {
                  return dtInicial.isSame(cross.dtInicial);
                }

                return (
                  dtInicial.isSameOrAfter(cross.dtInicial) && dtInicial.isSameOrBefore(cross.dtFinal)
                );
              });

              const values = {
                start: item.dtInicial,
                title: item.dsConfigAgenda,
                display: 'background',
                color: crossDates.length > 1 ? 'rgba(149, 152, 154, 0.0)' : 'rgba(149, 152, 154, 0.3)',
                className: 'dia-desabilitado',
                extendedProps: {
                  level: crossDates.length - 1
                }
              };
              if (item.dtFinal) {
                return {
                  end: moment(item.dtFinal)
                    .add(1, 'day')
                    .format('YYYY-MM-DD'),
                  ...values
                };
              }

              return {
                end: item.dtInicial,
                ...values
              };
            })
        );

        const events = res.data.itemContratoList.map(item => ({
          id: item.idItemContrato,
          backgroundColor: colorItemCalendar,
          borderColor: colorItemCalendar,
          title: `Contrato ${item.contrato.nrContrato} - Loja ${item.contrato.loja.nrCodigo}`,
          extendedProps: {
            item: item
          },
          date: item.dtPrevisaoComercial
        }));

        const daysWithEvents = {};
        events.forEach(item => {
          if (!daysWithEvents[moment(item.date).format('YYYY-MM-DD')]) {
            daysWithEvents[moment(item.date).format('YYYY-MM-DD')] = [item];
            return;
          }
          daysWithEvents[moment(item.date).format('YYYY-MM-DD')].push(item);
        });
        for (const prop in daysWithEvents) {
          if (daysWithEvents[prop].length === 5) {
            events.forEach(item => {
              if (moment(item.date).format('YYYY-MM-DD') === prop) {
                item.backgroundColor = colorItemCalendarExceeded;
                item.borderColor = colorItemCalendarExceeded;
              }
            });
            continue;
          }
          events.forEach(item => {
            if (moment(item.date).format('YYYY-MM-DD') === prop) {
              item.backgroundColor = colorItemCalendar;
              item.borderColor = colorItemCalendar;
            }
          });
        }

        setCalendarData(events);
        findItensToDrag();
      })
      .catch(err => {
        setLoading(false);
        swal('Erro ao buscar dados', {
          icon: 'error',
          confirmButtonColor: '#8CD4F5',
          buttons: {
            confirm: {
              confirmButtonColor: '#8CD4F5',
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-Ok'
            }
          }
        });
      });
  }

  function getWeekendsDisabledDays() {
    const weekendsDisabled = [];

    configsAgenda.forEach(item => {
      if (item.stBloqueiaSabado === 1) {
        disableWeekend(item, 6);
      }

      if (item.stBloqueiaDomingo === 1) {
        disableWeekend(item, 0);
      }
    });

    return weekendsDisabled;

    function pushWeekendDisabled(item) {
      weekendsDisabled.push({
        start: item.dtInicial,
        end: item.dtInicial,
        display: 'background',
        color: 'rgba(149, 152, 154, 0.3)',
        className: 'dia-desabilitado'
      });
    }

    function disableWeekend(item, weekday) {
      let actualDate = moment(item.dtInicial);
      if (!item.dtFinal && actualDate.weekday() === weekday) {
        pushWeekendDisabled(item);
        return;
      }

      const limitDate = moment(item.dtFinal);

      do {
        if (actualDate.weekday() === weekday) {
          pushWeekendDisabled({ ...item, dtInicial: actualDate.format('YYYY-MM-DD') });
        }

        actualDate = actualDate.add(1, 'day');
      } while (actualDate.isSameOrBefore(limitDate));
    }
  }

  function findItensToDrag() {
    if (itensToDrag.length > 0) {
      setLoading(false);
      updateCalendarSize();
      return;
    }

    findItensPendentes()
      .then(res => {
        res.data.forEach(item => (item.color = colorItemCalendar));
        setItensToDrag(res.data);
      })
      .catch(err => {
        swal('Erro ao buscar dados dos itens pendentes', {
          icon: 'error',
          confirmButtonColor: '#8CD4F5',
          buttons: {
            confirm: {
              confirmButtonColor: '#8CD4F5',
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-Ok'
            }
          }
        });
      })
      .finally(() => {
        setLoading(false);
        updateCalendarSize();
      });
  }

  function addHandleEvents() {
    const btnPrevEl = document.getElementsByClassName('fc-prev-button')[0];
    const btnNextEl = document.getElementsByClassName('fc-next-button')[0];
    const btnTodayEl = document.getElementsByClassName('fc-today-button')[0];
    const btnMonthEl = document.getElementsByClassName('fc-dayGridMonth-button')[0];
    const btnWeekEl = document.getElementsByClassName('fc-dayGridWeek-button')[0];
    const btnDayEl = document.getElementsByClassName('fc-dayGridDay-button')[0];

    btnNextEl.addEventListener('click', handleClickNext);
    btnPrevEl.addEventListener('click', handleClickPrev);
    btnTodayEl.addEventListener('click', handleClickToday);
    btnMonthEl.addEventListener('click', handleClickMonth);
    btnWeekEl.addEventListener('click', handleClickWeek);
    btnDayEl.addEventListener('click', handleClickDay);
  }

  function removeHandleEvents() {
    const btnPrevEl = document.getElementsByClassName('fc-prev-button')[0];
    const btnNextEl = document.getElementsByClassName('fc-next-button')[0];
    const btnTodayEl = document.getElementsByClassName('fc-today-button')[0];
    const btnMonthEl = document.getElementsByClassName('fc-dayGridMonth-button')[0];
    const btnWeekEl = document.getElementsByClassName('fc-dayGridWeek-button')[0];
    const btnDayEl = document.getElementsByClassName('fc-dayGridDay-button')[0];

    btnNextEl.removeEventListener('click', handleClickNext);
    btnPrevEl.removeEventListener('click', handleClickPrev);
    btnTodayEl.removeEventListener('click', handleClickToday);
    btnMonthEl.removeEventListener('click', handleClickMonth);
    btnWeekEl.removeEventListener('click', handleClickWeek);
    btnDayEl.removeEventListener('click', handleClickDay);
  }

  function handleClickMonth() {
    setViewType('dayGridMonth');
  }

  function handleClickWeek() {
    setViewType('dayGridWeek');
  }

  function handleClickDay() {
    setViewType('dayGridDay');
  }

  function handleClickToday() {
    setDateSelected(moment().date(1));
  }

  function handleClickNext() {
    if (calendarRef.current.getApi().getCurrentData().currentViewType === 'dayGridMonth') {
      setDateSelected(moment(dateSelectedRef.current).add(1, 'month'));
      return;
    }

    if (calendarRef.current.getApi().getCurrentData().currentViewType === 'dayGridWeek') {
      const monthAtual = moment(dateSelectedRef.current).format('MM');
      const monthCalendar = moment(calendarRef.current.getApi().getDate())
        .add(1, 'week')
        .format('MM');

      if (monthAtual !== monthCalendar) {
        setDateSelected(moment(dateSelectedRef.current).add(1, 'month'));
      }
    }

    if (calendarRef.current.getApi().getCurrentData().currentViewType === 'dayGridDay') {
      const monthAtual = moment(dateSelectedRef.current).format('MM');
      const monthCalendar = moment(calendarRef.current.getApi().getDate())
        .add(1, 'day')
        .format('MM');

      if (monthAtual !== monthCalendar) {
        setDateSelected(moment(dateSelectedRef.current).add(1, 'month'));
      }
    }
  }

  function handleClickPrev() {
    if (calendarRef.current.getApi().getCurrentData().currentViewType === 'dayGridMonth') {
      setDateSelected(moment(dateSelectedRef.current).subtract(1, 'month'));
      return;
    }

    if (calendarRef.current.getApi().getCurrentData().currentViewType === 'dayGridWeek') {
      const monthAtual = moment(dateSelectedRef.current).format('MM');
      const monthCalendar = moment(calendarRef.current.getApi().getDate())
        .subtract(1, 'week')
        .format('MM');

      if (monthAtual !== monthCalendar) {
        setDateSelected(moment(dateSelectedRef.current).subtract(1, 'month'));
      }
    }

    if (calendarRef.current.getApi().getCurrentData().currentViewType === 'dayGridDay') {
      const monthAtual = moment(dateSelectedRef.current).format('MM');
      const monthCalendar = moment(calendarRef.current.getApi().getDate())
        .subtract(1, 'day')
        .format('MM');

      if (monthAtual !== monthCalendar) {
        setDateSelected(moment(dateSelectedRef.current).subtract(1, 'month'));
      }
    }
  }

  function isDayDisabled(dateStr) {
    const date = moment(dateStr);

    const auxDisabledDate = disabledDays.find(item => {
      const start = moment(item.start);
      let end = moment(item.end);
      if (item.start !== item.end) {
        end = moment(item.end).subtract(1, 'day');
      }

      return date.isSameOrAfter(start) && date.isSameOrBefore(end);
    });

    return auxDisabledDate !== undefined;
  }

  function updateCalendarSize() {
    setTimeout(() => calendarRef.current && calendarRef.current.getApi().updateSize(), 500);
  }

  function setDateSelected(date) {
    dateSelectedRef.current = date;
    _setDateSelected(date);
  }
};

const mapStateToProps = state => ({
  menuIsOpen: state.menuOpen.open,
  usuarioLogado: state.usuarioReducer
});

export default withRouter(connect(mapStateToProps)(Comercial));
