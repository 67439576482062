import * as React from 'react';

function SvgIconCalendar(props) {
  return (
    <svg width='1.30em' height='1.30em' viewBox='0 0 22 22' {...props}>
      <path d='M0 0h24v24H0z' fill='none' />
      <path
        fill={props.color}
        d='M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z'
      />
    </svg>
  );
}

export default SvgIconCalendar;
