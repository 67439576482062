var estadoList = []

const adicionaListaDeEstado = (state = estadoList, action) => {
  switch (action.type) {
    case 'ADD_ESTADOS':
      estadoList = action.list
      return estadoList
    default:
      return state
  }
}

export default adicionaListaDeEstado
