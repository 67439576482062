/*
Ao adicionar subMenus ->
Os subitens deve ser listado em children, onde o pai de todos os itens, que é exibido quando o menu é fechado
deve conter um id, id esse que sera usado para associa-los a seus filhos, os ultimos itens de um submenu, 
os que direcionam a pagina para alguma rota deve conter o atributo parent que contem o id do seu pai.
Itens sem submenu nao necessitam nem de id, nem de parent
 */
import React from 'react';
import {
  SvgIconeContratos,
  SvgIconeFabrica,
  SvgIconParceiros,
  SvgIconLojas,
  SvgIconCalendar
} from '@components/svg-components/index.js';

import { FormattedMessage } from 'react-intl';

const itensMenu = [
  {
    name: 'Agenda',
    selecionado: false,
    icon: SvgIconCalendar,
    categoria: 3,
    id: 'AGENDA',
    children: [
      {
        name: 'Comercial',
        selecionado: false,
        categoria: 2,
        id: 'COMERCIAL',
        route: '/comercial'
      },
      {
        name: <FormattedMessage id='menu.fabrica' />,
        selecionado: false,
        categoria: 3,
        id: 'FABRICA',
        route: '/fabrica'
      },
      {
        name: <FormattedMessage id='menu.configuracaoAgenda' />,
        selecionado: false,
        categoria: 0,
        id: 'CONFIG-AGENDA',
        route: '/config-agenda'
      }
    ]
  },
  {
    name: 'Comercial',
    selecionado: false,
    icon: SvgIconeContratos,
    categoria: 3,
    id: 'COMERCIAIS',
    children: [
      {
        name: <FormattedMessage id='menu.contratos' />,
        selecionado: false,
        categoria: 3,
        id: 'CONTRATOS',
        route: '/contratos'
      },
      {
        name: <FormattedMessage id='menu.propostas' />,
        selecionado: false,
        categoria: 2,
        id: 'PROPOSTAS',
        route: '/propostas'
      },
      {
        name: <FormattedMessage id='menu.visitas' />,
        selecionado: false,
        categoria: 2,
        id: 'VISITAS',
        route: '/visitas'
      },
      {
        name: <FormattedMessage id='menu.metas' />,
        selecionado: false,
        categoria: 0,
        id: 'METAS',
        route: '/metas'
      },
      {
        name: <FormattedMessage id='menu.projetos' />,
        selecionado: false,
        categoria: 3,
        id: 'PROJETOS',
        route: '/projetos'
      },
      {
        name: 'Acompanhamento',
        selecionado: false,
        categoria: 2,
        id: 'ACOMPANHAMENTO',
        children: [
          {
            name: 'Contratos',
            selecionado: false,
            parent: ['COMERCIAIS', 'ACOMPANHAMENTO'],
            categoria: 2,
            route: '/resumo-contrato'
          },
          {
            name: 'Propostas',
            selecionado: false,
            parent: ['COMERCIAIS', 'ACOMPANHAMENTO'],
            categoria: 2,
            route: '/resumo-proposta'
          },
          {
            name: 'Metas',
            selecionado: false,
            parent: ['COMERCIAIS', 'ACOMPANHAMENTO'],
            categoria: 2,
            route: '/acompanhamento-metas'
          }
        ]
      }
    ]
  },
  {
    name: 'Pessoas',
    selecionado: false,
    icon: SvgIconParceiros,
    categoria: 0,
    id: 'PESSOAS',
    children: [
      {
        name: <FormattedMessage id='menu.gerentes' />,
        selecionado: false,
        categoria: 0,
        id: 'GERENTES',
        route: '/gerentes'
      },
      {
        name: <FormattedMessage id='menu.consultor' />,
        selecionado: false,
        categoria: 0,
        id: 'VENDEDORES',
        route: '/consultor'
      },
      {
        name: <FormattedMessage id='menu.admnistradores' />,
        selecionado: false,
        categoria: 0,
        id: 'ADMINISTRADORES',
        route: '/administradores'
      },
      {
        name: <FormattedMessage id='menu.tecnicos' />,
        selecionado: false,
        categoria: 0,
        id: 'TECNICOS',
        route: '/tecnicos'
      }
    ]
  },
  {
    name: 'Organização',
    selecionado: false,
    icon: SvgIconLojas,
    categoria: 2,
    id: 'ORGANIZACAO',
    children: [
      {
        name: <FormattedMessage id='menu.clientes' />,
        selecionado: false,
        categoria: 2,
        id: 'CLIENTES',
        route: '/clientes'
      },
      {
        name: <FormattedMessage id='menu.parceiros' />,
        selecionado: false,
        categoria: 2,
        id: 'PARCEIROS',
        route: '/parceiros'
      },
      {
        name: <FormattedMessage id='menu.lojas' />,
        selecionado: false,
        categoria: 0,
        id: 'LOJAS',
        route: '/lojas'
      },
      {
        name: <FormattedMessage id='menu.ambientes' />,
        selecionado: false,
        categoria: 0,
        id: 'AMBIENTES',
        route: '/ambientes'
      },
      {
        name: <FormattedMessage id='menu.acabamentos' />,
        selecionado: false,
        categoria: 0,
        id: 'ACABAMENTOS',
        route: '/acabamentos'
      }
    ]
  }
];
export default itensMenu;
