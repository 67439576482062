import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import flattenMessages from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import ptBR from './i18n/ptBR';
import CardFilters from '@components/CardFilters/CardFilters';
import FilterUtil from '../filterUtil';
import FabDiamond from '@components/FABDiamond/FABDiamond';
import SwalConfirmacao from '@utils/functions/SwalConfirmacao/SwalConfirmacao';
import swal from '@sweetalert/with-react';
import SwalMessage from '@utils/functions/SwalMessage/SwalMessage';
import { getAll, deleteAmbiente } from '@resources/api/ambiente';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DataTable, GroupButtonGrid } from '@kepha/sumora-react-components';
import '@root/App.css';
let messages = { 'pt-BR': ptBR };

const iconAdd = require('@images/ic_add_white.png');

let { provider } = new IntlProvider({});

const style = () => ({
  fab: {
    position: 'fixed',
    right: '15px',
    bottom: '40px',
    zIndex: '2000'
  },
  containerTable: {
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'column'
  },
  card: {
    height: 70,
    width: '100%',
    marginTop: '30px'
  }
});

class Ambientes extends Component {
  constructor(props) {
    super(props);

    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.tratarSituacao = this.tratarSituacao.bind(this);

    const messagesLanguage = flattenMessages(messages[getUserLang()]);
    const atualizando = messagesLanguage['ambientes.atualizando'];
    const nomeCol = messagesLanguage['ambientes.nomeCol'];
    const descricao = messagesLanguage['ambientes.descricao'];
    const situacao = messagesLanguage['ambientes.situacao'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          atualizando,
          nomeCol,
          descricao,
          situacao
        }
      },
      {}
    );
    provider = intlProvider.getChildContext();

    this.state = {
      infoExtra: [],
      validarSenha: false,
      selected: undefined, // controla a linha selecionada
      dataAppSync: [], // dados que vem da api
      dataInTable: [],
      operationValues: [
        {
          label: 'Nome',
          field: 'nmAmbiente',
          type: 'string'
        },
        {
          label: 'Situação',
          field: 'stAmbiente',
          type: 'enum',
          options: [
            {
              label: 'Ativo',
              field: 'Ativo',
              value: '1'
            },
            {
              label: 'Inativo',
              field: 'Inativo',
              value: '0'
            }
          ]
        }
      ]
    };
    const title = 'ambientes.title';
    if (this.props.itensState.name !== title) {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: (
          <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
            <FormattedMessage id={title} />
          </IntlProvider>
        ),
        name: title
      });
    }
  }

  componentDidMount() {
    this.props.dispatch({
      type: 'CLEAN_CHIPS'
    });
    this._isMounted = true;
    this.onFilter();
  }

  // controla a linha adicionada e muda os estados dos botões de editar e excluir
  onSelect(selected) {
    if (this._isMounted === true) {
      this.setState({
        selected: selected
      });
    }
  }

  //Trata a situação do ambiente
  tratarSituacao(situacao) {
    switch (parseInt(situacao)) {
      case 0:
        return 'Inativo';
      case 1:
        return 'Ativo';
      default:
        break;
    }
  }

  // Ao ser adicionado novo é realizado um filtro usando os dados
  onFilter(dataChips) {
    dataChips = dataChips || [];

    if (this.state.dataAppSync.length > 0) {
      return;
    } else {
      getAll()
        .then(res => {
          var data = res.data.map(item => {
            item.nmSituacao = this.tratarSituacao(item.stAmbiente);

            return item;
          });
          this.setState({
            dataInTable: FilterUtil.applyFilters(data, dataChips),
            dataAppSync: data
          });
        })
        .catch(err => {
          SwalMessage({
            title: 'Falha',
            text: 'Falha ao carregar página',
            err,
            callback: () => {
              this._isMounted && this.setState({ dataAppSync: [] });
            }
          });
        });
    }
  }
  onFilterChanged(dataChips) {
    this.setState({
      dataInTable: FilterUtil.applyFilters(this.state.dataAppSync, dataChips)
    });
  }

  // set que o componente está desmontado e não aceita mais setState
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleAdd() {
    this.props.history.push('/app/ambientes/novo');
  }

  handleEdit() {
    this.props.history.push({
      pathname: '/app/ambientes/editar/' + this.state.selected.idAmbiente,
      state: { ambiente: this.state.selected }
    });
  }

  handleDelete() {
    SwalConfirmacao({
      text: 'Tem certeza que deseja remover este ambiente?'
    }).then(res => {
      if (res) {
        swal(
          <div>
            <CircularProgress />
          </div>,
          {
            buttons: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
            title: 'Excluindo'
          }
        );
        deleteAmbiente(this.state.selected.idAmbiente)
          .then(res => {
            var data = this.state.dataAppSync;
            let index = data.indexOf(this.state.selected);
            if (index > -1) {
              data.splice(index, 1);
              data = [...data];

              this._isMounted &&
                this.setState(
                  {
                    selected: undefined,
                    dataAppSync: data,
                    dataInTable: FilterUtil.applyFilters(data, [])
                  },
                  () => {
                    SwalMessage({
                      icon: 'success',
                      title: 'Sucesso',
                      text: 'Ambiente Removido com sucesso',
                      callback: () => {
                        this.forceUpdate();
                      }
                    });
                  }
                );
            }
          })
          .catch(err => {
            SwalMessage({
              title: 'Erro',
              text: 'Não foi possível deletar esse ambiente, tente novamente'
            });
          });
      }
    });
  }

  render() {
    const { classes } = this.props;

    let head = [
      {
        field: 'nmAmbiente',
        headerName: provider.intl.formatMessage({ id: 'nomeCol' }),
        col: 10
      },
      {
        field: 'nmSituacao',
        headerName: provider.intl.formatMessage({ id: 'situacao' }),
        col: 2
      }
    ];
    const pagination = {
      length: this.state.dataInTable.length,
      page: 0
    };

    return (
      <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            marginTop: 10
          }}
        >
          <div
            ref={divElement => {
              this.divElement = divElement;
            }}
          >
            <CardFilters
              filtros={this.state.operationValues}
              onFilter={this.onFilter}
              onFilterChanged={this.onFilterChanged}
              usePaddings={false}
              viewKey='ambientes'
            ></CardFilters>
          </div>

          <div
            style={{
              marginTop: 10,
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <GroupButtonGrid
              showAdd={false}
              showEdit={true}
              showDelete={true}
              disableDelete={this.state.selected === undefined}
              disableEdit={this.state.selected === undefined}
              onClickDelete={this.handleDelete}
              onClickEdit={this.handleEdit}
              deleteColor='#5F4C3E'
              editColor='#5F4C3E'
            />
            <DataTable
              marginTop
              data={this.state.dataInTable}
              columns={head}
              userlang={getUserLang()}
              selectChange={this.onSelect}
              showPagination={true}
              pagination={pagination}
              handleChangePage={() => {}}
              handleChangeRowsPerPage={() => {}}
            />
          </div>
          <div className={classes.fab} onClick={this.handleAdd}>
            <FabDiamond
              url={iconAdd}
              imageStyle={{
                height: '28px',
                width: '28px',
                padding: '5px 5px 0px 0px'
              }}
            />
          </div>
        </div>
      </IntlProvider>
    );
  }
}
const mapStateToProps = state => ({
  UsuarioLogado: state.usuarioReducer,
  itensState: state.toolbar
});

let enhanced = withStyles(style)(Ambientes);
export default connect(mapStateToProps)(withRouter(enhanced));
