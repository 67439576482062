import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import flattenMessages from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withStyles } from '@material-ui/core/styles';
import ptBR from './i18n/ptBR';
import CardIcon from '@components/CardIcon/CardIcon';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CPFValidator from '@validators/CPFValidator';
import { TextField, MaskedTextField, AutoComplete, DatePicker } from '@kepha/sumora-react-components';
import swal from '@sweetalert/with-react';
import Grid from '@material-ui/core/Grid';
import { insert, update } from '@resources/api/vendedor';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getAllEstados } from '@resources/api/estado';
import ProfileImage from '@components/ProfileImage/ProfileImage';
import { getMunicipio, getMunicipios } from '@root/actions/municipioActions';
import { getLojas } from '@root/actions/lojaActions';
import { refreshVendedorList } from '@root/actions/vendedorActions';
import SwalConfirmacao from '@utils/functions/SwalConfirmacao/SwalConfirmacao';
import FabDiamondMenu from '@components/FabDiamondMenu/FabDiamondMenu';
import moment from 'moment';
import OnExitConfirmation from '@components/OnExitConfirmation/OnExitConfirmation';

let messages = { 'pt-BR': ptBR };
let { provider } = new IntlProvider({});

const style = () => ({
  fab: {
    position: 'fixed',
    right: '15px',
    bottom: '40px',
    zIndex: '2000'
  },
  card: {
    height: 550,
    width: '100%',
    marginTop: '30px'
  },
  underline: {
    '&:after': {
      borderBottomColor: ['#EA6909', '!important']
    },
    '&:hover:before': {
      borderBottomColor: ['#EA6909', '!important'],
      borderBottomWidth: ['1px', '!important']
    }
  },
  closeButton: {
    position: 'absolute',
    right: 20,
    paddingBottom: '8px',
    height: '40px',
    width: '40px'
  }
});

class VendedorForm extends Component {
  constructor(props) {
    super(props);

    const messagesLanguage = flattenMessages(messages[getUserLang()]);
    const nome = messagesLanguage['form.nome'];
    const campoObrigatorio = messagesLanguage['form.campoObrigatorio'];
    const identificacao = messagesLanguage['form.identificacao'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          nome,
          campoObrigatorio,
          identificacao
        }
      },
      {}
    );
    provider = intlProvider.getChildContext();

    this.initialValues = {
      nome: '',
      cidade: '',
      whatsapp: '',
      telefone: '',
      email: '',
      cep: '',
      endereco: '',
      estado: '',
      situacao: 1,
      codigo: '',
      dtNascimento: null,
      rg: '',
      loja: '',
      cpf: undefined,
      croppedPhoto: null,
      fotoPerfil: null,
      fotoPerfilUuId: ''
    };
    this.state = {
      estadoList: [],
      municipioList: [],
      lojaList: [],
      vendedor: { ...this.initialValues },
      dateMessage: 'Campo Obrigatório',
      keyAutoCompleteEstado: '',
      keyAutoCompleteCidade: '',
      keyAutoCompleteLoja: '',
      canLeave: false
    };
    let title;
    if (this.props.match.params.id === undefined) {
      title = 'CADASTRAR CONSULTOR';
    } else {
      title = 'EDITAR CONSULTOR';
    }

    if (this.props.itensState.name !== title) {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: title,
        name: title
      });
    }

    this.cancelarEdicao = this.cancelarEdicao.bind(this);

    this.onPressed = this.onPressed.bind(this);
    this.imagesCallback = this.imagesCallback.bind(this);

    this.mensagemVendedorEditadoComSucesso = this.mensagemVendedorEditadoComSucesso.bind(this);
    this.mensagemErroAoEditarVendedor = this.mensagemErroAoEditarVendedor.bind(this);
    this.mensagemProblemaInesperado = this.mensagemProblemaInesperado.bind(this);
    this.mensagemVendedorSalvoComSucesso = this.mensagemVendedorSalvoComSucesso.bind(this);
    this.mensagemErroAoSalvarVendedor = this.mensagemErroAoSalvarVendedor.bind(this);
    this.onChangeAutocompleteEstado = this.onChangeAutocompleteEstado.bind(this);
    this.onChangeAutocompleteLoja = this.onChangeAutocompleteLoja.bind(this);
    this.onChangeAutocompleteCidade = this.onChangeAutocompleteCidade.bind(this);
    this.tratarEstado = this.tratarEstado.bind(this);
    this.tratarLoja = this.tratarLoja.bind(this);
    this.tratarMunicipio = this.tratarMunicipio.bind(this);
    this.handleCancelar = this.handleCancelar.bind(this);
    this.cancelarEdicao = this.cancelarEdicao.bind(this);
    this.getAllEstados = this.getAllEstados.bind(this);
    this.getLojas = this.getLojas.bind(this);
    this.loadSuggestionsAutocompleteMunicipios = this.loadSuggestionsAutocompleteMunicipios.bind(this);
    this.loadSuggestionsAutocompleteLojas = this.loadSuggestionsAutocompleteLojas.bind(this);
  }

  getAllEstados() {
    getAllEstados().then(res => {
      var data = res.data;

      this.setState({
        estadoList: data
      });
    });
  }

  mensagemVendedorEditadoComSucesso() {
    swal('Consultor editado com sucesso', {
      icon: 'success',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-Ok'
        }
      }
    }).then(() => {
      this.cancelarEdicao();
    });
  }

  updateVendedorList = () => {
    this.props.dispatch(refreshVendedorList(this.props.vendedorList));
  };

  mensagemVendedorSalvoComSucesso() {
    swal('Consultor salvo com sucesso', {
      icon: 'success',
      confirmButtonColor: '#8CD4F5',
      buttons: {
        confirm: {
          confirmButtonColor: '#8CD4F5',
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-Ok'
        }
      }
    }).then(() => {
      this.cancelarEdicao();
    });
  }

  mensagemErroAoEditarVendedor() {
    swal('Falha ao editar o consultor', {
      icon: 'error',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-error'
        }
      }
    });
  }
  handleCancelar() {
    SwalConfirmacao({
      text: 'Tem certeza que deseja cancelar esta edição?'
    }).then(res => {
      if (res) {
        this.setState(
          {
            canLeave: true
          },
          () => {
            this.props.history.push('/app/consultor');
          }
        );
      }
    });
  }
  mensagemErroAoSalvarVendedor() {
    swal('Falha ao salvar o consultor', {
      icon: 'error',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-error'
        }
      }
    });
  }

  mensagemProblemaInesperado() {
    swal('Ocorreu um problema inesperado', {
      icon: 'error',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-error'
        }
      }
    }).then(() => {
      this.cancelarEdicao();
    });
  }

  //Callback que carrega a foto recortada no state
  imagesCallback(croppedImage) {
    this.setState({
      vendedor: { ...this.state.vendedor, croppedPhoto: croppedImage }
    });
  }

  //Trata a submissão do form
  onPressed(values) {
    swal(
      <div>
        <CircularProgress style={{ color: '#EA6909' }} />
      </div>,
      {
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        title: this.props.match.params.id !== undefined ? 'Atualizando' : 'Salvando'
      }
    );
    const data = {
      idUsuario: this.props.match.params.id && this.props.match.params.id,
      nmUsuario: values.nome,
      nrTelefone: values.telefone,
      nrWhatsapp: values.whatsapp,
      nrCep: values.cep,
      idUf: values.estado.idUf,
      idMunicipio: values.cidade.idMunicipio,
      dsLogradouro: values.endereco,
      dsEmail: values.email,
      stUsuario: values.situacao,
      dsFotoBase64: this.state.vendedor.croppedPhoto,
      dsFotoPerfil: this.state.vendedor.fotoPerfilUuId,
      nrRg: values.rg,
      nrCpf: values.cpf,
      dtNascimento: moment(values.dtNascimento).format('YYYY-MM-DD'),
      idLoja: values.loja.idLoja
    };
    if (this.props.match.params.id !== undefined && this.props.match.params.id !== null) {
      update(data)
        .then(res => {
          this.updateVendedorList();
          return this.mensagemVendedorEditadoComSucesso();
        })
        .catch(err => {
          return this.mensagemErroAoEditarVendedor();
        });
    } else {
      insert(data)
        .then(res => {
          this.updateVendedorList();
          return this.mensagemVendedorSalvoComSucesso();
        })
        .catch(err => {
          if (err.response && err.response.data.codigo === 'EMAIL_DUPLICADO') {
            swal('Este e-mail já foi cadastrado', {
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-error'
                }
              }
            });
            return;
          }
          return this.mensagemErroAoSalvarVendedor();
        });
    }
  }

  //Volta para a listagem
  cancelarEdicao() {
    this.setState(
      {
        canLeave: true
      },
      () => {
        this.props.history.push('/app/consultor');
      }
    );
  }

  //Em caso de edição, carrega as informações no formulário
  async componentDidMount() {
    await this.getLojas();
    var data = this.props.location.state !== undefined ? this.props.location.state.vendedor : undefined;

    if (data !== undefined) {
      var estado = this.tratarEstado(data.idUf);
      var loja = this.tratarLoja(data.idLoja);
      var municipio = await this.tratarMunicipio(data.idUf, data.idMunicipio);
      this.initialValues = {
        nome: data.nmUsuario,
        cidade: municipio,
        whatsapp: data.nrWhatsapp,
        telefone: data.nrTelefone,
        email: data.dsEmail,
        cep: data.nrCep,
        endereco: data.dsLogradouro,
        estado: estado,
        situacao: data.stUsuario,
        fotoPerfil: data.dsFotoPerfil ? process.env.REACT_APP_URL_IMAGES + data.dsFotoPerfil : null,
        fotoPerfilUuId: data.dsFotoPerfil,
        rg: data.nrRg,
        cpf: data.nrCpf,
        dtNascimento: data.dtNascimento,
        loja: loja
      };
      this.setState(
        {
          vendedor: { ...this.initialValues }
        },
        () => {
          this.setState({
            keyAutoCompleteEstado: Math.random(),
            keyAutoCompleteCidade: Math.random(),
            keyAutoCompleteLoja: Math.random()
          });
        }
      );
    }
  }

  // Trata quando um valor é selecionado enquanto o usuario digita no AutoComplete de estado
  onChangeAutocompleteEstado(value) {
    let errors = this.errors;
    this.reinitialize = false;
    value != null && this.loadSuggestionsAutocompleteMunicipios(value);
    this.setFieldValue && this.setFieldValue('estado', value);

    this.errors = errors;
  }

  // Trata quando um valor é selecionado enquanto o usuario digita no AutoComplete de loja
  onChangeAutocompleteLoja(value) {
    let errors = this.errors;
    this.reinitialize = false;
    this.setFieldValue && this.setFieldValue('loja', value);
    this.errors = errors;
  }

  //Carrega a lista de lojas no state
  async getLojas() {
    await this.props.dispatch(getLojas(this.props.lojaList)).then(lojas => {
      this.setState({
        lojaList: lojas
      });
    });
  }

  // Trata quando um valor é selecionado enquanto o usuario digita no AutoComplete de cidade
  onChangeAutocompleteCidade(value) {
    let errors = this.errors;
    this.reinitialize = false;
    this.setFieldValue && this.setFieldValue('cidade', value);
    this.errors = errors;
  }

  // carrega os dados para colocar no select de municipios
  async loadSuggestionsAutocompleteMunicipios(estado) {
    await this.props.dispatch(getMunicipios(estado.idUf, this.props.municipioList)).then(municipios => {
      this.setState({
        municipioList: municipios
      });
    });
  }

  // carrega os dados para colocar no select de lojas
  async loadSuggestionsAutocompleteLojas() {
    await this.props.dispatch(getLojas(this.props.lojaList)).then(lojas => {
      this.setState({
        lojas: lojas
      });
    });
  }

  //Retorna um estado a partir de um idUf
  tratarEstado(id) {
    var estado = this.props.estadoList.find(el => {
      return el.idUf === id;
    });

    return estado;
  }

  // Retorna uma loja a partir de um idLoja
  tratarLoja(id) {
    var loja = this.props.lojaList.find(el => {
      return el.idLoja === id;
    });

    return loja;
  }

  //Carrega a lista de municipios de um estado no state e retorna um município a partir de um id
  async tratarMunicipio(idEstado, idMunicipio) {
    var municipioFiltrado;
    await this.props
      .dispatch(getMunicipio(idEstado, idMunicipio, this.props.municipioList))
      .then(municipio => {
        this.setState({
          municipioList: this.props.municipioList[idEstado]
        });
        municipioFiltrado = municipio;
      });
    return municipioFiltrado;
  }

  render() {
    const { classes } = this.props;

    return (
      <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
        <div style={{ width: '100%', marginTop: 15 }}>
          <Formik
            initialValues={{
              ...this.initialValues
            }}
            onSubmit={this.onPressed}
            validateOnBlur
            enableReinitialize={true}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              nome: Yup.string().required(<FormattedMessage id='form.campoObrigatorio' />),
              cidade: Yup.string().required(<FormattedMessage id='form.campoObrigatorio' />),
              estado: Yup.string().required(<FormattedMessage id='form.campoObrigatorio' />),
              endereco: Yup.string().required(<FormattedMessage id='form.campoObrigatorio' />),
              cep: Yup.string().required(<FormattedMessage id='form.campoObrigatorio' />),
              whatsapp: Yup.string().required(<FormattedMessage id='form.campoObrigatorio' />),
              telefone: Yup.string().required(<FormattedMessage id='form.campoObrigatorio' />),
              email: Yup.string()
                .email('Email inválido')
                .required(<FormattedMessage id='form.campoObrigatorio' />),
              situacao: Yup.string().required(<FormattedMessage id='form.campoObrigatorio' />),
              dtNascimento: Yup.date().required(<FormattedMessage id='form.campoObrigatorio' />),
              cpf: Yup.string()
                .test('', 'CPF Inválido', function(value) {
                  if (value !== undefined) {
                    const validation = CPFValidator.validate(value);
                    return validation;
                  }
                  return true;
                })
                .required(<FormattedMessage id='form.campoObrigatorio' />),
              rg: Yup.string().required(<FormattedMessage id='form.campoObrigatorio' />),
              loja: Yup.string().required(<FormattedMessage id='form.campoObrigatorio' />)
            })}
            render={({
              values,
              handleSubmit,
              errors,
              touched,
              handleBlur,
              handleChange,
              setFieldValue
            }) => {
              this.values = { ...values };
              this.setFieldValue = setFieldValue;
              this.errors = errors;

              return (
                <div>
                  <OnExitConfirmation
                    values={this.values}
                    initialValues={this.initialValues}
                    canLeave={this.state.canLeave}
                  >
                    <CardIcon
                      titulo={provider.intl.formatMessage({
                        id: 'identificacao'
                      })}
                      disableExpandButton={true}
                    >
                      <form autoComplete='off'>
                        <div className={classes.card}>
                          <Grid container spacing={3}>
                            <Grid item xs={3}>
                              <ProfileImage
                                initalValue={this.state.vendedor.fotoPerfil}
                                imagesCallback={this.imagesCallback}
                              />
                            </Grid>
                            <Grid item xs={9} />
                            <Grid
                              item
                              xs={6}
                              style={{
                                marginTop: 9
                              }}
                            >
                              <TextField
                                InputProps={{
                                  classes: { underline: classes.underline }
                                }}
                                inputProps={{ maxLength: 200 }}
                                id='nome'
                                label={provider.intl.formatMessage({
                                  id: 'nome'
                                })}
                                value={values.nome}
                                onChange={e => setFieldValue('nome', e.target.value)}
                                onBlur={handleBlur}
                                error={errors.nome && touched.nome}
                                helperText={
                                  errors.nome &&
                                  touched.nome &&
                                  provider.intl.formatMessage({
                                    id: 'campoObrigatorio'
                                  })
                                }
                              />
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              style={{
                                marginTop: 12
                              }}
                            >
                              <DatePicker
                                userlang='pt-BR'
                                label='Data de Nascimento *'
                                value={values.dtNascimento}
                                maxDate={new Date()}
                                onChange={date => {
                                  if (date !== null) {
                                    setFieldValue('dtNascimento', date._d);
                                  } else {
                                    setFieldValue('dtNascimento', null);
                                  }
                                }}
                                errorTouched={errors.dtNascimento && touched.dtNascimento}
                                helperText={this.state.dateMessage}
                                onBlur={date => {
                                  if (date.target.value.length === 0) {
                                    this.setState({
                                      dateMessage: 'Campo Obrigatório'
                                    });
                                    handleBlur('dtNascimento')(date);
                                    return;
                                  }
                                  let schema = Yup.date();
                                  let validation = schema.isValidSync(values.dtNascimento);
                                  if (!validation) {
                                    setFieldValue('dtNascimento', null);
                                    this.setState({
                                      dateMessage: 'Data Inválida'
                                    });
                                  }
                                  handleBlur('dtNascimento')(date);
                                }}
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <FormControl
                                fullWidth
                                className={classes.formControl}
                                error={errors.situacao && touched.situacao}
                              >
                                <FormControlLabel
                                  style={{
                                    marginTop: 25,
                                    position: 'absolute'
                                  }}
                                  id='situacao'
                                  name='situacao'
                                  control={
                                    <Checkbox
                                      color='primary'
                                      checked={values.situacao}
                                      onChange={e => {
                                        var situacao = e.target.checked ? 1 : 0;
                                        setFieldValue('situacao', situacao);
                                      }}
                                    />
                                  }
                                  label={
                                    <div
                                      style={{
                                        color: '#757575'
                                      }}
                                    >
                                      Ativo
                                    </div>
                                  }
                                />
                                {errors.situacao && touched.situacao && (
                                  <FormHelperText>
                                    {provider.intl.formatMessage({
                                      id: 'campoObrigatorio'
                                    })}
                                  </FormHelperText>
                                )}{' '}
                              </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                              <AutoComplete
                                itens={this.props.lojaList}
                                value={values.loja}
                                campoOp='nmLoja'
                                valueAutoComplete={values.loja}
                                campoChave='idLoja'
                                label='Nome da Loja *'
                                name='loja'
                                id='loja'
                                error={errors.loja && touched.loja}
                                helperText={
                                  errors.loja &&
                                  touched.loja &&
                                  provider.intl.formatMessage({
                                    id: 'campoObrigatorio'
                                  })
                                }
                                onChange={this.onChangeAutocompleteLoja}
                                onChangeAutoComplete={this.getLojas}
                                key={this.state.keyAutoCompleteLoja}
                                maxHeight='calc(100vh - 472px)'
                              />
                            </Grid>
                            <Grid item xs={6} />
                            <Grid item xs={3}>
                              <MaskedTextField
                                label='Telefone *'
                                tipoMascara='telefone'
                                userlang='pt-BR'
                                InputProps={{
                                  classes: { underline: classes.underline }
                                }}
                                id='telefone'
                                value={values.telefone}
                                onChange={e => setFieldValue('telefone', e.target.value)}
                                onBlur={handleBlur}
                                error={errors.telefone && touched.telefone}
                                helperText={
                                  errors.telefone &&
                                  touched.telefone &&
                                  provider.intl.formatMessage({
                                    id: 'campoObrigatorio'
                                  })
                                }
                              />
                            </Grid>

                            <Grid item xs={3}>
                              <MaskedTextField
                                label='WhatsApp *'
                                tipoMascara='telefone'
                                userlang='pt-BR'
                                InputProps={{
                                  classes: { underline: classes.underline }
                                }}
                                id='whatsapp'
                                value={values.whatsapp}
                                onChange={e => setFieldValue('whatsapp', e.target.value)}
                                onBlur={handleBlur}
                                error={errors.whatsapp && touched.whatsapp}
                                helperText={
                                  errors.whatsapp &&
                                  touched.whatsapp &&
                                  provider.intl.formatMessage({
                                    id: 'campoObrigatorio'
                                  })
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                type='email'
                                name='email'
                                onChange={e => setFieldValue('email', e.target.value)}
                                onBlur={handleBlur}
                                value={values.email}
                                error={errors.email && touched.email}
                                helperText={errors.email && touched.email ? errors.email : null}
                                InputProps={{
                                  classes: { underline: classes.underline }
                                }}
                                inputProps={{ maxLength: 100 }}
                                label='E-mail *'
                                disabled={!!this.props.match.params.id}
                              />
                            </Grid>

                            <Grid item xs={3}>
                              <MaskedTextField
                                label='CPF *'
                                tipoMascara='cpf'
                                userlang='pt-BR'
                                InputProps={{
                                  classes: { underline: classes.underline }
                                }}
                                id='cpf'
                                value={values.cpf}
                                onChange={e => setFieldValue('cpf', e.target.value)}
                                onBlur={handleBlur}
                                error={errors.cpf && touched.cpf}
                                helperText={errors.cpf && touched.cpf && errors.cpf}
                              />
                            </Grid>

                            <Grid item xs={3}>
                              <TextField
                                InputProps={{
                                  classes: { underline: classes.underline }
                                }}
                                inputProps={{ maxLength: 20 }}
                                id='rg'
                                label='RG *'
                                value={values.rg}
                                onChange={e => setFieldValue('rg', e.target.value)}
                                onBlur={handleBlur}
                                error={errors.rg && touched.rg}
                                helperText={
                                  errors.rg &&
                                  touched.rg &&
                                  provider.intl.formatMessage({
                                    id: 'campoObrigatorio'
                                  })
                                }
                              />
                            </Grid>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={3}>
                              <MaskedTextField
                                label='CEP *'
                                tipoMascara='cep'
                                userlang='pt-BR'
                                InputProps={{
                                  classes: { underline: classes.underline }
                                }}
                                id='cep'
                                value={values.cep}
                                onChange={e => setFieldValue('cep', e.target.value)}
                                onBlur={handleBlur}
                                error={errors.cep && touched.cep}
                                helperText={
                                  errors.cep &&
                                  touched.cep &&
                                  provider.intl.formatMessage({
                                    id: 'campoObrigatorio'
                                  })
                                }
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <AutoComplete
                                itens={this.props.estadoList}
                                value={values.estado}
                                campoOp='sgUf'
                                valueAutoComplete={values.estado}
                                campoChave='idUf'
                                label='Estado *'
                                name='estado'
                                id='estado'
                                error={errors.estado && touched.estado}
                                helperText={
                                  errors.estado &&
                                  touched.estado &&
                                  provider.intl.formatMessage({
                                    id: 'campoObrigatorio'
                                  })
                                }
                                onChange={this.onChangeAutocompleteEstado}
                                onChangeAutoComplete={this.getAllEstados}
                                key={this.state.keyAutoCompleteEstado}
                                maxHeight='calc(100vh - 625px)'
                              />
                            </Grid>

                            <Grid item xs={6}>
                              <AutoComplete
                                itens={this.state.municipioList}
                                value={values.cidade}
                                campoOp='nmMunicipio'
                                campoChave='idMunicipio'
                                disabled={this.state.municipioList.length === 0}
                                label='Cidade *'
                                name='cidade'
                                id='cidade'
                                error={errors.cidade && touched.cidade}
                                helperText={
                                  errors.cidade &&
                                  touched.cidade &&
                                  provider.intl.formatMessage({
                                    id: 'campoObrigatorio'
                                  })
                                }
                                onChange={this.onChangeAutocompleteCidade}
                                key={this.state.keyAutoCompleteCidade}
                                valueAutoComplete={values.cidade}
                                maxHeight='calc(100vh - 625px)'
                              />
                            </Grid>

                            <Grid item xs={6}>
                              <TextField
                                InputProps={{
                                  classes: { underline: classes.underline }
                                }}
                                inputProps={{ maxLength: 100 }}
                                id='endereco'
                                label='Endereço *'
                                value={values.endereco}
                                onChange={e => setFieldValue('endereco', e.target.value)}
                                onBlur={handleBlur}
                                error={errors.endereco && touched.endereco}
                                helperText={
                                  errors.endereco &&
                                  touched.endereco &&
                                  provider.intl.formatMessage({
                                    id: 'campoObrigatorio'
                                  })
                                }
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </form>
                    </CardIcon>
                  </OnExitConfirmation>
                  <div className={classes.fab}>
                    <FabDiamondMenu
                      actionSalvar={{ onClick: handleSubmit }}
                      actionCancelar={{ onClick: this.handleCancelar }}
                    />
                  </div>
                </div>
              );
            }}
          />
        </div>
      </IntlProvider>
    );
  }
}
const mapStateToProps = state => ({
  usuarioLogado: state.usuarioReducer,
  itensState: state.toolbar,
  estadoList: state.estadoReducer,
  municipioList: state.municipioReducer,
  lojaList: state.lojaReducer,
  vendedorList: state.vendedorReducer
});

let enhanced = withStyles(style)(VendedorForm);
export default connect(mapStateToProps)(withRouter(enhanced));
