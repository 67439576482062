import React from 'react';
import Comercial from './Comercial/Comercial';
import Fabrica from './Fabrica/Fabrica';
import EditarPerfil from './Perfil/Perfil';
import Ambientes from './Ambientes/Ambientes';
import AmbienteForm from './Ambientes/PageActions/AmbienteForm/AmbienteForm';
import Acabamento from './Acabamento/Acabamento';
import AcabamentoForm from './Acabamento/PageActions/AcabamentoForm/AcabamentoForm';
import LojaForm from './Loja/PageActions/LojaForm/LojaForm';
import Loja from './Loja/Loja';
import ParceiroForm from './Parceiro/PageActions/ParceiroForm/ParceiroForm';
import Parceiro from './Parceiro/Parceiro';
import ClienteForm from './Cliente/PageActions/ClienteForm/ClienteForm';
import Cliente from './Cliente/Cliente';
import GerenteForm from './Gerente/PageActions/GerenteForm/GerenteForm';
import Gerente from './Gerente/Gerente';
import Tecnico from './Tecnico/Tecnico';
import TecnicoForm from './Tecnico/PageActions/GerenteForm/TecnicoForm';
import AdministradorForm from './Administrador/PageActions/AdministradorForm/AdministradorForm';
import Administrador from './Administrador/Administrador';
import MetaForm from './Meta/PageActions/MetaForm/MetaForm';
import Meta from './Meta/Meta';
import VendedorForm from './Vendedor/PageActions/VendedorForm/VendedorForm';
import Vendedor from './Vendedor/Vendedor';
import Visita from './Visita/Visita';
import Proposta from './Proposta/Proposta';
import PropostaForm from './Proposta/PropostaForm/PropostaForm';
import ResumoContrato from './ResumoContratos/ResumoContratos';
import Contrato from './Contrato/Contrato';
import ContratoForm from './Contrato/ContratoForm/ContratoForm';
import ResumoProposta from './ResumoProposta/ResumoProposta';
import AcompanhamentoMetas from './AcompanhamentoMetas/AcompanhamentoMetas';
import ConfiguracaoAgenda from './ConfiguracaoAgenda/ConfiguracaoAgenda';
import EditarConfiguracaoAgenda from './ConfiguracaoAgenda/EditarConfiguracaoAgenda';
import Projetos from './Projetos/Projetos';

const routes = [
  {
    path: '/app/comercial',
    exact: true,
    main: () => <Comercial />
  },
  {
    path: '/app/fabrica',
    exact: true,
    main: () => <Fabrica />
  },
  {
    path: '/app/acompanhamento-metas',
    exact: true,
    main: () => <AcompanhamentoMetas />
  },
  {
    path: '/app/resumo-proposta',
    exact: true,
    dashboard: 'Dashboard',
    main: () => <ResumoProposta />
  },
  {
    path: '/app/perfil',
    main: () => <EditarPerfil />
  },
  {
    path: '/app/ambientes',
    exact: true,
    main: () => <Ambientes />
  },
  {
    path: '/app/ambientes/novo',
    exact: true,

    main: () => <AmbienteForm />
  },
  {
    path: '/app/ambientes/editar/:id',
    exact: true,

    main: () => <AmbienteForm />
  },
  {
    path: '/app/acabamentos',
    exact: true,
    main: () => <Acabamento />
  },
  {
    path: '/app/acabamentos/novo',
    exact: true,

    main: () => <AcabamentoForm />
  },
  {
    path: '/app/acabamentos/editar/:id',
    exact: true,

    main: () => <AcabamentoForm />
  },
  {
    path: '/app/lojas',
    exact: true,
    main: () => <Loja />
  },
  {
    path: '/app/lojas/novo',
    exact: true,

    main: () => <LojaForm />
  },
  {
    path: '/app/lojas/editar/:id',
    exact: true,

    main: () => <LojaForm />
  },

  {
    path: '/app/clientes',
    exact: true,
    main: () => <Cliente />
  },
  {
    path: '/app/clientes/novo',
    exact: true,

    main: () => <ClienteForm />
  },
  {
    path: '/app/clientes/editar/:id',
    exact: true,

    main: () => <ClienteForm />
  },
  {
    path: '/app/parceiros',
    exact: true,
    main: () => <Parceiro />
  },
  {
    path: '/app/parceiros/novo',
    exact: true,

    main: () => <ParceiroForm />
  },
  {
    path: '/app/parceiros/editar/:id',
    exact: true,

    main: () => <ParceiroForm />
  },
  {
    path: '/app/gerentes',
    exact: true,
    main: () => <Gerente />
  },
  {
    path: '/app/gerentes/novo',
    exact: true,

    main: () => <GerenteForm />
  },
  {
    path: '/app/gerentes/editar/:id',
    exact: true,

    main: () => <GerenteForm />
  },
  {
    path: '/app/tecnicos',
    exact: true,
    main: () => <Tecnico />
  },
  {
    path: '/app/tecnico/novo',
    exact: true,

    main: () => <TecnicoForm />
  },
  {
    path: '/app/tecnico/editar/:id',
    exact: true,

    main: () => <TecnicoForm />
  },
  {
    path: '/app/administradores',
    exact: true,
    main: () => <Administrador />
  },
  {
    path: '/app/administradores/novo',
    exact: true,

    main: () => <AdministradorForm />
  },
  {
    path: '/app/administradores/editar/:id',
    exact: true,

    main: () => <AdministradorForm />
  },
  {
    path: '/app/metas',
    exact: true,
    main: () => <Meta />
  },
  {
    path: '/app/metas/novo',
    exact: true,

    main: () => <MetaForm />
  },
  {
    path: '/app/metas/editar/:id',
    exact: true,

    main: () => <MetaForm />
  },
  {
    path: '/app/consultor',
    exact: true,
    main: () => <Vendedor />
  },
  {
    path: '/app/consultor/novo',
    exact: true,

    main: () => <VendedorForm />
  },
  {
    path: '/app/consultor/editar/:id',
    exact: true,

    main: () => <VendedorForm />
  },
  {
    path: '/app/visitas',
    exact: true,
    main: () => <Visita />
  },
  {
    path: '/app/propostas',
    exact: true,
    main: () => <Proposta />
  },
  {
    path: '/app/propostas/novo',
    exact: true,
    main: () => <PropostaForm />
  },
  {
    path: '/app/propostas/editar/:id',
    exact: true,
    main: () => <PropostaForm />
  },
  {
    path: '/app/contratos',
    exact: true,
    main: () => <Contrato />
  },
  {
    path: '/app/contratos/editar/:id',
    exact: true,
    main: () => <ContratoForm />
  },
  {
    path: '/app/resumo-contrato',
    exact: true,
    main: () => <ResumoContrato />
  },
  {
    path: '/app/config-agenda',
    exact: true,
    main: () => <ConfiguracaoAgenda />
  },
  {
    path: '/app/config-agenda/novo',
    exact: true,
    main: () => <EditarConfiguracaoAgenda />
  },
  {
    path: '/app/config-agenda/editar/:id',
    exact: true,
    main: () => <EditarConfiguracaoAgenda />
  },
  {
    path: '/app/projetos',
    exact: true,
    main: () => <Projetos />
  }
];

export default routes;
