import en from 'react-intl/locale-data/en'
import pt from 'react-intl/locale-data/pt'
import es from 'react-intl/locale-data/es'
import { addLocaleData } from 'react-intl'

var userLang = navigator.language || navigator.userLanguage

function getUser() {
  userLang = 'pt-BR'
}

getUser()

userLang = 'pt-BR'

addLocaleData([...en, ...pt, ...es])

export function getUserLang(userChange = false) {
  if (userChange) {
    getUser()
  }

  return userLang
}
