import React, { useState, useCallback } from 'react'
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import getCroppedImg from './cropImage'
import { styles } from './styles'

import FABDiamond from '@components/FABDiamond/FABDiamond'
const check = require('@images/check.png')
const ImageCropper = ({
  classes,
  extendedImage,
  setProfileImage,
  setModal,
  imagesCallback,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const loadCroppedImage = useCallback(async () => {
    setProfileImage(null)
    try {
      const croppedImageUrl = await getCroppedImg(
        extendedImage,
        croppedAreaPixels,
        rotation
      )

      setProfileImage(croppedImageUrl)
      var formattedBase64 = croppedImageUrl.split('base64,')[1]
      imagesCallback(formattedBase64)
      setModal(false)
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, rotation])

  return (
    <div>
      <div className={classes.cropContainer}>
        <Cropper
          image={extendedImage}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={1}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          cropShape="round"
          showGrid={false}
        />
      </div>
      <div className={classes.controls}>
        <div className={classes.sliderContainer}>
          <Typography
            variant="overline"
            classes={{ root: classes.sliderLabel }}
          >
            Zoom
          </Typography>
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            classes={{ root: classes.slider }}
            onChange={(e, zoom) => setZoom(zoom)}
          />
        </div>
        <div className={classes.sliderContainer}>
          <Typography
            variant="overline"
            classes={{ root: classes.sliderLabel }}
          >
            Rotacionar
          </Typography>
          <Slider
            value={rotation}
            min={0}
            max={360}
            step={1}
            aria-labelledby="Rotacionar"
            classes={{ root: classes.slider }}
            onChange={(e, rotation) => setRotation(rotation)}
          />
        </div>
        <div className={classes.cropButton}>
          <FABDiamond
            onClickButton={loadCroppedImage}
            url={check}
            style={{
              width: '24px',
              height: '24px',
              paddingTop: '4px',
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(ImageCropper)
