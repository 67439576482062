import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import flattenMessages from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import FormHelperText from '@material-ui/core/FormHelperText';
import * as Yup from 'yup';
import { withStyles } from '@material-ui/core/styles';
import ptBR from './i18n/ptBR';
import CardIcon from '@components/CardIcon/CardIcon';
import { TextField } from '@kepha/sumora-react-components';
import swal from '@sweetalert/with-react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import { insert, update } from '@resources/api/meta';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SwalConfirmacao from '@utils/functions/SwalConfirmacao/SwalConfirmacao';
import FabDiamondMenu from '@components/FabDiamondMenu/FabDiamondMenu';
import { getGerentes } from '@root/actions/gerenteActions';
import { getVendedores } from '@root/actions/vendedorActions';
import { getUsuarioLogado } from '@root/actions/usuarioActions';
import TpUsuarioEnum from '@utils/enums/TpUsuarioEnum';
import { AutoComplete, DatePicker, MaskedTextField } from '@kepha/sumora-react-components';
import { toDate } from '@utils/dateUtils';
import OnExitConfirmation from '@components/OnExitConfirmation/OnExitConfirmation';

let messages = { 'pt-BR': ptBR };
let { provider } = new IntlProvider({});

const style = () => ({
  fab: {
    position: 'fixed',
    right: '15px',
    bottom: '40px',
    zIndex: '2000'
  },
  card: {
    height: 300,
    width: '100%',
    marginTop: '30px'
  },
  underline: {
    '&:after': {
      borderBottomColor: '#EA6909'
    },
    '&:hover:before': {
      borderBottomColor: ['#EA6909', '!important']
    }
  },
  closeButton: {
    position: 'absolute',
    right: 20,
    paddingBottom: '8px',
    height: '40px',
    width: '40px'
  }
});

class MetaForm extends Component {
  constructor(props) {
    super(props);

    const messagesLanguage = flattenMessages(messages[getUserLang()]);
    const codigo = messagesLanguage['editarMeta.cod'];
    const nome = messagesLanguage['editarMeta.nome'];
    const descricao = messagesLanguage['editarMeta.descricao'];
    const campoObrigatorio = messagesLanguage['editarMeta.campoObrigatorio'];
    const identificacao = messagesLanguage['editarMeta.identificacao'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          codigo,
          nome,
          descricao,
          campoObrigatorio,
          identificacao
        }
      },
      {}
    );
    provider = intlProvider.getChildContext();

    this.initialValues = {
      cod: '',
      nome: '',
      descricao: '',
      situacao: 1,
      gerente: '',
      vendedor: '',
      dhInicio: null,
      dhTermino: null,
      vlProposta: '',
      vlContrato: '',
      visitas: '',
      propostas: '',
      contratos: ''
    };

    this.state = {
      vendedorList: [],
      gerenteList: [],
      isGerente: false,
      keyAutoCompleteGerente: '',
      keyAutoCompleteVendedor: '',
      usuario: null,
      dhInicioMessage: 'Campo Obrigatório',
      dhTerminoMessage: 'Campo Obrigatório',
      vlProposta: 'Campo Obrigatório',
      vlContrato: 'Campo Obrigatório',
      canLeave: false,
      meta: { ...this.initialValues }
    };
    let title;
    if (this.props.match.params.id === undefined) {
      title = 'CADASTRAR META';
    } else {
      title = 'EDITAR META';
    }

    if (this.props.itensState.name !== title) {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: title
      });
    }

    this.cancelarEdicao = this.cancelarEdicao.bind(this);
    this.tratarSituacao = this.tratarSituacao.bind(this);
    this.onPressed = this.onPressed.bind(this);
    this.handleCancelar = this.handleCancelar.bind(this);
    this.loadGerentes = this.loadGerentes.bind(this);
    this.loadVendedores = this.loadVendedores.bind(this);
    this.onChangeAutocompleteGerente = this.onChangeAutocompleteGerente.bind(this);
    this.onChangeAutocompleteVendedor = this.onChangeAutocompleteVendedor.bind(this);
    this.mensagemMetaEditadoComSucesso = this.mensagemMetaEditadoComSucesso.bind(this);
    this.mensagemErroAoEditarMeta = this.mensagemErroAoEditarMeta.bind(this);
    this.mensagemProblemaInesperado = this.mensagemProblemaInesperado.bind(this);
    this.mensagemMetaSalvoComSucesso = this.mensagemMetaSalvoComSucesso.bind(this);
    this.mensagemErroAoSalvarMeta = this.mensagemErroAoSalvarMeta.bind(this);
    this.getUsuario = this.getUsuario.bind(this);
    this.getGerente = this.getGerente.bind(this);
    this.getVendedor = this.getVendedor.bind(this);
  }

  mensagemMetaEditadoComSucesso() {
    swal('Meta editada com sucesso', {
      icon: 'success',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-Ok'
        }
      }
    }).then(() => {
      this.cancelarEdicao();
    });
  }

  mensagemMetaSalvoComSucesso() {
    swal('Meta salva com sucesso', {
      icon: 'success',
      confirmButtonColor: '#8CD4F5',
      buttons: {
        confirm: {
          confirmButtonColor: '#8CD4F5',
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-Ok'
        }
      }
    }).then(() => {
      this.cancelarEdicao();
    });
  }
  handleCancelar() {
    SwalConfirmacao({
      text: 'Tem certeza que deseja cancelar esta edição?'
    }).then(res => {
      if (res) {
        this.setState(
          {
            canLeave: true
          },
          () => {
            this.props.history.push('/app/metas');
          }
        );
      }
    });
  }

  mensagemErroAoEditarMeta() {
    swal('Falha ao editar a meta', {
      icon: 'error',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-error'
        }
      }
    });
  }
  async getUsuario() {
    await this.props.dispatch(getUsuarioLogado(this.props.usuarioLogado)).then(usuario => {
      this.setState({
        usuario: usuario
      });
    });
  }
  mensagemErroAoSalvarMeta() {
    swal('Falha ao salvar a meta', {
      icon: 'error',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-error'
        }
      }
    });
  }

  mensagemProblemaInesperado() {
    swal('Ocorreu um problema inesperado', {
      icon: 'error',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-error'
        }
      }
    }).then(() => {
      this.cancelarEdicao();
    });
  }

  tratarSituacao(situacao) {
    switch (situacao) {
      case 'Inativo':
        return 0;
      case 'Ativo':
        return 1;
      default:
        break;
    }
  }

  //Carrega lista de vendedores no state
  async loadVendedores() {
    return await this.props.dispatch(getVendedores(this.props.vendedorList)).then(vendedores => {
      this.setState({
        vendedorList: vendedores
      });
    });
  }

  //Retorna um gerente a partir de um id
  getGerente(id) {
    return this.props.gerenteList.find(item => {
      return item.idUsuario === id;
    });
  }

  //Retorna um vendedor a partir de um id
  getVendedor(id) {
    return this.props.vendedorList.find(item => {
      return item.idUsuario === id;
    });
  }

  //Carrega lista de gerentes no state, e checa se o usuario é um gerente
  async loadGerentes() {
    return await this.props.dispatch(getGerentes(this.props.gerenteList)).then(gerentes => {
      var usuario = this.state.usuario;

      if (usuario.tpUsuario === TpUsuarioEnum.GERENTE) {
        var gerente = gerentes.find(item => {
          return item.idUsuario === usuario.idUsuario;
        });
        this.setState({
          gerente: gerente,
          isGerente: true
        });
      }
      this.setState({
        gerenteList: gerentes
      });
    });
  }

  //Trata a submissão do formulário
  onPressed(values) {
    swal(
      <div>
        <CircularProgress style={{ color: '#EA6909' }} />
      </div>,
      {
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        title: this.props.match.params.id !== undefined ? 'Atualizando' : 'Salvando'
      }
    );

    const data = {
      idMeta: this.props.match.params.id,
      idVendedor: values.vendedor.idUsuario,
      idGerente: values.gerente.idUsuario,
      nrVisita: values.visitas,
      nrProposta: values.propostas,
      nrContrato: values.contratos,
      dhInicio: toDate(values.dhInicio),
      dhTermino: toDate(values.dhTermino),
      vlProposta: values.vlProposta,
      vlContrato: values.vlContrato,
      stMeta: values.situacao
    };
    if (this.props.match.params.id !== undefined && this.props.match.params.id !== null) {
      update(data)
        .then(res => {
          return this.mensagemMetaEditadoComSucesso();
        })
        .catch(err => {
          return this.mensagemErroAoEditarMeta();
        });
    } else {
      insert(data)
        .then(res => {
          return this.mensagemMetaSalvoComSucesso();
        })
        .catch(err => {
          return this.mensagemErroAoSalvarMeta();
        });
    }
  }

  //Volta para a listagem
  cancelarEdicao() {
    this.setState(
      {
        canLeave: true
      },
      () => {
        this.props.history.push('/app/metas');
      }
    );
  }

  //Em caso de edição, carrega os dados no form
  async componentDidMount() {
    await this.getUsuario();
    await this.loadGerentes();
    await this.loadVendedores();
    var data = this.props.location.state !== undefined ? this.props.location.state.meta : undefined;

    if (data !== undefined) {
      var vendedor = this.getVendedor(data.idVendedor);
      var gerente = this.getGerente(data.idGerente);
      var inicio = data.dhInicio.split('T')[0];
      var termino = data.dhTermino.split('T')[0];
      this.initialValues = {
        vendedor: vendedor,
        gerente: gerente,
        visitas: data.nrVisita,
        propostas: data.nrProposta,
        contratos: data.nrContrato,
        dhInicio: inicio,
        dhTermino: termino,
        vlProposta: data.vlProposta,
        vlContrato: data.vlContrato,
        situacao: data.stMeta
      };

      this.setState(
        {
          meta: { ...this.initialValues }
        },
        () => {
          this.setState({
            keyAutoCompleteGerente: Math.random(),
            keyAutoCompleteVendedor: Math.random()
          });
        }
      );
    }
  }
  // trata quando um valor é selecionado enquanto o usuario digita no AutoComplete de gerente
  onChangeAutocompleteGerente(value) {
    let errors = this.errors;
    this.reinitialize = false;
    this.setFieldValue && this.setFieldValue('gerente', value);
    this.errors = errors;
  }

  // trata quando um valor é selecionado enquanto o usuario digita no AutoComplete de vendedor
  onChangeAutocompleteVendedor(value) {
    let errors = this.errors;
    this.reinitialize = false;
    this.setFieldValue && this.setFieldValue('vendedor', value);
    this.errors = errors;
  }

  render() {
    const { classes } = this.props;

    return (
      <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
        <div style={{ width: '100%', marginTop: 15 }}>
          <Formik
            initialValues={{
              ...this.initialValues
            }}
            onSubmit={this.onPressed}
            validateOnBlur
            enableReinitialize={true}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              gerente: Yup.string().required(<FormattedMessage id='editarMeta.campoObrigatorio' />),
              vendedor: Yup.string().required(<FormattedMessage id='editarMeta.campoObrigatorio' />),
              dhInicio: Yup.date().required(<FormattedMessage id='editarMeta.campoObrigatorio' />),
              dhTermino: Yup.date().required(<FormattedMessage id='editarMeta.campoObrigatorio' />),
              vlProposta: Yup.string().required(<FormattedMessage id='editarMeta.campoObrigatorio' />),
              vlContrato: Yup.string().required(<FormattedMessage id='editarMeta.campoObrigatorio' />),
              propostas: Yup.string().required(<FormattedMessage id='editarMeta.campoObrigatorio' />),
              visitas: Yup.string().required(<FormattedMessage id='editarMeta.campoObrigatorio' />),
              contratos: Yup.string().required(<FormattedMessage id='editarMeta.campoObrigatorio' />),
              situacao: Yup.string().required(<FormattedMessage id='editarMeta.campoObrigatorio' />)
            })}
            render={({
              values,
              handleSubmit,
              errors,
              touched,
              handleBlur,
              handleChange,
              setFieldValue
            }) => {
              this.values = { ...values };
              this.setFieldValue = setFieldValue;
              this.errors = errors;
              return (
                <div>
                  <OnExitConfirmation
                    values={this.values}
                    initialValues={this.initialValues}
                    canLeave={this.state.canLeave}
                  >
                    <CardIcon
                      titulo={provider.intl.formatMessage({
                        id: 'identificacao'
                      })}
                      disableExpandButton={true}
                    >
                      <form autoComplete='off'>
                        <div className={classes.card}>
                          <Grid container spacing={3}>
                            <Grid item xs={6}>
                              <AutoComplete
                                itens={this.state.gerenteList}
                                value={values.gerente}
                                disabled={this.state.isGerente}
                                campoOp='nmUsuario'
                                valueAutoComplete={values.gerente}
                                campoChave='idUsuario'
                                label='Nome do Gerente *'
                                name='gerente'
                                id='gerente'
                                error={errors.gerente && touched.gerente}
                                helperText={
                                  errors.gerente &&
                                  touched.gerente &&
                                  provider.intl.formatMessage({
                                    id: 'campoObrigatorio'
                                  })
                                }
                                onChange={this.onChangeAutocompleteGerente}
                                onChangeAutoComplete={this.loadGerentes}
                                key={this.state.keyAutoCompleteGerente}
                                maxHeight='calc(100vh - 390px)'
                              />
                            </Grid>

                            <Grid item xs={6}>
                              <AutoComplete
                                itens={this.state.vendedorList}
                                value={values.vendedor}
                                campoOp='nmUsuario'
                                valueAutoComplete={values.vendedor}
                                campoChave='idUsuario'
                                label='Nome do Consultor *'
                                name='vendedor'
                                id='vendedor'
                                error={errors.vendedor && touched.vendedor}
                                helperText={
                                  errors.vendedor &&
                                  touched.vendedor &&
                                  provider.intl.formatMessage({
                                    id: 'campoObrigatorio'
                                  })
                                }
                                onChange={this.onChangeAutocompleteVendedor}
                                onChangeAutoComplete={this.loadVendedores}
                                key={this.state.keyAutoCompleteVendedor}
                                maxHeight='calc(100vh - 390px)'
                              />
                            </Grid>

                            <Grid item xs={3}>
                              <DatePicker
                                minDate={new Date()}
                                userlang='pt-BR'
                                label='Data de Inicio *'
                                value={values.dhInicio}
                                onChange={date => {
                                  if (date != null) {
                                    setFieldValue('dhInicio', date._d);
                                  } else {
                                    setFieldValue('dhInicio', null);
                                  }
                                }}
                                errorTouched={errors.dhInicio && touched.dhInicio}
                                helperText={this.state.dhInicioMessage}
                                onBlur={date => {
                                  if (date.target.value.length === 0) {
                                    this.setState({
                                      dhInicioMessage: 'Campo Obrigatório'
                                    });
                                    handleBlur('dhInicio')(date);
                                    return;
                                  }
                                  let schema = Yup.date();
                                  let validation = schema.isValidSync(values.dhInicio);
                                  if (!validation) {
                                    setFieldValue('dhInicio', null);
                                    this.setState({
                                      dhInicioMessage: 'Data Inválida'
                                    });
                                  }
                                  handleBlur('dhInicio')(date);
                                }}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <DatePicker
                                minDate={values.dhInicio || new Date()}
                                userlang='pt-BR'
                                label='Data de Término *'
                                value={values.dhTermino}
                                onChange={date => {
                                  if (date != null) {
                                    setFieldValue('dhTermino', date._d);
                                  } else {
                                    setFieldValue('dhTermino', null);
                                  }
                                }}
                                errorTouched={errors.dhTermino && touched.dhTermino}
                                helperText={this.state.dhTerminoMessage}
                                onBlur={date => {
                                  if (date.target.value.length === 0) {
                                    this.setState({
                                      dhInicioMessage: 'Campo Obrigatório'
                                    });
                                    handleBlur('dhTermino')(date);
                                    return;
                                  }
                                  let schema = Yup.date();
                                  let validation = schema.isValidSync(values.dhTermino);
                                  if (!validation) {
                                    setFieldValue('dhTermino', null);
                                    this.setState({
                                      dhTerminoMessage: 'Data Inválida'
                                    });
                                  }
                                  handleBlur('dhTermino')(date);
                                }}
                              />
                            </Grid>

                            <Grid item xs={6} />

                            <Grid item xs={3}>
                              <TextField
                                type='number'
                                InputProps={{
                                  classes: { underline: classes.underline }
                                }}
                                id='visitas'
                                label='Meta de Visitas *'
                                value={values.visitas}
                                onChange={e => setFieldValue('visitas', e.target.value)}
                                onBlur={handleBlur}
                                error={errors.visitas && touched.visitas}
                                helperText={
                                  errors.visitas &&
                                  touched.visitas &&
                                  provider.intl.formatMessage({
                                    id: 'campoObrigatorio'
                                  })
                                }
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <TextField
                                type='number'
                                InputProps={{
                                  classes: { underline: classes.underline }
                                }}
                                id='propostas'
                                label='Meta de Propostas *'
                                value={values.propostas}
                                onChange={e => setFieldValue('propostas', e.target.value)}
                                onBlur={handleBlur}
                                error={errors.propostas && touched.propostas}
                                helperText={
                                  errors.propostas &&
                                  touched.propostas &&
                                  provider.intl.formatMessage({
                                    id: 'campoObrigatorio'
                                  })
                                }
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <TextField
                                type='number'
                                InputProps={{
                                  classes: { underline: classes.underline }
                                }}
                                id='contratos'
                                label='Meta de Contratos *'
                                value={values.contratos}
                                onChange={e => setFieldValue('contratos', e.target.value)}
                                onBlur={handleBlur}
                                error={errors.contratos && touched.contratos}
                                helperText={
                                  errors.contratos &&
                                  touched.contratos &&
                                  provider.intl.formatMessage({
                                    id: 'campoObrigatorio'
                                  })
                                }
                              />
                            </Grid>

                            <Grid item xs={3} />

                            <Grid item xs={3}>
                              <MaskedTextField
                                label='Valor da Proposta'
                                tipoMascara='dinheiro'
                                isAllowed={value =>
                                  value.floatValue >= 1 && value.floatValue <= 99999999.99
                                }
                                userlang='pt-BR'
                                InputProps={{
                                  classes: { underline: classes.underline }
                                }}
                                id='vlProposta'
                                value={values.vlProposta}
                                onChange={e => setFieldValue('vlProposta', '' + e.target.value)}
                                onBlur={handleBlur}
                                error={errors.vlProposta && touched.vlProposta}
                                helperText={
                                  errors.vlProposta &&
                                  touched.vlProposta &&
                                  provider.intl.formatMessage({
                                    id: 'campoObrigatorio'
                                  })
                                }
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <MaskedTextField
                                label='Valor do Contrato'
                                tipoMascara='dinheiro'
                                isAllowed={value =>
                                  value.floatValue >= 1 && value.floatValue <= 99999999.99
                                }
                                userlang='pt-BR'
                                InputProps={{
                                  classes: { underline: classes.underline }
                                }}
                                id='vlContrato'
                                value={values.vlContrato}
                                onChange={e => setFieldValue('vlContrato', '' + e.target.value)}
                                onBlur={handleBlur}
                                error={errors.vlContrato && touched.vlContrato}
                                helperText={
                                  errors.vlContrato &&
                                  touched.vlContrato &&
                                  provider.intl.formatMessage({
                                    id: 'campoObrigatorio'
                                  })
                                }
                              />
                            </Grid>

                            <Grid item xs={1}>
                              <FormControl
                                className={classes.formControl}
                                error={errors.situacao && touched.situacao}
                              >
                                <FormControlLabel
                                  style={{
                                    marginTop: 13,
                                    position: 'absolute'
                                  }}
                                  id='situacao'
                                  name='situacao'
                                  control={
                                    <Checkbox
                                      color='primary'
                                      checked={values.situacao}
                                      onChange={e => {
                                        var situacao = e.target.checked ? 1 : 0;
                                        setFieldValue('situacao', situacao);
                                      }}
                                    />
                                  }
                                  label={
                                    <div
                                      style={{
                                        color: '#757575'
                                      }}
                                    >
                                      Ativo
                                    </div>
                                  }
                                />
                                {errors.situacao && touched.situacao && (
                                  <FormHelperText>
                                    {provider.intl.formatMessage({
                                      id: 'campoObrigatorio'
                                    })}
                                  </FormHelperText>
                                )}{' '}
                              </FormControl>
                            </Grid>
                          </Grid>
                        </div>
                      </form>
                    </CardIcon>
                  </OnExitConfirmation>
                  <div className={classes.fab}>
                    <FabDiamondMenu
                      actionSalvar={{ onClick: handleSubmit }}
                      actionCancelar={{ onClick: this.handleCancelar }}
                    />
                  </div>
                </div>
              );
            }}
          />
        </div>
      </IntlProvider>
    );
  }
}
const mapStateToProps = state => ({
  usuarioLogado: state.usuarioReducer,
  itensState: state.toolbar,
  gerenteList: state.gerenteReducer,
  vendedorList: state.vendedorReducer
});

let enhanced = withStyles(style)(MetaForm);
export default connect(mapStateToProps)(withRouter(enhanced));
