import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import './style/style.css'
import { FormattedMessage } from 'react-intl'

const styles = theme => ({
  labelEntrar: {
    color: 'white',
    fontWeight: 'lighter',
    fontSize: 14,
    cursor: 'pointer',
  },
  marginBotao: {
    margin: theme.spacing(),
    background: theme.palette.primary,
  },
  botaoProgresso: {
    color: theme.palette.primary,
    position: 'relative',
    margin: '8px 1px 0 0',
  },
  divBotao: {
    margin: theme.spacing(),
    justifyContent: 'center',
    alignItems: 'center',
    position: 'center',
    height: '53.74px !important',
    width: '53.74px !important',
    borderRadius: 10,
    boxShadow: '10px 10px 15px rgba(0, 0, 0, 0.2)',
    transition: 'all 0.3s ease',
    transform: 'rotate(45deg)',
  },
  centralIcon: {
    width: '15px',
    height: '15px',
    transform: 'rotate(-44deg)',
    padding: '0 3px 0 0px',
  },
  disabledButton: {
    backgroundColor: '#E8DAD1',
  },
})

function FABDiamond(props) {
  const { classes } = props

  const imageStyle =
    props.imageStyle !== undefined
      ? props.imageStyle
      : { width: '15px', height: '15px' }

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        className={classes.divBotao}
        style={{
          width: props.statusConfirmarCadatro ? 53.74 : 126,
          maxWidth: props.statusConfirmarCadatro ? '53.74px' : '126px',
          maxHeight: '53.74px',
          minWidth: '53.74px',
          minHeight: '53.74px',
        }}
        onClick={props.onClickButton}
        disabled={props.loadingButton}
        classes={{ disabled: classes.disabledButton }}
      >
        <label className={classes.labelEntrar}>
          {props.loadingButton ? (
            <div>
              <CircularProgress
                size={24}
                style={{ marginLeft: props.marginLeft }}
                className={classes.botaoProgresso}
              />
            </div>
          ) : (
            <img
              src={props.url}
              className={classes.centralIcon}
              style={props.style === undefined ? imageStyle : props.style}
              alt={<FormattedMessage id="telaLogin.login" />}
            ></img>
          )}
        </label>
      </Button>
    </div>
  )
}

export default withStyles(styles)(FABDiamond)
