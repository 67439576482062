import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withStyles } from '@material-ui/core/styles';
import CardIcon from '@components/CardIcon/CardIcon';
import { TextField } from '@kepha/sumora-react-components';
import swal from '@sweetalert/with-react';
import Grid from '@material-ui/core/Grid';
import { insert, update } from '@resources/api/configuracaoAgenda';
import CircularProgress from '@material-ui/core/CircularProgress';
import SwalConfirmacao from '@utils/functions/SwalConfirmacao/SwalConfirmacao';
import FabDiamondMenu from '@components/FabDiamondMenu/FabDiamondMenu';
import OnExitConfirmation from '@components/OnExitConfirmation/OnExitConfirmation';
import Checkbox from '@material-ui/core/Checkbox';
import { DatePicker } from '@kepha/sumora-react-components';
import {
  MuiThemeProvider,
  createMuiTheme,
  FormControlLabel,
  FormControl,
  InputLabel,
  MenuItem,
  Input,
  FormHelperText,
  Select
} from '@material-ui/core';
import * as moment from 'moment';

const style = () => ({
  styleCheck: {
    color: '#757575',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  fab: {
    position: 'fixed',
    right: '15px',
    bottom: '40px',
    zIndex: '2000'
  },
  card: {
    height: 200,
    width: '100%',
    marginTop: '30px'
  },
  underline: {
    '&:after': {
      borderBottomColor: ['#EA6909', '!important']
    },
    '&:hover:before': {
      borderBottomColor: ['#EA6909', '!important'],
      borderBottomWidth: ['1px', '!important']
    }
  },
  closeButton: {
    position: 'absolute',
    right: 20,
    paddingBottom: '8px',
    height: '40px',
    width: '40px'
  }
});

/**
 * Tela de cadastro e edição de configuração de agenda
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 */
class EditarConfiguracaoAgenda extends Component {
  constructor(props) {
    super(props);

    this.initialValues = {
      idConfigAgenda: '',
      dsConfigAgenda: '',
      stConfigAgenda: 0,
      nrSlots: '',
      dtFinal: null,
      dtInicial: null,
      stBloqueiaDomingo: 0,
      stBloqueiaSabado: 0,
      stBloqueio: 0,
      stRecorrente: 0,
      tpAgenda: ''
    };

    this.state = {
      dateMessage: 'Campo obrigatório',
      configAgenda: { ...this.initialValues },
      canLeave: false
    };
    let title;
    if (this.props.match.params.id === undefined) {
      title = 'CADASTRAR CONFIGURAÇÃO';
    } else {
      title = 'EDITAR CONFIGURAÇÃO';
    }

    if (this.props.itensState.name !== title) {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: title,
        name: title
      });
    }

    this.cancelarEdicao = this.cancelarEdicao.bind(this);

    this.onPressed = this.onPressed.bind(this);

    this.mensagemAgendaEditadoComSucesso = this.mensagemAgendaEditadoComSucesso.bind(this);
    this.mensagemErroAoEditarAgenda = this.mensagemErroAoEditarAgenda.bind(this);
    this.mensagemProblemaInesperado = this.mensagemProblemaInesperado.bind(this);
    this.mensagemAgendaSalvoComSucesso = this.mensagemAgendaSalvoComSucesso.bind(this);
    this.mensagemErroAoSalvarAgenda = this.mensagemErroAoSalvarAgenda.bind(this);
    this.handleCancelar = this.handleCancelar.bind(this);
    this.cancelarEdicao = this.cancelarEdicao.bind(this);
  }

  /**
   * Retorna swal de mensagem de sucesso ao editar
   */
  mensagemAgendaEditadoComSucesso() {
    swal('Configuração de agenda editada com sucesso', {
      icon: 'success',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-Ok'
        }
      }
    }).then(() => {
      this.cancelarEdicao();
    });
  }

  /**
   * Retorna swal de mensagem de sucesso ao salvar
   */
  mensagemAgendaSalvoComSucesso() {
    swal('Configuração de agenda salva com sucesso', {
      icon: 'success',
      confirmButtonColor: '#8CD4F5',
      buttons: {
        confirm: {
          confirmButtonColor: '#8CD4F5',
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-Ok'
        }
      }
    }).then(() => {
      this.cancelarEdicao();
    });
  }

  /**
   * Retorna swal de mensagem de erro ao editar
   */
  mensagemErroAoEditarAgenda() {
    swal('Falha ao editar a configuração de agenda', {
      icon: 'error',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-error'
        }
      }
    });
  }

  /**
   * Confirma e sai da tela
   */
  handleCancelar() {
    SwalConfirmacao({
      text: 'Tem certeza que deseja cancelar esta edição?'
    }).then(res => {
      if (res) {
        this.setState(
          {
            canLeave: true
          },
          () => {
            this.props.history.push('/app/config-agenda');
          }
        );
      }
    });
  }

  /**
   * Retorna swal de mensagem de erro ao salvar agenda
   */
  mensagemErroAoSalvarAgenda() {
    swal('Falha ao salvar a configuração de agenda', {
      icon: 'error',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-error'
        }
      }
    });
  }

  /**
   * Retorna swal de mensagem de erro
   */
  mensagemProblemaInesperado() {
    swal('Ocorreu um problema inesperado', {
      icon: 'error',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-error'
        }
      }
    }).then(() => {
      this.cancelarEdicao();
    });
  }

  /**
   * /Trata a submissão do formulário
   *
   * @param values - values para salvar
   */
  onPressed(values) {
    swal(
      <div>
        <CircularProgress style={{ color: '#EA6909' }} />
      </div>,
      {
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        title: this.props.match.params.id !== undefined ? 'Atualizando' : 'Salvando'
      }
    );

    const data = {
      ...values,
      dtFinal: values.dtFinal ? moment(values.dtFinal).format('YYYY-MM-DD') : null,
      dtInicial: moment(values.dtInicial).format('YYYY-MM-DD'),
      nrSlots: parseInt(values.nrSlots),
      idConfigAgenda: null
    };

    if (this.props.match.params.id !== undefined && this.props.match.params.id !== null) {
      data.idConfigAgenda = this.props.match.params.id;
      update(data)
        .then(res => {
          return this.mensagemAgendaEditadoComSucesso();
        })
        .catch(err => {
          return this.mensagemErroAoEditarAgenda();
        });
    } else {
      insert(data)
        .then(res => {
          return this.mensagemAgendaSalvoComSucesso();
        })
        .catch(err => {
          return this.mensagemErroAoSalvarAgenda();
        });
    }
  }

  /**
   * Volta para tela de edição
   */
  cancelarEdicao() {
    this.setState(
      {
        canLeave: true
      },
      () => {
        this.props.history.push('/app/config-agenda');
      }
    );
  }

  /**
   * Executado ao entrar na tela, em caso de edição, carrega todos os campos no formulário
   */
  async componentDidMount() {
    var data =
      this.props.location.state !== undefined ? this.props.location.state.configAgenda : undefined;

    if (data !== undefined) {
      this.initialValues = {
        nrSlots: '',
        dtFinal: null,
        ...data
      };

      this.setState({
        configAgenda: { ...this.initialValues }
      });
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div style={{ width: '100%', marginTop: 15 }}>
        <Formik
          initialValues={{
            ...this.initialValues
          }}
          onSubmit={this.onPressed}
          validateOnBlur
          enableReinitialize={true}
          validateOnChange={false}
          validationSchema={Yup.object().shape({
            dsConfigAgenda: Yup.string().required('Campo obrigatório'),
            tpAgenda: Yup.string().required('Campo obrigatório'),
            dtFinal: Yup.date().nullable(),
            dtInicial: Yup.date().required('Campo obrigatório'),
            nrSlots:
              this.initialValues.stBloqueio === 0
                ? Yup.string().required('Campo obrigatório')
                : Yup.string()
          })}
          render={({
            values,
            handleSubmit,
            errors,
            touched,
            handleBlur,
            handleChange,
            setFieldValue
          }) => {
            this.values = { ...values };
            this.setFieldValue = setFieldValue;
            this.errors = errors;

            return (
              <MuiThemeProvider
                theme={createMuiTheme({
                  palette: {
                    primary: {
                      main: '#EA6909'
                    },
                    card: '#E8DAD1'
                  },
                  overrides: {
                    MuiTypography: {
                      body1: {
                        overflow: 'hidden'
                      }
                    }
                  },
                  typography: {
                    useNextVariants: true
                  }
                })}
              >
                <div>
                  <OnExitConfirmation
                    values={this.values}
                    initialValues={this.initialValues}
                    canLeave={this.state.canLeave}
                  >
                    <CardIcon titulo={'Identificação'} disableExpandButton={true}>
                      <form autoComplete='off'>
                        <div className={classes.card}>
                          <Grid container>
                            <Grid item xs={4} style={{ marginTop: 9, paddingRight: 15 }}>
                              <TextField
                                InputProps={{
                                  maxLength: 60,
                                  classes: { underline: classes.underline }
                                }}
                                id='dsConfigAgenda'
                                label={'Descrição'}
                                value={values.dsConfigAgenda}
                                onChange={e => setFieldValue('dsConfigAgenda', e.target.value)}
                                onBlur={handleBlur}
                                error={errors.dsConfigAgenda && touched.dsConfigAgenda}
                                helperText={
                                  errors.dsConfigAgenda && touched.dsConfigAgenda && 'Campo obrigatório'
                                }
                              />
                            </Grid>

                            <Grid item xs={3} style={{ paddingRight: 15, marginTop: 12 }}>
                              <FormControl
                                fullWidth
                                style={{ marginTop: -2 }}
                                className={classes.tpAgenda}
                                error={errors.tpAgenda && touched.tpAgenda}
                              >
                                <InputLabel style={{ fontSize: 14 }} htmlFor='statusProposta'>
                                  Tipo de agenda
                                </InputLabel>
                                <Select
                                  SelectDisplayProps={{
                                    style: { backgroundColor: 'transparent' }
                                  }}
                                  input={<Input id='statusProposta' />}
                                  value={values.tpAgenda}
                                  name='tpAgenda'
                                  onChange={e => {
                                    setFieldValue('tpAgenda', e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                  error={errors.tpAgenda && touched.tpAgenda}
                                >
                                  <MenuItem value={0}>Comercial</MenuItem>
                                  <MenuItem value={1}>Produção</MenuItem>
                                  <MenuItem value={2}>Ambas</MenuItem>
                                </Select>
                                <FormHelperText>
                                  {errors.tpAgenda && touched.tpAgenda ? errors.tpAgenda : null}
                                </FormHelperText>
                              </FormControl>
                            </Grid>

                            <Grid item xs={3} style={{ marginTop: 10 }}>
                              <FormControl
                                style={{ width: '100%' }}
                                className={classes.formControl}
                                error={errors.stBloqueio && touched.stBloqueio}
                              >
                                <FormControlLabel
                                  style={{
                                    marginTop: 13,
                                    position: 'absolute',
                                    width: '100%'
                                  }}
                                  id='stBloqueio'
                                  name='stBloqueio'
                                  control={
                                    <Checkbox
                                      color='primary'
                                      checked={values.stBloqueio === 1}
                                      onChange={e => {
                                        var stBloqueio = e.target.checked ? 1 : 0;
                                        setFieldValue('stBloqueio', stBloqueio);
                                        if (stBloqueio === 0) {
                                          setFieldValue('stRecorrente', 0);
                                          setFieldValue('nrSlots', '');
                                          this.initialValues = {
                                            ...values,
                                            stBloqueio: stBloqueio,
                                            nrSlots: '',
                                            stRecorrente: 0
                                          };
                                        } else if (stBloqueio === 1) {
                                          setFieldValue('nrSlots', '');
                                          this.initialValues = {
                                            ...values,
                                            stBloqueio: stBloqueio,
                                            nrSlots: ''
                                          };
                                        }

                                        this.setState({});
                                      }}
                                    />
                                  }
                                  label={<div className={classes.styleCheck}>Bloqueia produção</div>}
                                />
                              </FormControl>
                            </Grid>

                            <Grid item xs={2} style={{ marginTop: 9, paddingRight: 15 }}>
                              <TextField
                                type='number'
                                disabled={values.stBloqueio === 1}
                                InputProps={{
                                  classes: { underline: classes.underline }
                                }}
                                id='nrSlots'
                                label='Slots disponíveis'
                                value={values.nrSlots}
                                onChange={e => setFieldValue('nrSlots', e.target.value)}
                                onBlur={handleBlur}
                                error={errors.nrSlots && touched.nrSlots}
                                helperText={errors.nrSlots && touched.nrSlots && 'Campo obrigatório'}
                              />
                            </Grid>
                          </Grid>

                          <Grid container>
                            <Grid item xs={2} style={{ marginTop: 9, paddingRight: 15 }}>
                              <DatePicker
                                userlang='pt-BR'
                                label='Data Inicial'
                                value={values.dtInicial}
                                errorTouched={errors.dtInicial && touched.dtInicial}
                                helperText={errors.dtInicial && touched.dtInicial && 'Campo obrigatório'}
                                onChange={date => {
                                  if (date !== null) {
                                    setFieldValue('dtInicial', date._d);
                                  } else {
                                    setFieldValue('dtInicial', null);
                                  }

                                  setFieldValue('dtFinal', null);
                                }}
                              />
                            </Grid>

                            <Grid item xs={2} style={{ marginTop: 9, paddingRight: 15 }}>
                              <DatePicker
                                userlang='pt-BR'
                                label='Data Final'
                                minDate={values.dtInicial || new Date('01-01-1900')}
                                value={values.dtFinal}
                                errorTouched={errors.dtFinal && touched.dtFinal}
                                helperText={errors.dtFinal && touched.dtFinal && 'Campo obrigatório'}
                                onChange={date => {
                                  if (date !== null) {
                                    setFieldValue('dtFinal', date._d);
                                  } else {
                                    setFieldValue('dtFinal', null);
                                  }
                                }}
                              />
                            </Grid>

                            <Grid item xs={3} style={{ marginTop: 10 }}>
                              <FormControl
                                style={{ width: '100%' }}
                                className={classes.formControl}
                                error={errors.stBloqueiaSabado && touched.stBloqueiaSabado}
                              >
                                <FormControlLabel
                                  style={{
                                    width: '100%',
                                    marginTop: 13,
                                    position: 'absolute'
                                  }}
                                  id='stBloqueiaSabado'
                                  name='stBloqueiaSabado'
                                  control={
                                    <Checkbox
                                      color='primary'
                                      checked={values.stBloqueiaSabado === 1}
                                      onChange={e => {
                                        var stBloqueiaSabado = e.target.checked ? 1 : 0;
                                        setFieldValue('stBloqueiaSabado', stBloqueiaSabado);
                                      }}
                                    />
                                  }
                                  label={<div className={classes.styleCheck}>Bloqueia sábado</div>}
                                />
                              </FormControl>
                            </Grid>

                            <Grid item xs={3} style={{ marginTop: 10 }}>
                              <FormControl
                                style={{ width: '100%' }}
                                className={classes.formControl}
                                error={errors.stBloqueiaDomingo && touched.stBloqueiaDomingo}
                              >
                                <FormControlLabel
                                  style={{
                                    width: '100%',
                                    marginTop: 13,
                                    position: 'absolute'
                                  }}
                                  id='stBloqueiaDomingo'
                                  name='stBloqueiaDomingo'
                                  control={
                                    <Checkbox
                                      color='primary'
                                      checked={values.stBloqueiaDomingo === 1}
                                      onChange={e => {
                                        var stBloqueiaDomingo = e.target.checked ? 1 : 0;
                                        setFieldValue('stBloqueiaDomingo', stBloqueiaDomingo);
                                      }}
                                    />
                                  }
                                  label={<div className={classes.styleCheck}>Bloqueia domingo</div>}
                                />
                              </FormControl>
                            </Grid>

                            <Grid item xs={2} style={{ marginTop: 10 }}>
                              <FormControl
                                disabled={values.stBloqueio !== 1}
                                style={{ width: '100%' }}
                                className={classes.formControl}
                                error={errors.stRecorrente && touched.stRecorrente}
                              >
                                <FormControlLabel
                                  style={{
                                    width: '100%',
                                    marginTop: 13,
                                    position: 'absolute'
                                  }}
                                  id='stRecorrente'
                                  name='stRecorrente'
                                  control={
                                    <Checkbox
                                      color='primary'
                                      checked={values.stRecorrente === 1}
                                      onChange={e => {
                                        var stRecorrente = e.target.checked ? 1 : 0;
                                        setFieldValue('stRecorrente', stRecorrente);
                                      }}
                                    />
                                  }
                                  label={
                                    <div
                                      style={{
                                        color:
                                          values.stBloqueio !== 1 ? 'rgb(117, 117, 117, 0.5)' : '#757575'
                                      }}
                                      className={classes.styleCheck}
                                    >
                                      Recorrente
                                    </div>
                                  }
                                />
                              </FormControl>
                            </Grid>
                          </Grid>

                          <Grid container>
                            <Grid item xs={1}>
                              <FormControl
                                fullWidth
                                className={classes.formControl}
                                error={errors.stConfigAgenda && touched.stConfigAgenda}
                              >
                                <FormControlLabel
                                  style={{
                                    marginTop: 25,
                                    position: 'absolute',
                                    height: 60
                                  }}
                                  id='stConfigAgenda'
                                  name='stConfigAgenda'
                                  control={
                                    <Checkbox
                                      color='primary'
                                      checked={values.stConfigAgenda === 1}
                                      onChange={e => {
                                        var stConfigAgenda = e.target.checked ? 1 : 0;
                                        setFieldValue('stConfigAgenda', stConfigAgenda);
                                      }}
                                    />
                                  }
                                  label={<div className={classes.styleCheck}>Ativo</div>}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </div>
                      </form>
                    </CardIcon>
                  </OnExitConfirmation>
                  <div className={classes.fab}>
                    <FabDiamondMenu
                      actionSalvar={{ onClick: handleSubmit }}
                      actionCancelar={{ onClick: this.handleCancelar }}
                    />
                  </div>
                </div>
              </MuiThemeProvider>
            );
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  UsuarioLogado: state.usuarioReducer,
  itensState: state.toolbar,
  municipioList: state.municipioReducer
});

let enhanced = withStyles(style)(EditarConfiguracaoAgenda);
export default connect(mapStateToProps)(withRouter(enhanced));
