import * as React from 'react'

function SvgIconAdd(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
      <path
        data-name="Path 286"
        d="M14 8H8v6H6V8H0V6h6V0h2v6h6z"
        fill="#ea6909"
      />
    </svg>
  )
}

export default SvgIconAdd