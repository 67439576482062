export default {
  acabamento: {
    title: 'ACABAMENTOS',
    cod: 'Cód.',
    nomeCol: 'Nome',
    descricao: 'Descrição',
    situacao: 'Situação',
    nrDias: 'Dias',
  },
}
