import { getAll as getAllGerentes } from '@resources/api/gerente'

const getGerente = (list, id) => {
  return async dispatch => {
    var gerente
    if (list.length > 0) {
      gerente = list.find(el => {
        return el.idUsuario === id
      })
    }

    if (gerente === undefined) {
      await getAllGerentes().then(res => {
        gerente = res.data.find(el => {
          return el.idUsuario === id
        })
        list = res.data
        dispatch({
          type: 'ADD_GERENTES',
          list: list,
        })
      })
    }

    return gerente.nmUsuario
  }
}

const getGerentes = list => {
  return async dispatch => {
    if (list.length > 0) {
      return list
    }

    await getAllGerentes().then(res => {
      list = res.data
      dispatch({
        type: 'ADD_GERENTES',
        list: list,
      })
    })

    return list
  }
}

const refreshGerenteList = list => {
  return async dispatch => {
    await getAllGerentes().then(res => {
      list = res.data
      dispatch({
        type: 'ADD_GERENTES',
        list: list,
      })
    })
    return list
  }
}
export { getGerente, getGerentes, refreshGerenteList }
