import * as React from 'react'

function SvgIconeContratos(props) {
  return (
    <svg width="1.30em" height="1.30em" viewBox="0 0 22 22" {...props}>
      <path data-name="Path 85" d="M0 0h24v24H0z" fill="none" />
      <path
        data-name="Path 86"
        d="M14 2H6a2 2 0 00-1.99 2L4 20a2 2 0 001.99 2H18a2.006 2.006 0 002-2V8zm2 16H8v-2h8zm0-4H8v-2h8zm-3-5V3.5L18.5 9z"
        fill={props.color}
      />
    </svg>
  )
}

export default SvgIconeContratos