const { getMunicipioById } = require('@resources/api/municipio')

const getMunicipio = (idEstado, idMunicipio, list) => {
  return async dispatch => {
    var municipio

    if (list[idEstado] !== undefined) {
      municipio = list[idEstado].find(el => {
        return el.idMunicipio === idMunicipio
      })
    }

    if (municipio === undefined) {
      await getMunicipioById(idEstado).then(res => {
        municipio = res.data.find(el => {
          return el.idMunicipio === idMunicipio
        })
        list[idEstado] = res.data
        dispatch({
          type: 'ADD_MUNICIPIOS',
          list: list,
        })
      })
    }
    return municipio
  }
}

const getMunicipios = (idEstado, list) => {
  return async dispatch => {
    if (list[idEstado] !== undefined) {
      return list[idEstado]
    }
    await getMunicipioById(idEstado).then(res => {
      list[idEstado] = res.data
      dispatch({
        type: 'ADD_MUNICIPIOS',
        list: list,
      })
    })

    return list[idEstado]
  }
}

export { getMunicipio, getMunicipios }
