import axios from 'axios';

const BASEAPI = process.env.REACT_APP_URL_API;
const ENDPOINT = '/proposta';

export async function findAllPropostas() {
  return axios.get(`${BASEAPI + ENDPOINT}/`);
}

export async function findPropostaById(id) {
  return axios.get(`${BASEAPI + ENDPOINT}/${id}`);
}

export async function saveProposta(values) {
  return axios.post(`${BASEAPI + ENDPOINT}/`, values);
}

export async function updateProposta(values) {
  return axios.put(`${BASEAPI + ENDPOINT}/`, values);
}

export async function deletaProposta(id) {
  return axios.delete(`${BASEAPI + ENDPOINT}/${id}`);
}

export async function aprovarProposta(id) {
  return axios.put(`${BASEAPI + ENDPOINT}/aprovar/${id}`);
}
