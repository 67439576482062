import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getUserLang } from '@utils/localeUtils';
import { withStyles } from '@material-ui/core/styles';
import CardFilters from '@components/CardFilters/CardFilters';
import FilterUtil from '../filterUtil';
import SwalMessage from '@utils/functions/SwalMessage/SwalMessage';
import { getMetasAcompanhamento } from '@resources/api/meta';
import { getUsuarioLogado } from '@root/actions/usuarioActions';
import { getGerente } from '@root/actions/gerenteActions';
import { getVendedor } from '@root/actions/vendedorActions';
import { DataTable } from '@kepha/sumora-react-components';
import '../../App.css';
import * as moment from 'moment';
import { formatCurrency } from '@utils/functions/FormatCurrency/formatCurrency';

const style = () => ({
  fab: {
    position: 'fixed',
    right: '15px',
    bottom: '40px',
    zIndex: '2000'
  },
  containerTable: {
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'column'
  },
  card: {
    height: 70,
    width: '100%',
    marginTop: '30px'
  }
});

class Meta extends Component {
  constructor(props) {
    super(props);

    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.tratarSituacao = this.tratarSituacao.bind(this);
    this.getUsuario = this.getUsuario.bind(this);
    this.tratarGerente = this.tratarGerente.bind(this);
    this.tratarVendedor = this.tratarVendedor.bind(this);

    this.state = {
      infoExtra: [],
      validarSenha: false,
      selected: undefined, // controla a linha selecionada
      dataAppSync: [], // dados que vem da api
      dataInTable: [],
      usuario: {},
      tpUsuario: undefined,
      operationValues: [
        {
          label: 'Gerente',
          field: 'nmGerente',
          type: 'string'
        },
        {
          label: 'Consultor',
          field: 'nmVendedor',
          type: 'string'
        },
        {
          label: 'Data de início',
          field: 'dhInicio',
          type: 'date'
        },
        {
          label: 'Data de término',
          field: 'dhTermino',
          type: 'date'
        },
        {
          label: 'Situação',
          field: 'stMeta',
          type: 'enum',
          options: [
            {
              label: 'Ativo',
              field: 'Ativo',
              value: '1'
            },
            {
              label: 'Inativo',
              field: 'Inativo',
              value: '0'
            }
          ]
        }
      ]
    };
    const title = 'meta.title';
    if (this.props.itensState.name !== title) {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: 'ACOMPANHAMENTO DE METAS',
        name: title
      });
    }
  }
  async componentDidMount() {
    await this.getUsuario();
    this.setState({
      tpUsuario: this.props.usuarioLogado.tpUsuario
    });
    this.props.dispatch({
      type: 'CLEAN_CHIPS'
    });
    this._isMounted = true;
    this.onFilter();
  }

  // controla a linha adicionada e muda os estados dos botões de editar e excluir
  onSelect(selected) {
    if (this._isMounted === true) {
      this.setState({
        selected: selected
      });
    }
  }
  tratarSituacao(situacao) {
    switch (parseInt(situacao)) {
      case 0:
        return 'Inativo';
      case 1:
        return 'Ativo';
      default:
        break;
    }
  }

  // ao ser adicionado novo é realizado um filtro usando os dados
  onFilter(dataChips) {
    dataChips = dataChips || [];
    if (this.state.dataAppSync.length > 0) {
      return;
    } else {
      getMetasAcompanhamento()
        .then(async res => {
          var data = await res.data.map(async item => {
            return {
              ...item,
              nrContratosRealizados: item.nrContratosRealizados,
              nrPropostasRealizadas: item.nrPropostasRealizadas,
              nrVisitasRealizadas: item.nrVisitasRealizadas,
              idMeta: item.idMeta,
              idVendedor: item.idVendedor,
              idGerente: item.idGerente,
              nrVisita: item.nrVisita,
              nrProposta: item.nrProposta,
              nrContrato: item.nrContrato,
              dhInicio: item.dhInicio,
              dhTermino: item.dhTermino,
              stMeta: item.stMeta,
              nmSituacao: this.tratarSituacao(item.stMeta),
              nmVendedor: await this.tratarVendedor(item.idVendedor),
              nmGerente: await this.tratarGerente(item.idGerente)
            };
          });
          const metas = await Promise.all(data);
          this.setState({
            dataInTable: FilterUtil.applyFilters(metas, dataChips),
            dataAppSync: metas
          });
        })
        .catch(err => {
          SwalMessage({
            title: 'Falha',
            text: 'Falha ao carregar página',
            err,
            callback: () => {
              this._isMounted && this.setState({ dataAppSync: [] });
            }
          });
        });
    }
  }
  onFilterChanged(dataChips) {
    this.setState({
      dataInTable: FilterUtil.applyFilters(this.state.dataAppSync, dataChips)
    });
  }

  // set que o componente está desmontado e não aceita mais setState
  componentWillUnmount() {
    this._isMounted = false;
  }

  async tratarVendedor(id) {
    return await this.props.dispatch(getVendedor(this.props.vendedorList, id)).then(vendedor => {
      return vendedor;
    });
  }

  async tratarGerente(id) {
    return await this.props.dispatch(getGerente(this.props.gerenteList, id)).then(gerente => {
      return gerente;
    });
  }

  async getUsuario() {
    await this.props.dispatch(getUsuarioLogado(this.props.usuarioLogado)).then(usuario => {
      this.setState({
        usuario: usuario
      });
    });
  }

  render() {
    let head = [
      {
        field: 'nmGerente',
        headerName: 'Gerente',
        col: 6,
        suppressSizeToFit: true,
        pinned: 'left'
      },
      {
        field: 'nmVendedor',
        headerName: 'Consultor',
        col: 6,
        suppressSizeToFit: true,
        pinned: 'left'
      },
      {
        valueGetter: args => moment(args.node.data.dhInicio.split('T')[0]).format('DD/MM/YY'),
        headerName: 'Início',
        suppressSizeToFit: true,
        col: 1
      },
      {
        valueGetter: args => moment(args.node.data.dhTermino.split('T')[0]).format('DD/MM/YY'),
        headerName: 'Término',
        suppressSizeToFit: true,
        col: 1
      },
      {
        valueGetter: args => args.node.data.nrVisitasRealizadas + ' / ' + args.node.data.nrVisita,
        headerName: 'Visitas',
        suppressSizeToFit: true,
        col: 1
      },
      {
        valueGetter: args => args.node.data.nrPropostasRealizadas + ' / ' + args.node.data.nrProposta,
        headerName: 'Propostas',
        suppressSizeToFit: true,
        col: 1
      },
      {
        valueGetter: args => args.node.data.nrContratosRealizados + ' / ' + args.node.data.nrContrato,
        headerName: 'Contratos',
        suppressSizeToFit: true,
        col: 1
      },
      {
        valueGetter: args =>
          formatCurrency(parseFloat(args.node.data.vlContratosRealizados)).trim() +
          ' / ' +
          formatCurrency(parseFloat(args.node.data.vlProposta)).trim(),
        headerName: 'Vl. Proposta',
        col: 3
      },
      {
        valueGetter: args =>
          formatCurrency(parseFloat(args.node.data.vlPropostasRealizadas)).trim() +
          ' / ' +
          formatCurrency(parseFloat(args.node.data.vlContrato)).trim(),
        headerName: 'Vl. Contrato',
        col: 3
      },
      {
        field: 'nmSituacao',
        headerName: 'Situação',
        col: 1,
        suppressSizeToFit: true
      }
    ];
    const pagination = {
      length: this.state.dataInTable.length,
      page: 0
    };

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          marginTop: 10
        }}
      >
        <div
          ref={divElement => {
            this.divElement = divElement;
          }}
        >
          <CardFilters
            filtros={this.state.operationValues}
            onFilter={this.onFilter}
            onFilterChanged={this.onFilterChanged}
            usePaddings={false}
            viewKey='metas'
          ></CardFilters>
        </div>

        <div
          style={{
            marginTop: 20,
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <DataTable
            marginTop
            data={this.state.dataInTable}
            columns={head}
            userlang={getUserLang()}
            selectChange={this.onSelect}
            showPagination={true}
            pagination={pagination}
            handleChangePage={() => {}}
            handleChangeRowsPerPage={() => {}}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  usuarioLogado: state.usuarioReducer,
  gerenteList: state.gerenteReducer,
  vendedorList: state.vendedorReducer,
  itensState: state.toolbar
});

let enhaced = withStyles(style)(Meta);
export default connect(mapStateToProps)(withRouter(enhaced));
