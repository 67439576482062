import { getAll as getAllClientes } from '@resources/api/cliente'

const getClientes = list => {
  return async dispatch => {
    await getAllClientes().then(res => {
      list = res.data
      dispatch({
        type: 'ADD_CLIENTES',
        list: list,
      })
    })

    return list
  }
}

export { getClientes }
