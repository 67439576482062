const style = () => ({
  fab: {
    position: 'fixed',
    right: '15px',
    bottom: '40px',
    zIndex: '2000'
  },
  cardTitle: {
    padding: 20,
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: 0
  },
  containerTable: {
    display: 'flex',
    height: '400px',
    width: '100%',
    flexDirection: 'column'
  },
  cardContrato: {
    height: 205,
    width: '100%',
    paddingBottom: '12px',
    paddingTop: '8px'
  },
  cardCliente: {
    height: 205,
    width: '100%',
    marginTop: '40px',
    paddingBottom: '12px',
    paddingTop: '8px'
  },
  mainDiv: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  form: {
    width: '100%',
    marginTop: 18
  },
  divTitulo: {
    height: 12,
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    borderBottom: '1px solid #95989A'
  },
  titulo: {
    fontWeight: 500,
    fontSize: 15,
    color: 'rgb(0,0,0,1)',
    paddingLeft: '16px'
  },
  divCliente: {
    paddingLeft: '30px'
  },
  cardContent: {
    display: 'flex',
    paddingLeft: 4,
    marginTop: 5
  },
  inputPadding: {
    paddingLeft: 24
  },
  nrContrato: {
    color: '#EA6909',
    fontWeight: 'bold',
    fontSize: 20,
    marginRight: 30
  },
  nrValor: {
    color: '#8F7D73',
    fontWeight: 'bold',
    fontSize: 20
  },
  nmLoja: {
    color: 'rgba(95,76,62,0.54)',
    fontSize: 16
  },
  nmVendedor: {
    color: 'rgba(234,105,9,0.54)',
    fontSize: 16,
    marginLeft: 3
  }
});

export default style;
