import moment from 'moment';
import { formatCurrency } from '@utils/functions/FormatCurrency/formatCurrency';

const head = [
  {
    field: 'nmAmbiente',
    headerName: 'Ambiente',
    col: 3
  },
  {
    field: 'nmAcabamento',
    headerName: 'Acabamento',
    col: 3
  },
  {
    valueGetter: args =>
      formatCurrency(parseFloat(args.node.data.vlItem))
        .replace('R$', '')
        .trim(),
    headerName: 'Valor',
    col: 1
  },
  {
    valueGetter: args => moment(args.node.data.dtPrevisaoEntrega).format('DD/MM/YYYY'),
    headerName: 'Prev. entrega',
    col: 2
  },
  {
    valueGetter: args => {
      if (!args.node.data.projetoExecutivo) return ' ';
      return +args.node.data.projetoExecutivo.stProjetoExecutivo === 1 ? 'Aprovado' : 'Não aprovado';
    },
    headerName: 'Projeto',
    col: 2
  }
];

export default head;
