import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { DataTable, GroupButtonGrid } from '@kepha/sumora-react-components';
import CardFilters from '@components/CardFilters/CardFilters';
import FilterUtil from '../filterUtil';
import style from './style/style';
import { getUserLang } from '@utils/localeUtils';
import '@root/App.css';
import { head, headGerente, headAdmin } from './tableHead';
import SwalMessage from '@utils/functions/SwalMessage/SwalMessage';
import { imprimirContrato, exportContratoPdf } from '@resources/api/contrato';
import { SvgIconeContratos } from '@components/svg-components/index';
import swal from '@sweetalert/with-react';
import { ButtonGrid } from '@kepha/sumora-react-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import { findAllContratos } from '@resources/api/contrato';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';

class Contrato extends React.Component {
  constructor(props) {
    super(props);

    this.operationNumero = {
      label: 'Número',
      field: 'nrContrato',
      type: 'string'
    };

    const consultor = {
      label: 'Consultor',
      field: 'nmUsuario',
      type: 'string'
    };

    this.operationAdmin = [
      {
        label: 'Loja',
        field: 'nmLoja',
        type: 'string'
      },
      consultor
    ];

    this.operationGerente = [consultor];

    this.operationValues = [
      {
        label: 'Entrega',
        field: 'dhEntregue',
        type: 'date'
      },
      {
        label: 'Cliente',
        field: 'nmCliente',
        type: 'string'
      },
      {
        label: 'Valor',
        field: 'vlTotal',
        type: 'number'
      },
      {
        label: 'Situação',
        field: 'stContrato',
        type: 'enum',
        options: [
          {
            label: 'Pendente',
            field: 'Pendente',
            value: '0'
          },
          {
            label: 'Em análise',
            field: 'Em análise',
            value: '1'
          },
          {
            label: 'Aprovado e não assinado',
            field: 'Aprovado e não assinado',
            value: '2'
          },
          {
            label: 'Aprovado e assinado',
            field: 'Aprovado e assinado',
            value: '3'
          },
          {
            label: 'Entregue',
            field: 'Entregue',
            value: '4'
          }
        ]
      }
    ];

    this.state = {
      operationValues: [this.operationNumero, ...this.operationValues],
      selected: undefined, // controla a linha selecionada
      dataAppSync: [], // dados que vem da api
      dataInTable: []
    };

    this.props.dispatch({
      type: 'UPDATE_TOOLBAR',
      toolbar: 'CONTRATOS'
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleEdit = () => {
    this.props.history.push({
      pathname: '/app/contratos/editar/' + this.state.selected.idContrato,
      state: { contrato: this.state.selected }
    });
  };

  onFilter = async dataChips => {
    dataChips = dataChips || [];

    findAllContratos()
      .then(async res => {
        var data = res.data.map(async item => {
          item.nmSituacao = [
            'Pendente',
            'Em análise',
            'Apr. e não assinado',
            'Apr. e assinado',
            'Entregue'
          ][item.stContrato];
          item.nmCliente = item.cliente.nmCliente;
          item.nmLoja = item.loja.nmLoja;
          item.nmUsuario = item.vendedor.nmUsuario;
          return item;
        });

        const contratos = await Promise.all(data);
        this.setState({
          dataInTable: FilterUtil.applyFilters(contratos, dataChips),
          dataAppSync: contratos
        });
      })
      .catch(err => {
        SwalMessage({
          title: 'Falha',
          text: 'Falha ao carregar página',
          err,
          callback: () => {
            this._isMounted && this.setState({ dataAppSync: [] });
          }
        });
      });
  };

  onSelect = selected => {
    if (this._isMounted === true) {
      this.setState({
        selected: selected
      });
    }
  };
  onFilterChanged = dataChips => {
    this.setState({
      dataInTable: FilterUtil.applyFilters(this.state.dataAppSync, dataChips)
    });
  };
  componentDidMount() {
    this.props.dispatch({
      type: 'CLEAN_CHIPS'
    });
    this._isMounted = true;
    this.onFilter();
  }

  render() {
    const { classes } = this.props;
    const pagination = {
      length: this.state.dataInTable.length,
      page: 0
    };

    let operationValues = this.state.operationValues;
    let headData = head;
    let keyData = 0;
    if (this.props.usuarioLogado && this.props.usuarioLogado.tpUsuario === 0) {
      headData = headAdmin;
      operationValues = [this.operationNumero, ...this.operationAdmin, ...this.operationValues];
      keyData = 1;
    } else if (this.props.usuarioLogado && this.props.usuarioLogado.tpUsuario === 1) {
      headData = headGerente;
      operationValues = [this.operationNumero, ...this.operationGerente, ...this.operationValues];
      keyData = 2;
    }

    return (
      <div className={classes.mainDiv}>
        <div>
          <CardFilters
            filtros={operationValues}
            onFilter={this.onFilter}
            onFilterChanged={this.onFilterChanged}
            usePaddings={false}
            viewKey='lojas'
          />
        </div>

        <div className={classes.buttonGrid}>
          <GroupButtonGrid
            customButtons={
              <div style={{ display: 'flex' }}>
                <ButtonGrid
                  show={true}
                  icon={<PictureAsPdf style={{ color: '#FFFFFF', height: 21, width: 21 }} />}
                  color={'#5F4C3E'}
                  disabled={this.state.selected === undefined || this.state.selected.stProposta === 2}
                  onClick={() => {
                    if (!this.state.selected) return;

                    swal(
                      <div>
                        <CircularProgress style={{ color: '#EA6909' }} />
                      </div>,
                      {
                        buttons: false,
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                        title: 'Gerando arquivo do contrato'
                      }
                    );

                    exportContratoPdf(this.state.selected.idContrato)
                      .then(async resContrato => {
                        var res = await fetch(resContrato.data);
                        var data = await res.blob();

                        const anchor = document.createElement('a');
                        anchor.href = window.URL.createObjectURL(data);
                        anchor.download = resContrato.data
                          .split(process.env.REACT_APP_URL_IMAGES)
                          .reverse()[0];
                        anchor.click();

                        swal.close();
                      })
                      .catch(err => {
                        swal('Erro ao imprimir contrato', {
                          icon: 'error',
                          confirmButtonColor: '#8CD4F5',
                          buttons: {
                            confirm: {
                              confirmButtonColor: '#8CD4F5',
                              text: 'Ok',
                              value: true,
                              visible: true,
                              closeModal: true,
                              className: 'swal2-Ok'
                            }
                          }
                        });
                      });
                  }}
                />
                <ButtonGrid
                  show={true}
                  icon={<SvgIconeContratos color='#fff' />}
                  color={'#5F4C3E'}
                  disabled={this.state.selected === undefined}
                  onClick={() => {
                    if (!this.state.selected) return;

                    swal(
                      <div>
                        <CircularProgress style={{ color: '#EA6909' }} />
                      </div>,
                      {
                        buttons: false,
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                        title: 'Gerando arquivo do contrato'
                      }
                    );

                    imprimirContrato(this.state.selected.idContrato)
                      .then(async resContrato => {
                        var res = await fetch(resContrato.data);
                        var data = await res.blob();

                        const anchor = document.createElement('a');
                        anchor.href = window.URL.createObjectURL(data);
                        anchor.download = resContrato.data
                          .split(process.env.REACT_APP_URL_IMAGES)
                          .reverse()[0];
                        anchor.click();

                        swal.close();
                      })
                      .catch(err => {
                        swal('Erro ao imprimir contrato', {
                          icon: 'error',
                          confirmButtonColor: '#8CD4F5',
                          buttons: {
                            confirm: {
                              confirmButtonColor: '#8CD4F5',
                              text: 'Ok',
                              value: true,
                              visible: true,
                              closeModal: true,
                              className: 'swal2-Ok'
                            }
                          }
                        });
                      });
                  }}
                />
              </div>
            }
            showAdd={false}
            showEdit={true}
            showDelete={false}
            disableDelete={this.state.selected === undefined}
            disableEdit={this.state.selected === undefined}
            onClickDelete={this.handleDelete}
            onClickEdit={this.handleEdit}
            deleteColor='#5F4C3E'
            editColor='#5F4C3E'
          />
          <DataTable
            key={keyData}
            marginTop
            data={this.state.dataInTable}
            columns={headData}
            userlang={getUserLang()}
            selectChange={this.onSelect}
            showPagination={true}
            pagination={pagination}
            handleChangePage={() => {}}
            handleChangeRowsPerPage={() => {}}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  usuarioLogado: state.usuarioReducer,
  itensState: state.toolbar,
  estadoList: state.estadoReducer,
  municipioList: state.municipioReducer,
  getMunicipio: state.getMunicipio,
  lojaList: state.lojaReducer
});
let enhanced = withStyles(style)(Contrato);
export default connect(mapStateToProps)(withRouter(enhanced));
