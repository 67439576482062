import axios from 'axios';

const BASEAPI = process.env.REACT_APP_URL_API;
const ENDPOINT = '/agenda/comercial';

export async function updateItemContrato(valuesItemContrato) {
  return axios.put(`${BASEAPI + ENDPOINT}/`, valuesItemContrato);
}

export async function findItensPendentes() {
  return axios.get(`${BASEAPI + ENDPOINT}/pendente`);
}

export async function findItensProducao(dtInicio, dtFim) {
  return axios.get(`${BASEAPI + ENDPOINT}/producao?dtInicio=${dtInicio}&dtFim=${dtFim}`);
}
