export default {
  editarAmbiente: {
    title: 'Editar Ambiente',
    codigo: 'Código *',
    nome: 'Nome *',
    descricao: 'Descrição *',
    campoObrigatorio: 'Campo Obrigatório',
    identificacao: 'Identificação',
  },
}
