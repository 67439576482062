var lojaList = []

const adicionaListaDeLojas = (state = lojaList, action) => {
  switch (action.type) {
    case 'ADD_LOJAS':
      lojaList = action.list
      return lojaList
    default:
      return state
  }
}

export default adicionaListaDeLojas
