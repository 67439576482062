import * as React from 'react'

function SvgIconParceiros(props) {
  return (
    <svg width="1.30em" height="1.30em" viewBox="0 0 24 24" {...props}>
      <g data-name="Group 3428">
        <path data-name="Rectangle 514" fill="none" d="M0 0h24v24H0z" />
      </g>
      <g data-name="Group 3434">
        <g data-name="Group 3433">
          <g data-name="Group 3429">
            <path
              data-name="Path 2225"
              d="M16.67 13.13A4.651 4.651 0 0119 17v3h4v-3c0-2.18-3.57-3.47-6.33-3.87z"
              fill={props.color}
              fillRule="evenodd"
            />
          </g>
          <g data-name="Group 3430">
            <circle
              data-name="Ellipse 58"
              cx={4}
              cy={4}
              r={4}
              transform="translate(5 4)"
              fill={props.color}
            />
          </g>
          <g data-name="Group 3431">
            <path
              data-name="Path 2226"
              d="M15 12a4 4 0 000-8 4.178 4.178 0 00-1.33.24 5.98 5.98 0 010 7.52A4.178 4.178 0 0015 12z"
              fill={props.color}
              fillRule="evenodd"
            />
          </g>
          <g data-name="Group 3432">
            <path
              data-name="Path 2227"
              d="M9 13c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z"
              fill={props.color}
              fillRule="evenodd"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgIconParceiros