import ReactDOM from 'react-dom'
import thunkMiddleware from 'redux-thunk'
import './index.css'
import registerServiceWorker from './registerServiceWorker'
import createRoutes from './views/routes'
import React from 'react'
import { Provider } from 'react-redux'
import Store from './states/store'
import { createStore, applyMiddleware } from 'redux'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core'

const routes = createRoutes()
const store = createStore(Store, applyMiddleware(thunkMiddleware))
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#EA6909',
      light: '#EA6909',
      contrastText: '#ffffff',
    },
    card: '#E8DAD1',
    modal: {
      footer: 'rgb(139, 200, 165, 0.1)',
    },
    tabs: {
      selected: '#EA6909',
      contrastText: '#ffffff',
    },
    menu: {
      background: 'linear-gradient(#5F4C3E, #5F4C3E)',
      itemBackground: '#00622B',
      subitemBackground: '#004820',
    },
  },
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: 'none',
      },
    },
    MuiInput: {
      underline: {
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottomColor: ['#EA6909', '!important'],
          borderBottomWidth: ['1px', '!important'],
        },
        '&:after': {
          borderBottomColor: ['#EA6909', '!important'],
          borderBottomWidth: ['1px', '!important'],
        },
        '&:hover:before': {
          borderBottomColor: ['#EA6909', '!important'],
          borderBottomWidth: ['1px', '!important'],
        },
      },
    },
    MuiGrid: {
      root: {
        height: 70,
      },
    },
  },
})

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>{routes}</Provider>
  </MuiThemeProvider>,
  document.getElementById('root')
)

registerServiceWorker()
