export const styles = theme => ({
  root: {
    outline: '0 !important',
    '&:focus': {
      outline: '0 !important',
    },
  },
  cropContainer: {
    outline: 0,
    position: 'relative',
    width: '100%',
    height: 500,
    background: '#333',
    [theme.breakpoints.up('sm')]: {
      height: 500,
    },
  },
  cropButton: {
    marginLeft: 78,
  },
  controls: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  sliderContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '15px',
  },
  sliderLabel: {
    marginRight: '15px',
    [theme.breakpoints.down('xs')]: {
      minWidth: 65,
    },

    zoom: {
      width: 180,
    },
    rotation: {
      width: 200,
    },
  },
  slider: {
    width: '228px',
  },
})
