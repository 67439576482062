export default {
  form: {
    title: 'Editar Ambiente',
    codigo: 'Código *',
    nome: 'Nome *',
    nrDias: 'Dias *',
    descricao: 'Descrição *',
    campoObrigatorio: 'Campo Obrigatório',
    identificacao: 'Identificação'
  }
};
