export default {
    filtrosDeListagem : {
        filtros: 'Filters',
        tipoDeFiltro: 'Filter type', 
        tipoDeComparacao: 'Comparison type',
        pesquisa: 'Search',
        filtrosAdicionados: 'Filters added',
        filtrar: 'Filter',
        igual:'Equal',
        maiorOuIgual: 'Greater or equal',
        maior: 'More than',
        menorOuIgual:'Less or equal',
        menor:'Less than',
        diferente: 'Not equal',
        contendo: 'Contains',
        campoObrigatorio: 'Required field',
        filtroDuplicado: 'Duplicate filter',
    }
}