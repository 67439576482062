import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import flattenMessages from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import ptBR from './i18n/ptBR';
import CardFilters from '@components/CardFilters/CardFilters';
import FilterUtil from '../filterUtil';
import SwalMessage from '@utils/functions/SwalMessage/SwalMessage';
import { getAll } from '@resources/api/visita';
import { DataTable } from '@kepha/sumora-react-components';
import { formatDate, formatDateToHhMm } from '@utils/dateUtils';
import { getUsuarioLogado } from '@root/actions/usuarioActions';
import { getLojas } from '@root/actions/lojaActions';
import * as moment from 'moment';

import '@root/App.css';

let messages = { 'pt-BR': ptBR };

const style = () => ({
  fab: {
    position: 'fixed',
    right: '15px',
    bottom: '40px',
    zIndex: '2000'
  },
  containerTable: {
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'column'
  },
  card: {
    height: 70,
    width: '100%',
    marginTop: '30px'
  }
});

class Visita extends Component {
  constructor(props) {
    super(props);

    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.tratarSituacao = this.tratarSituacao.bind(this);

    this.state = {
      infoExtra: [],
      validarSenha: false,
      selected: undefined, // controla a linha selecionada
      dataAppSync: [], // dados que vem da api
      dataInTable: [],
      usuario: null,
      operationValues: [
        {
          label: 'Data',
          field: 'dhVisita',
          type: 'date'
        },
        {
          label: 'Nome da Loja',
          field: 'nmLoja',
          type: 'string'
        },
        {
          label: 'Consultor',
          field: 'nmVendedor',
          type: 'string'
        },
        {
          label: 'Cliente/Parceiro',
          field: 'nmContratante',
          type: 'string'
        },
        {
          label: 'Situação',
          field: 'dsSituacao',
          type: 'enum',
          options: [
            {
              label: 'Concluída',
              field: 'Concluída',
              value: 'Concluída'
            },
            {
              label: 'Próxima',
              field: 'Próxima',
              value: 'Próxima'
            }
          ]
        }
      ]
    };
    const title = 'visita.title';
    if (this.props.itensState.name !== title) {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: (
          <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
            <FormattedMessage id={title} />
          </IntlProvider>
        ),
        name: title
      });
    }
  }
  async componentDidMount() {
    await this.getUsuario();
    this.props.dispatch({
      type: 'CLEAN_CHIPS'
    });
    this._isMounted = true;
    this.onFilter();
  }

  // controla a linha adicionada e muda os estados dos botões de editar e excluir
  onSelect(selected) {
    if (this._isMounted === true) {
      this.setState({
        selected: selected
      });
    }
  }

  //Adiciona o usuario logado no state
  async getUsuario() {
    await this.props.dispatch(getUsuarioLogado(this.props.usuarioLogado)).then(usuario => {
      this.setState({
        usuario: usuario
      });
    });
  }

  //Trata situação da visita
  tratarSituacao(date, time) {
    var now = moment();
    var hhMm = formatDateToHhMm(time);
    var input = moment(`${date}T${hhMm}`);

    const validation = input.isAfter(now);

    if (validation) {
      return 'Próxima';
    }
    return 'Concluída';
  }

  //Retorna a loja de um id
  async tratarLoja(id) {
    var loja;
    await this.props.dispatch(getLojas(this.props.lojaList)).then(lojaList => {
      loja = lojaList.find(el => {
        return el.idLoja === id;
      });
    });

    return loja !== undefined ? loja.nmLoja : '';
  }

  // ao ser adicionado novo é realizado um filtro usando os dados
  async onFilter(dataChips) {
    dataChips = dataChips || [];

    if (this.state.dataAppSync.length > 0) {
      return;
    } else {
      this._isMounted &&
        getAll()
          .then(async res => {
            var data = res.data.map(async item => {
              return {
                idParceiro: item.idParceiro,
                idCliente: item.idCliente,
                dhVisita: item.dtVisita,
                dtVisita: formatDate(item.dtVisita),
                dhInicio: formatDateToHhMm(item.dhInicio),
                nmParceiro: item.nmParceiro,
                nmCliente: item.nmCliente,
                nmContratante: item.nmCliente || item.nmParceiro,
                dsSituacao: this.tratarSituacao(item.dtVisita, item.dhTermino),
                nmLoja: await this.tratarLoja(item.usuario.idLoja),
                nmVendedor: item.usuario.nmUsuario
              };
            });
            const visitas = await Promise.all(data);
            this.setState({
              dataInTable: FilterUtil.applyFilters(visitas, dataChips),
              dataAppSync: visitas
            });
          })
          .catch(err => {
            SwalMessage({
              title: 'Falha',
              text: 'Falha ao carregar página',
              err,
              callback: () => {
                this._isMounted && this.setState({ dataAppSync: [] });
              }
            });
          });
    }
  }
  onFilterChanged(dataChips) {
    this.setState({
      dataInTable: FilterUtil.applyFilters(this.state.dataAppSync, dataChips)
    });
  }

  // set que o componente está desmontado e não aceita mais setState
  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    let head = [
      {
        field: 'dtVisita',
        headerName: 'Data',
        col: 1
      },
      {
        field: 'dhInicio',
        headerName: 'Hora',
        col: 1
      },
      {
        field: 'nmLoja',
        headerName: 'Loja',
        col: 3
      },
      {
        field: 'nmVendedor',
        headerName: 'Consultor',
        col: 3
      },
      {
        field: 'nmContratante',
        headerName: 'Cliente/Parceiro',
        col: 3
      },
      {
        field: 'dsSituacao',
        headerName: 'Situação',
        col: 1
      }
    ];
    const pagination = {
      length: this.state.dataInTable.length,
      page: 0
    };

    return (
      <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            marginTop: 10
          }}
        >
          <div
            ref={divElement => {
              this.divElement = divElement;
            }}
          >
            <CardFilters
              filtros={this.state.operationValues}
              onFilter={this.onFilter}
              onFilterChanged={this.onFilterChanged}
              usePaddings={false}
              viewKey='visitas'
            ></CardFilters>
          </div>

          <div
            style={{
              marginTop: 10,
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <dif
              style={{
                marginTop: 35
              }}
            ></dif>
            <DataTable
              marginTop
              data={this.state.dataInTable}
              columns={head}
              userlang={getUserLang()}
              selectChange={this.onSelect}
              showPagination={true}
              pagination={pagination}
              handleChangePage={() => {}}
              handleChangeRowsPerPage={() => {}}
              defaultColDef={{
                name: 'Static Styles',
                field: 'dsSituacao',
                cellStyle: params => {
                  if (params.value === 'Concluída') {
                    return { color: '#2DCB88' };
                  }
                  if (params.value === 'Próxima') {
                    return { color: '#FAD500' };
                  }
                }
              }}
            />
          </div>
        </div>
      </IntlProvider>
    );
  }
}
const mapStateToProps = state => ({
  usuarioLogado: state.adicionaUsuarioLogado,
  itensState: state.toolbar,
  estadoList: state.estadoReducer,
  municipioList: state.municipioReducer,
  getMunicipio: state.getMunicipio,
  lojaList: state.lojaReducer
});

let enhanced = withStyles(style)(Visita);
export default connect(mapStateToProps)(withRouter(enhanced));
