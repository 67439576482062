import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import TelaRecuperarSenha from '@components/TelaRecuperarSenha/TelaRecuperarSenha'
import CabecalhoLogin from '@components/CabecalhoLogin/CabecalhoLogin'

const imgFundo = require('@images/fundo.png')

const styles = {
  image: {
    width: '1024',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    backgroundImage: `url(${imgFundo})`,
    backgroundRepeat: 'no-repeat',
    flexDirection: 'row',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  cabecalho: {
    paddingBottom: 480,
    position: 'absolute',
  },
  divContent: {
    marginTop: 70,
  },
}

class RecuperarSenha extends Component {
  render() {
    const { classes } = this.props

    return (
      <div className={classes.image}>
        <div className={classes.cabecalho}>
          <CabecalhoLogin />
        </div>
        <div className={classes.divContent}>
          <TelaRecuperarSenha />
        </div>
      </div>
    )
  }
}

RecuperarSenha.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(RecuperarSenha)
