import axios from 'axios';

const BASEAPI = process.env.REACT_APP_URL_API;
const ENDPOINT = '/contrato';

export async function imprimirContrato(idProposta) {
  return axios.post(`${BASEAPI + ENDPOINT}/imprimir/${idProposta}`);
}

export async function exportContratoPdf(idProposta) {
  return axios.post(`${BASEAPI + ENDPOINT}/imprimir/pdf/${idProposta}`);
}

export async function updateContrato(values) {
  return axios.put(`${BASEAPI + ENDPOINT}/`, values);
}

export async function findContratoById(id) {
  return axios.get(`${BASEAPI + ENDPOINT}/${id}`);
}

export async function findAllContratos() {
  return axios.get(`${BASEAPI + ENDPOINT}/`);
}
