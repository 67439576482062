import React from 'react';
import { FormattedMessage } from 'react-intl';
import { combineReducers } from 'redux';
import itensMenu from '../components/MenuLateral/itensMenu';
import vendedorReducer from '../reducers/vendedorReducer';
import gerenteReducer from '../reducers/gerenteReducer';
import lojaReducer from '../reducers/lojaReducer';
import estadoReducer from '../reducers/estadoReducer';
import municipioReducer from '../reducers/municipioReducer';
import usuarioReducer from '../reducers/usuarioReducer';
import clienteReducer from '../reducers/clienteReducer';

const estadoInicial = {
  chipData: [
    { id: 'ambientes', content: [] },
    { id: 'acabamentos', content: [] },
    { id: 'lojas', content: [] },
    { id: 'clientes', content: [] },
    { id: 'metas', content: [] },
    { id: 'visitas', content: [] }
  ]
};
const histPaginas = {
  paginaAnterior: 'paginaAnterior'
};
let menuInicial = {
  open: false
};
const itemMenuSelecionado = {
  item: 'selecionado'
};

const menuOpen = (state = menuInicial, action) => {
  switch (action.type) {
    case 'OPEN_MENU':
      return { open: true };
    case 'CLOSE_MENU':
      resetarMenu(itensMenu);
      return { open: false };
    default:
      return state;
  }
};

const resetarMenu = itens => {
  for (var i = 0; i < itens.length; i++) {
    if (itens[i].selecionado) {
      itens[i].selecionado = false;
      if (itens[i].children !== undefined) {
        resetarMenu(itens[i].children);
      }
    }
  }
};

const itemSelecionado = (state = itemMenuSelecionado, action) => {
  switch (action.type) {
    case 'SELECIONAR_ITEM':
      return { item: action.itemSelecionado };
    default:
      return state;
  }
};
const previousPage = (state = histPaginas, action) => {
  switch (action.type) {
    case 'UPDATE_PREVIOUS_PAGE':
      return { paginaAnterior: action.idClean };
    default:
      return state;
  }
};

var toolbarTitle = {
  label: <FormattedMessage id={'toolbar.toolbar'} />,
  name: 'toolbar'
};
const toolbar = (state = toolbarTitle, action) => {
  switch (action.type) {
    case 'UPDATE_TOOLBAR':
      return { label: action.toolbar, name: action.name };
    default:
      return state;
  }
};

const chips = (state = estadoInicial, action) => {
  switch (action.type) {
    case 'ADD_CHIPS':
      const updatedItems = state.chipData.map(item => {
        if (item.id === action.id) {
          return { ...item, content: [...item.content, action.newChip] };
        }
        return item;
      });
      return {
        ...state,
        chipData: updatedItems
      };
    case 'DELETE_CHIP':
      const deleteItems = state.chipData.map(item => {
        if (item.id === action.idOrigin) {
          return {
            ...item,
            content: item.content.filter((item, index) => action.index !== index)
          };
        }
        return item;
      });
      return {
        ...state,
        chipData: deleteItems
      };
    case 'CLEAN_CHIPS':
      const cleanItems = state.chipData.map(item => {
        if (item.idClean === action.idOrigin) {
          return { ...item, content: [] };
        }
        return item;
      });
      return {
        ...state,
        chipData: cleanItems
      };

    default:
      return state;
  }
};

export default combineReducers({
  itemSelecionado,
  menuOpen,
  toolbar,
  chips,
  previousPage,
  usuarioReducer,
  estadoReducer,
  municipioReducer,
  lojaReducer,
  gerenteReducer,
  vendedorReducer,
  clienteReducer
});
