import React, { useState, useEffect } from 'react'
import { Prompt, withRouter } from 'react-router-dom'
import SwalConfirmacao from '@utils/functions/SwalConfirmacao/SwalConfirmacao'

/**
 * Modal de confirmação ao sair de uma tela com dados não salvos
 *
 * Autores: João Victor R. Lourenço, Bruno, Gabriela
 *
 * Parâmetros: values = valores atuais, initialValues = representação dos dados vazios, canLeave = boolean
 * que indica se esta sendo utilizado um history.push() no componente pai
 */
function OnExitConfirmation(props) {
  const [newPath, setNewPath] = useState({ canLeave: false, path: null })

  const isDirty = () => {
    return JSON.stringify(props.initialValues) !== JSON.stringify(props.values)
  }

  window.onbeforeunload = () => {
    if (isDirty()) {
      return true
    }
  }
  useEffect(() => {
    return () => {
      window.onbeforeunload = undefined
    }
  }, [])

  useEffect(() => {
    if (newPath.canLeave && !props.canLeave) {
      props.history.push(newPath.path)
    }
  }, [newPath.canLeave, props.canLeave])

  const onTryExit = location => {
    if (props.canLeave) {
      return
    }
    if (isDirty()) {
      SwalConfirmacao({
        text: 'Existem informações não salvas, deseja continuar?',
        textConfirm: 'Continuar Editando',
        textCancel: 'Sair',
        inverteButtonsColors: true,
        config: { closeOnClickOutside: false },
      }).then(res => {
        if (!res) {
          setNewPath({ canLeave: true, path: location.pathname })
        }
      })
      return false
    }
    setNewPath({ canLeave: true, path: location.pathname })
  }

  return (
    <>
      <Prompt when={!newPath.canLeave} message={onTryExit} />
      {props.children}
    </>
  )
}

export default withRouter(OnExitConfirmation)
