import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import flattenMessages from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import FormHelperText from '@material-ui/core/FormHelperText';
import * as Yup from 'yup';
import { withStyles } from '@material-ui/core/styles';
import ptBR from './i18n/ptBR';
import CardIcon from '@components/CardIcon/CardIcon';
import { TextField } from '@kepha/sumora-react-components';
import swal from '@sweetalert/with-react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import { insert, update } from '@resources/api/ambiente';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SwalConfirmacao from '@utils/functions/SwalConfirmacao/SwalConfirmacao';
import FabDiamondMenu from '@components/FabDiamondMenu/FabDiamondMenu';
import OnExitConfirmation from '@components/OnExitConfirmation/OnExitConfirmation';

let messages = { 'pt-BR': ptBR };
let { provider } = new IntlProvider({});

const style = () => ({
  fab: {
    position: 'fixed',
    right: '15px',
    bottom: '40px',
    zIndex: '2000'
  },
  card: {
    height: 100,
    width: '100%',
    marginTop: '30px'
  },
  underline: {
    '&:after': {
      borderBottomColor: '#EA6909'
    },
    '&:hover:before': {
      borderBottomColor: ['#EA6909', '!important']
    }
  },
  closeButton: {
    position: 'absolute',
    right: 20,
    paddingBottom: '8px',
    height: '40px',
    width: '40px'
  }
});

class AmbienteForm extends Component {
  constructor(props) {
    super(props);

    const messagesLanguage = flattenMessages(messages[getUserLang()]);
    const nome = messagesLanguage['editarAmbiente.nome'];
    const campoObrigatorio = messagesLanguage['editarAmbiente.campoObrigatorio'];
    const identificacao = messagesLanguage['editarAmbiente.identificacao'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          nome,
          campoObrigatorio,
          identificacao
        }
      },
      {}
    );
    provider = intlProvider.getChildContext();

    this.initialValues = {
      nome: '',
      situacao: 1
    };

    this.state = {
      ambiente: {
        ...this.initialValues
      },
      canLeave: false
    };
    let title;
    if (this.props.match.params.id === undefined) {
      title = 'CADASTRAR AMBIENTE';
    } else {
      title = 'EDITAR AMBIENTE';
    }

    if (this.props.itensState.name !== title) {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: title,
        name: title
      });
    }

    this.cancelarEdicao = this.cancelarEdicao.bind(this);
    this.tratarSituacao = this.tratarSituacao.bind(this);
    this.onPressed = this.onPressed.bind(this);
    this.handleCancelar = this.handleCancelar.bind(this);
    this.mensagemAmbienteEditadoComSucesso = this.mensagemAmbienteEditadoComSucesso.bind(this);
    this.mensagemErroAoEditarAmbiente = this.mensagemErroAoEditarAmbiente.bind(this);
    this.mensagemProblemaInesperado = this.mensagemProblemaInesperado.bind(this);
    this.mensagemAmbienteSalvoComSucesso = this.mensagemAmbienteSalvoComSucesso.bind(this);
    this.mensagemErroAoSalvarAmbiente = this.mensagemErroAoSalvarAmbiente.bind(this);
  }

  mensagemAmbienteEditadoComSucesso() {
    swal('Ambiente editado com sucesso', {
      icon: 'success',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-Ok'
        }
      }
    }).then(() => {
      this.cancelarEdicao();
    });
  }

  mensagemAmbienteSalvoComSucesso() {
    swal('Ambiente salvo com sucesso', {
      icon: 'success',
      confirmButtonColor: '#8CD4F5',
      buttons: {
        confirm: {
          confirmButtonColor: '#8CD4F5',
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-Ok'
        }
      }
    }).then(() => {
      this.cancelarEdicao();
    });
  }

  //Modal que trata a ação de cancelamento
  handleCancelar() {
    SwalConfirmacao({
      text: 'Tem certeza que deseja cancelar esta edição?'
    }).then(res => {
      if (res) {
        this.setState(
          {
            canLeave: true
          },
          () => {
            this.props.history.push('/app/ambientes');
          }
        );
      }
    });
  }

  mensagemErroAoEditarAmbiente() {
    swal('Falha ao editar o ambiente', {
      icon: 'error',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-error'
        }
      }
    });
  }

  mensagemErroAoSalvarAmbiente() {
    swal('Falha ao salvar o ambiente', {
      icon: 'error',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-error'
        }
      }
    });
  }

  mensagemProblemaInesperado() {
    swal('Ocorreu um problema inesperado', {
      icon: 'error',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-error'
        }
      }
    }).then(() => {
      this.cancelarEdicao();
    });
  }

  //Trata situação do ambiente
  tratarSituacao(situacao) {
    switch (situacao) {
      case 'Inativo':
        return 0;
      case 'Ativo':
        return 1;
      default:
        break;
    }
  }

  //Trata a submissão do form
  onPressed(values) {
    swal(
      <div>
        <CircularProgress style={{ color: '#EA6909' }} />
      </div>,
      {
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        title: this.props.match.params.id !== undefined ? 'Atualizando' : 'Salvando'
      }
    );
    const data = {
      idAmbiente: this.props.match.params.id,
      nmAmbiente: values.nome,
      stAmbiente: values.situacao
    };
    if (this.props.match.params.id !== undefined && this.props.match.params.id !== null) {
      update(data)
        .then(res => {
          return this.mensagemAmbienteEditadoComSucesso();
        })
        .catch(err => {
          if (err.response && err.response.status === 400) {
            swal('Código do ambiente já existente', {
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-error'
                }
              }
            });
            return;
          }
          return this.mensagemErroAoEditarAmbiente();
        });
    } else {
      insert(data)
        .then(res => {
          return this.mensagemAmbienteSalvoComSucesso();
        })
        .catch(err => {
          if (err.response && err.response.status === 400) {
            swal('Código do ambiente já existente', {
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-error'
                }
              }
            });
            return;
          }
          return this.mensagemErroAoSalvarAmbiente();
        });
    }
  }

  cancelarEdicao() {
    this.setState(
      {
        canLeave: true
      },
      () => {
        this.props.history.push('/app/ambientes');
      }
    );
  }

  //Em caso de edição carrega os dados nos campos
  async componentDidMount() {
    var data = this.props.location.state !== undefined ? this.props.location.state.ambiente : undefined;

    if (data !== undefined) {
      this.initialValues = {
        nome: data.nmAmbiente,
        situacao: data.stAmbiente
      };
      this.setState({
        ambiente: {
          ...this.initialValues
        }
      });
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
        <div style={{ width: '100%', marginTop: 15 }}>
          <Formik
            initialValues={{
              ...this.initialValues
            }}
            onSubmit={this.onPressed}
            validateOnBlur
            enableReinitialize={true}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              nome: Yup.string().required(<FormattedMessage id='editarAmbiente.campoObrigatorio' />),
              situacao: Yup.string().required(<FormattedMessage id='editarAmbiente.campoObrigatorio' />)
            })}
            render={({
              values,
              handleSubmit,
              errors,
              touched,
              handleBlur,
              handleChange,
              setFieldValue
            }) => {
              this.setFieldValue = setFieldValue;
              this.errors = errors;
              return (
                <div>
                  <OnExitConfirmation
                    values={values}
                    initialValues={this.initialValues}
                    canLeave={this.state.canLeave}
                  >
                    <CardIcon
                      titulo={provider.intl.formatMessage({
                        id: 'identificacao'
                      })}
                      disableExpandButton={true}
                    >
                      <form autoComplete='off'>
                        <div className={classes.card}>
                          <Grid container spacing={3}>
                            <Grid item xs={5}>
                              <TextField
                                InputProps={{
                                  maxLength: 200,
                                  classes: { underline: classes.underline }
                                }}
                                id='nome'
                                label={provider.intl.formatMessage({
                                  id: 'nome'
                                })}
                                value={values.nome}
                                onChange={e => setFieldValue('nome', e.target.value)}
                                onBlur={handleBlur}
                                error={errors.nome && touched.nome}
                                helperText={
                                  errors.nome &&
                                  touched.nome &&
                                  provider.intl.formatMessage({
                                    id: 'campoObrigatorio'
                                  })
                                }
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <FormControl
                                className={classes.formControl}
                                error={errors.situacao && touched.situacao}
                              >
                                <FormControlLabel
                                  style={{
                                    marginTop: 16
                                  }}
                                  id='situacao'
                                  name='situacao'
                                  control={
                                    <Checkbox
                                      color='primary'
                                      checked={values.situacao}
                                      onChange={e => {
                                        var situacao = e.target.checked ? 1 : 0;
                                        setFieldValue('situacao', situacao);
                                      }}
                                    />
                                  }
                                  label={
                                    <div
                                      style={{
                                        color: '#757575'
                                      }}
                                    >
                                      Ativo
                                    </div>
                                  }
                                />
                                {errors.situacao && touched.situacao && (
                                  <FormHelperText>
                                    {provider.intl.formatMessage({
                                      id: 'campoObrigatorio'
                                    })}
                                  </FormHelperText>
                                )}{' '}
                              </FormControl>
                            </Grid>
                          </Grid>
                        </div>
                      </form>
                    </CardIcon>
                  </OnExitConfirmation>
                  <div className={classes.fab}>
                    <FabDiamondMenu
                      actionSalvar={{ onClick: handleSubmit }}
                      actionCancelar={{ onClick: this.handleCancelar }}
                    />
                  </div>
                </div>
              );
            }}
          />
        </div>
      </IntlProvider>
    );
  }
}
const mapStateToProps = state => ({
  UsuarioLogado: state.adicionaUsuarioLogado,
  itensState: state.toolbar
});

let enhanced = withStyles(style)(AmbienteForm);
export default connect(mapStateToProps)(withRouter(enhanced));
