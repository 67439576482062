//Componente de escolha de imagem, Author: João Victor Ribeiro Lourenço
import React from 'react'
import CameraAltIcon from '@material-ui/icons/CameraAlt'
import Modal from '@material-ui/core/Modal'
import './styles.css'
import ImageCropper from './CropImage/ImageCropper'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import IconeClose from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 800,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid transparent',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 1, 4),
  },
  closeButtom: {
    position: 'absolute',
    top: -4,
    right: -4,
    paddingBottom: '8px',
    height: '40px',
    width: '40px',
  },
}))
function ProfileImage({ imagesCallback, initalValue }) {
  const imageUploader = React.useRef(null)
  const [imageUrl, setImageUrl] = React.useState(null)
  const [croppedImageUrl, setCroppedImageUrl] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const classes = useStyles()

  const [modalStyle] = React.useState(getModalStyle)

  function getModalStyle() {
    const top = 15

    return {
      top: `${top}%`,
      margin: 'auto',
    }
  }

  const handleImageUpload = async e => {
    const [file] = e.target.files
    if (file) {
      const reader = new FileReader()

      reader.onload = e => {
        setImageUrl(e.target.result)
        setOpen(true)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const body = (
    <Fade in={open}>
      <div style={modalStyle} className={classes.paper}>
        <IconButton
          className={classes.closeButtom}
          onClick={() => {
            setOpen(false)
            setImageUrl(null)
          }}
        >
          <span id="buttonMenu" className="disableHover">
            <IconeClose className={classes.icone} />
          </span>
        </IconButton>
        <ImageCropper
          extendedImage={imageUrl}
          setProfileImage={setCroppedImageUrl}
          setModal={setOpen}
          imagesCallback={imagesCallback}
        />
      </div>
    </Fade>
  )
  return (
    <div id="main">
      <input
        type="file"
        accept="image/jpeg, image/png"
        onChange={handleImageUpload}
        ref={imageUploader}
        style={{
          display: 'none',
        }}
      />
      <div className="container" onClick={() => imageUploader.current.click()}>
        <Fade in={croppedImageUrl !== null || initalValue !== null}>
          <img
            alt="Imagem do Perfil"
            src={croppedImageUrl || initalValue}
            className="imagem"
            style={{
              display:
                croppedImageUrl !== null || initalValue !== null
                  ? 'flex'
                  : 'none',
            }}
          />
        </Fade>
        <Fade in={croppedImageUrl === null || initalValue === null}>
          <div
            className="replacement"
            style={{
              display:
                initalValue === null && croppedImageUrl === null
                  ? 'flex'
                  : 'none',
            }}
          >
            <CameraAltIcon />
          </div>
        </Fade>
      </div>
      <Modal
        disableEnforceFocus
        disableAutoFocus
        disableBackdropClick
        className="egClass"
        style={{
          outline: '0 !important',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        classes={{
          root: classes.root,
        }}
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        {body}
      </Modal>
    </div>
  )
}

export default ProfileImage
