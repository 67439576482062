import { formatCurrency } from '@utils/functions/FormatCurrency/formatCurrency';
import moment from 'moment';

const nrProposta = {
  field: 'nrProposta',
  headerName: 'Nº',
  col: 1,
  sort: 'desc'
};
const cliente = {
  valueGetter: args => args.node.data.cliente.nmCliente,
  headerName: 'Cliente',
  col: 5
};
const valor = {
  valueGetter: args =>
    formatCurrency(parseFloat(args.node.data.vlTotal))
      .replace('R$', '')
      .trim(),
  headerName: 'Valor',
  col: 2
};
const situacao = {
  field: 'nmSituacao',
  headerName: 'Situação',
  col: 2
};

const head = [
  nrProposta,
  {
    valueGetter: args => {
      if (!args.node.data.dhCriacao) return '';

      return moment(args.node.data.dhCriacao.split('T')[0]).format('DD/MM/YY');
    },
    headerName: 'Data',
    col: 2
  },
  cliente,
  valor,
  situacao
];

const headAdmin = [
  nrProposta,
  {
    valueGetter: args => args.node.data.loja.nmLoja,
    headerName: 'Loja',
    col: 2
  },
  {
    valueGetter: args => args.node.data.vendedor.nmUsuario,
    headerName: 'Consultor',
    col: 2
  },
  {
    valueGetter: args => {
      if (!args.node.data.dhCriacao) return '';

      return moment(args.node.data.dhCriacao.split('T')[0]).format('DD/MM/YY');
    },
    headerName: 'Data',
    col: 1
  },
  {
    valueGetter: args => args.node.data.cliente.nmCliente,
    headerName: 'Cliente',
    col: 2
  },
  valor,
  situacao
];

const headGerente = [
  nrProposta,
  {
    valueGetter: args => args.node.data.vendedor.nmUsuario,
    headerName: 'Consultor',
    col: 2
  },
  {
    valueGetter: args => args.node.data.cliente.nmCliente,
    headerName: 'Cliente',
    col: 3
  },
  {
    valueGetter: args => {
      if (!args.node.data.dhCriacao) return '';

      return moment(args.node.data.dhCriacao.split('T')[0]).format('DD/MM/YY');
    },
    headerName: 'Data',
    col: 2
  },
  valor,
  situacao
];

export { head, headAdmin, headGerente };
