export default {
    telaLogin: {
        login: 'Login',
        esqueceuSenha: 'olvide mi contraseña',
        enviar: 'Enviar',
        campoObrigatorio: 'Campo obrigatório',
        emailInvalido: 'Email inválido',
        senhaMinimo: 'A senha deve ter no mínimo 6 caracteres',
        retornarLogin: 'Retornar para o Login',
        senha: 'Senha',
        usuarioIncorreto: 'Usuário ou senha incorretos',
        falhaLogin: 'Falha ao realizar login',
        falhaLoginMessage: 'Ocorreu uma falha ao realizar o login',
        emailEnviado: 'Foi enviado um E-mail com um link para você trocar sua senha',
        emailEnviadoMessage: 'E-mail enviado',
        usuarioNaoEncontrado: 'Usuario no encontrado',
        falhaRecuperarSenha: 'Error al recuperar contraseña',
        emailReenviado: 'Ya se ha enviado un correo electrónico de recuperación de contraseña',
        falhaRecuperarSenhaMensagem: 'Se produjo un error al recuperar la contraseña',
        falhaUsuarioInativo: 'Usuario está inactivo',
        falhaClienteInativo: 'El cliente está inactivo',
        usuarioNaoConfirmado: 'Usuario no confirmado'
    }
    
}