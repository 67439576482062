//@author Katia Miglioli
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import AppMainToolbar from '@components/AppMainToolbar/AppMainToolbar';
import MenuLateral from '@components/MenuLateral/MenuLateral';
import flattenMessages from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import Carregando from '@components/Carregando/Carregando';
import { Route, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import routes from '../Rotas';
import Authentication from '@resources/oauth/Authentication';
import { getAllEstados } from '@resources/api/estado';
import { getPerfil } from '@resources/api/perfil';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

const styles = theme => ({
  divAppMainToolbar: {
    width: '100%',
    height: '100%',
    position: 'relative'
  },
  fab: {
    position: 'relative',
    bottom: theme.spacing.unit * 9.5,
    right: theme.spacing.unit * 3,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    zIndex: 1,
    height: '10%',
    marginBottom: 0
  }
});

class AppMainLayout extends Component {
  constructor(props) {
    super(props);
    this.layout = this.layout.bind(this);
    this.carregando = this.carregando.bind(this);
    this.setUserInfo = this.setUserInfo.bind(this);
    this.setActualRouteInfo = this.setActualRouteInfo.bind(this);
    this.loadAppData = this.loadAppData.bind(this);
    this.state = {
      statusCarregando: true,
      location: '',
      tpPerfil: ''
    };
  }

  shouldComponentUpdate(newProps, newState) {
    return this.state.location !== window.location.pathname;
  }

  componentDidUpdate(prevProps) {
    if (this.state.location !== window.location.pathname) {
      this.setState({
        location: window.location.pathname
      });
    }
  }

  componentDidMount() {
    Authentication.refreshToken()
      .then(() => {
        this.loadAppData();
      })
      .catch(err => {
        this.props.history.push('/login');
      });
  }

  loadAppData() {
    getPerfil()
      .then(res => {
        this.setState({
          tpPerfil: res.data.tpUsuario,
          statusCarregando: false
        });
        this.setUserInfo(res.data);
      })
      .catch(err => {
        this.props.history.push('/login');
      })
      .finally(() => {
        if (this.props.estadoList.length > 0) {
          return;
        } else {
          getAllEstados().then(res => {
            var data = res.data.map(item => {
              return {
                sgUf: item.sgUf,
                idUf: item.idUf,
                nmUf: item.nmUf
              };
            });
            this.props.dispatch({
              type: 'ADD_ESTADOS',
              list: data
            });
          });
        }
      });
  }

  setUserInfo(user) {
    this.props.dispatch({
      type: 'ADD_USER_INFO',
      info: user
    });
  }
  carregando() {
    return <Carregando />;
  }

  setActualRouteInfo(info) {
    var actualRoute = info.split(/[\s/]+/, 3);
    actualRoute = actualRoute[actualRoute.length - 1].toUpperCase();
    this.props.dispatch({
      type: 'SELECIONAR_ITEM',
      itemSelecionado: actualRoute
    });
  }

  layout(classes) {
    this.setActualRouteInfo(window.location.pathname);

    return (
      <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
        <div className={classes.divAppMainToolbar}>
          <AppMainToolbar>
            <div style={{ display: 'flex', height: '100%', overflow: 'hidden' }}>
              <MenuLateral permissaoUsuario={this.state.tpPerfil} localization={this.state.location} />
              {routes.map((route, index) => (
                <Route key={index} path={route.path} exact={route.exact} component={route.main} />
              ))}
              <Route
                exact
                path='/app'
                render={() => {
                  if (this.state.tpPerfil === 3) {
                    return <Redirect to='/app/contratos' />;
                  }

                  return <Redirect to='/app/resumo-proposta' />;
                }}
              />
            </div>
          </AppMainToolbar>
        </div>
      </IntlProvider>
    );
  }

  render() {
    const { classes } = this.props;

    return this.state.statusCarregando ? this.carregando() : this.layout(classes);
  }
}

const mapStateToProps = state => ({
  itensState: state,
  UsuarioLogado: state.usuarioReducer,
  estadoList: state.estadoReducer
});

let enhaced = withStyles(styles)(AppMainLayout);
export default connect(mapStateToProps)(withRouter(enhaced));
