//@author Katia Miglioli
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Logo from '@images/logo-white.png'

const Styles = theme => ({
  logo: {
    position: 'relative',
    top: -50,
    marginLeft: '60px',
    width: 260,
    height: 124,
  },
})

function CabecalhoLogin(props) {
  const { classes } = props
  return (
    <>
      <img src={Logo} className={classes.logo} alt="Logomarca moveis Gava" />
    </>
  )
}

export default withStyles(Styles, { withTheme: true })(CabecalhoLogin)
