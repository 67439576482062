import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { DataTable, GroupButtonGrid } from '@kepha/sumora-react-components';
import CardFilters from '@components/CardFilters/CardFilters';
import FilterUtil from '../filterUtil';
import style from './style/style';
import { getUserLang } from '@utils/localeUtils';
import '@root/App.css';
import { head, headAdmin, headGerente } from './tableHead';
import SwalMessage from '@utils/functions/SwalMessage/SwalMessage';
import { findAllContratos } from '@resources/api/contrato';

class ResumoContrato extends React.Component {
  constructor(props) {
    super(props);

    this.operationNumero = {
      label: 'Número',
      field: 'nrContrato',
      type: 'string'
    };

    const consultor = {
      label: 'Consultor',
      field: 'nmUsuario',
      type: 'string'
    };

    this.operationAdmin = [
      {
        label: 'Loja',
        field: 'nmLoja',
        type: 'string'
      },
      consultor
    ];

    this.operationGerente = [consultor];

    this.operationValues = [
      {
        label: 'Data',
        field: 'dhCriacao',
        type: 'date'
      },
      {
        label: 'Entrega',
        field: 'dhEntregue',
        type: 'date'
      },
      {
        label: 'Cliente',
        field: 'nmCliente',
        type: 'string'
      },
      {
        label: 'Valor (R$)',
        field: 'vlTotal',
        type: 'number'
      },
      {
        label: 'Situação',
        field: 'stContrato',
        type: 'enum',
        options: [
          {
            label: 'Pendente',
            field: 'Pendente',
            value: '0'
          },
          {
            label: 'Em análise',
            field: 'Em análise',
            value: '1'
          },
          {
            label: 'Aprovado e não assinado',
            field: 'Aprovado e não assinado',
            value: '2'
          },
          {
            label: 'Aprovado e assinado',
            field: 'Aprovado e assinado',
            value: '3'
          },
          {
            label: 'Entregue',
            field: 'Entregue',
            value: '4'
          }
        ]
      }
    ];

    this.state = {
      sunValuesFooter: 0.0,
      operationValues: [this.operationNumero, ...this.operationValues],
      selected: undefined, // controla a linha selecionada
      dataAppSync: [], // dados que vem da api
      dataInTable: []
    };

    this.props.dispatch({
      type: 'UPDATE_TOOLBAR',
      toolbar: 'RESUMO DE CONTRATOS'
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleEdit = () => {
    this.props.history.push({
      pathname: '/app/contratos/editar/' + this.state.selected.idContrato,
      state: { contrato: this.state.selected }
    });
  };

  onFilter = async dataChips => {
    dataChips = dataChips || [];

    findAllContratos()
      .then(async res => {
        let sunValuesFooter = 0;
        var data = res.data.map(async item => {
          sunValuesFooter += item.vlTotal;
          item.nmSituacao = [
            'Pendente',
            'Em análise',
            'Apr. e não assinado',
            'Apr. e assinado',
            'Entregue'
          ][item.stContrato];
          item.nmCliente = item.cliente.nmCliente;
          item.nmLoja = item.loja.nmLoja;
          item.nmUsuario = item.vendedor.nmUsuario;
          return item;
        });

        const contratos = await Promise.all(data);
        this.setState({
          dataInTable: FilterUtil.applyFilters(contratos, dataChips),
          dataAppSync: contratos,
          sunValuesFooter: sunValuesFooter
        });
      })
      .catch(err => {
        SwalMessage({
          title: 'Falha',
          text: 'Falha ao carregar página',
          err,
          callback: () => {
            this._isMounted && this.setState({ dataAppSync: [] });
          }
        });
      });
  };

  onSelect = selected => {
    if (this._isMounted === true) {
      this.setState({
        selected: selected
      });
    }
  };
  onFilterChanged = dataChips => {
    this.setState(
      {
        dataInTable: FilterUtil.applyFilters(this.state.dataAppSync, dataChips)
      },
      () => {
        let sunValuesFooter = 0;
        this.state.dataInTable.forEach(item => {
          sunValuesFooter += item.vlTotal;
        });
        this.setState({
          sunValuesFooter: sunValuesFooter
        });
      }
    );
  };
  componentDidMount() {
    this.props.dispatch({
      type: 'CLEAN_CHIPS'
    });
    this._isMounted = true;
    this.onFilter();
  }

  render() {
    const { classes } = this.props;
    const pagination = {
      length: this.state.dataInTable.length,
      page: 0
    };

    let operationValues = this.state.operationValues;
    let headData = head;
    let keyData = 0;
    if (this.props.usuarioLogado && this.props.usuarioLogado.tpUsuario === 0) {
      headData = headAdmin;
      operationValues = [this.operationNumero, ...this.operationAdmin, ...this.operationValues];
      keyData = 1;
    } else if (this.props.usuarioLogado && this.props.usuarioLogado.tpUsuario === 1) {
      headData = headGerente;
      operationValues = [this.operationNumero, ...this.operationGerente, ...this.operationValues];
      keyData = 2;
    }

    return (
      <div className={classes.mainDiv}>
        <div>
          <CardFilters
            filtros={operationValues}
            onFilter={this.onFilter}
            onFilterChanged={this.onFilterChanged}
            usePaddings={false}
            viewKey='lojas'
          />
        </div>

        <div className={classes.buttonGrid}>
          <GroupButtonGrid
            showAdd={false}
            showEdit={false}
            showDelete={false}
            deleteColor='#5F4C3E'
            editColor='#5F4C3E'
          />
          <DataTable
            key={keyData}
            marginTop
            data={this.state.dataInTable}
            columns={headData}
            showFooter={true}
            footerData={[
              {
                loja: { nmLoja: '' },
                vendedor: { nmUsuario: '' },
                cliente: { nmCliente: 'Total de propostas: ' + this.state.dataInTable.length + '' },
                vlTotal: this.state.sunValuesFooter
              }
            ]}
            userlang={getUserLang()}
            selectChange={this.onSelect}
            showPagination={false}
            pagination={pagination}
            handleChangePage={() => {}}
            handleChangeRowsPerPage={() => {}}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  usuarioLogado: state.usuarioReducer
});
let enhanced = withStyles(style)(ResumoContrato);
export default connect(mapStateToProps)(withRouter(enhanced));
