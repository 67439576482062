import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import flattenMessages from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import ptBR from './i18n/ptBR';
import CardFilters from '@components/CardFilters/CardFilters';
import FilterUtil from '../filterUtil';
import FabDiamond from '@components/FABDiamond/FABDiamond';
import SwalConfirmacao from '@utils/functions/SwalConfirmacao/SwalConfirmacao';
import swal from '@sweetalert/with-react';
import SwalMessage from '@utils/functions/SwalMessage/SwalMessage';
import { getAll, deleteAdministrador } from '@resources/api/administrador';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DataTable, GroupButtonGrid } from '@kepha/sumora-react-components';
import { getMunicipio } from '@root/actions/municipioActions';

import '@root/App.css';

let messages = { 'pt-BR': ptBR };

const iconAdd = require('@images/ic_add_white.png');

let { provider } = new IntlProvider({});

const style = () => ({
  fab: {
    position: 'fixed',
    right: '15px',
    bottom: '40px',
    zIndex: '2000'
  },
  containerTable: {
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'column'
  },
  card: {
    height: 70,
    width: '100%',
    marginTop: '30px'
  }
});

class Administrador extends Component {
  constructor(props) {
    super(props);

    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.tratarSituacao = this.tratarSituacao.bind(this);
    this.tratarEstado = this.tratarEstado.bind(this);
    this.tratarMunicipio = this.tratarMunicipio.bind(this);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const atualizando = messagesLinguage['administrador.atualizando'];
    const tel = messagesLinguage['administrador.tel'];
    const nomeCol = messagesLinguage['administrador.nomeCol'];
    const email = messagesLinguage['administrador.email'];
    const cidade = messagesLinguage['administrador.cidade'];
    const uf = messagesLinguage['administrador.uf'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          atualizando,
          tel,
          nomeCol,
          email,
          cidade,
          uf
        }
      },
      {}
    );
    provider = intlProvider.getChildContext();

    this.state = {
      infoExtra: [],
      validarSenha: false,
      selected: undefined, // controla a linha selecionada
      dataAppSync: [], // dados que vem da api
      dataInTable: [],
      operationValues: [
        {
          label: 'Nome',
          field: 'nmUsuario',
          type: 'string'
        },
        {
          label: 'Estado',
          field: 'nmUf',
          type: 'string'
        },
        {
          label: 'Cidade',
          field: 'nmMunicipio',
          type: 'string'
        },
        {
          label: 'Situação',
          field: 'stUsuario',
          type: 'enum',
          options: [
            {
              label: 'Ativo',
              field: 'Ativo',
              value: '1'
            },
            {
              label: 'Inativo',
              field: 'Inativo',
              value: '0'
            }
          ]
        }
      ]
    };
    const title = 'administrador.title';
    if (this.props.itensState.name !== title) {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: (
          <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
            <FormattedMessage id={title} />
          </IntlProvider>
        ),
        name: title
      });
    }
  }
  componentDidMount() {
    this.props.dispatch({
      type: 'CLEAN_CHIPS'
    });
    this._isMounted = true;
    this.onFilter();
  }

  // controla a linha adicionada e muda os estados dos botões de editar e excluir
  onSelect(selected) {
    if (this._isMounted === true) {
      this.setState({
        selected: selected
      });
    }
  }
  tratarSituacao(situacao) {
    switch (parseInt(situacao)) {
      case 0:
        return 'Inativo';
      case 1:
        return 'Ativo';
      default:
        break;
    }
  }
  // ao ser adicionado novo é realizado um filtro usando os dados
  async onFilter(dataChips) {
    dataChips = dataChips || [];

    if (this.state.dataAppSync.length > 0) {
      return;
    } else {
      getAll()
        .then(async res => {
          var data = res.data.map(async item => {
            return {
              idUsuario: item.idUsuario,
              nrCpf: item.nrCpf,
              nrRg: item.nrRg,
              nmUsuario: item.nmUsuario,
              nrTelefone: item.nrTelefone,
              nrWhatsapp: item.nrWhatsapp,
              nrCep: item.nrCep,
              idUf: item.idUf,
              idMunicipio: item.idMunicipio,
              nmMunicipio: await this.tratarMunicipio(item.idUf, item.idMunicipio),
              nmUf: this.tratarEstado(item.idUf),
              dsLogradouro: item.dsLogradouro,
              dsEmail: item.dsEmail,
              stUsuario: item.stUsuario,
              dsSituacao: this.tratarSituacao(item.stUsuario),
              dtNascimento: item.dtNascimento,
              dsFotoPerfil: item.dsFotoPerfil
            };
          });
          const administradores = await Promise.all(data);
          this.setState({
            dataInTable: FilterUtil.applyFilters(administradores, dataChips),
            dataAppSync: administradores
          });
        })
        .catch(err => {
          SwalMessage({
            title: 'Falha',
            text: 'Falha ao carregar página',
            err,
            callback: () => {
              this._isMounted && this.setState({ dataAppSync: [] });
            }
          });
        });
    }
  }
  onFilterChanged(dataChips) {
    this.setState({
      dataInTable: FilterUtil.applyFilters(this.state.dataAppSync, dataChips)
    });
  }

  tratarEstado(id) {
    var estado = this.props.estadoList.find(el => {
      return el.idUf === id;
    });

    return estado.sgUf;
  }

  async tratarMunicipio(idEstado, idMunicipio) {
    var municipioFiltrado;
    await this.props
      .dispatch(getMunicipio(idEstado, idMunicipio, this.props.municipioList))
      .then(municipio => {
        municipioFiltrado = municipio.nmMunicipio;
      });
    return municipioFiltrado;
  }

  // set que o componente está desmontado e não aceita mais setState
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleAdd() {
    this.props.history.push('/app/administradores/novo');
  }

  handleEdit() {
    this.props.history.push({
      pathname: '/app/administradores/editar/' + this.state.selected.idUsuario,
      state: { administrador: this.state.selected }
    });
  }

  handleDelete() {
    SwalConfirmacao({
      text: 'Tem certeza que deseja remover este administrador?'
    }).then(res => {
      if (res) {
        swal(
          <div>
            <CircularProgress />
          </div>,
          {
            buttons: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
            title: 'Excluindo'
          }
        );
        deleteAdministrador(this.state.selected.idAdministrador)
          .then(res => {
            var data = this.state.dataAppSync;
            let index = data.indexOf(this.state.selected);
            if (index > -1) {
              data.splice(index, 1);
              data = [...data];

              this._isMounted &&
                this.setState(
                  {
                    selected: undefined,
                    dataAppSync: data,
                    dataInTable: FilterUtil.applyFilters(data, [])
                  },
                  () => {
                    SwalMessage({
                      icon: 'success',
                      title: 'Sucesso',
                      text: 'Administrador Removido com sucesso',
                      callback: () => {
                        this.forceUpdate();
                      }
                    });
                  }
                );
            }
          })
          .catch(err => {
            SwalMessage({
              title: 'Erro',
              text: 'Não foi possivel deletar esse administrador, tente novamente'
            });
          });
      }
    });
  }

  render() {
    const { classes } = this.props;

    let head = [
      {
        field: 'nmUsuario',
        headerName: provider.intl.formatMessage({ id: 'nomeCol' }),
        col: 3
      },
      {
        field: 'nrTelefone',
        headerName: provider.intl.formatMessage({ id: 'tel' }),
        col: 2
      },
      {
        field: 'dsEmail',
        headerName: provider.intl.formatMessage({ id: 'email' }),
        col: 3
      },
      {
        field: 'nmMunicipio',
        headerName: provider.intl.formatMessage({ id: 'cidade' }),
        col: 2
      },
      {
        field: 'nmUf',
        headerName: provider.intl.formatMessage({ id: 'uf' }),
        col: 1
      },
      {
        field: 'dsSituacao',
        headerName: 'Situação',
        col: 1
      }
    ];
    const pagination = {
      length: this.state.dataInTable.length,
      page: 0
    };

    return (
      <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            marginTop: 10
          }}
        >
          <div
            ref={divElement => {
              this.divElement = divElement;
            }}
          >
            <CardFilters
              filtros={this.state.operationValues}
              onFilter={this.onFilter}
              onFilterChanged={this.onFilterChanged}
              usePaddings={false}
              viewKey='lojas'
            ></CardFilters>
          </div>

          <div
            style={{
              marginTop: 10,
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <GroupButtonGrid
              showAdd={false}
              showEdit={true}
              showDelete={false}
              disableDelete={this.state.selected === undefined}
              disableEdit={this.state.selected === undefined}
              onClickDelete={this.handleDelete}
              onClickEdit={this.handleEdit}
              deleteColor='#5F4C3E'
              editColor='#5F4C3E'
            />
            <DataTable
              marginTop
              data={this.state.dataInTable}
              columns={head}
              userlang={getUserLang()}
              selectChange={this.onSelect}
              showPagination={true}
              pagination={pagination}
              handleChangePage={() => {}}
              handleChangeRowsPerPage={() => {}}
            />
          </div>
          <div className={classes.fab} onClick={this.handleAdd}>
            <FabDiamond
              url={iconAdd}
              imageStyle={{
                height: '28px',
                width: '28px',
                padding: '5px 5px 0px 0px'
              }}
            />
          </div>
        </div>
      </IntlProvider>
    );
  }
}
const mapStateToProps = state => ({
  UsuarioLogado: state.usuarioReducer,
  itensState: state.toolbar,
  estadoList: state.estadoReducer,
  municipioList: state.municipioReducer,
  getMunicipio: state.getMunicipio
});

let enhaced = withStyles(style)(Administrador);
export default connect(mapStateToProps)(withRouter(enhaced));
