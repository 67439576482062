import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import FilterUtil from '../../filterUtil';
import style from './style/style';
import '@root/App.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import SwalMessage from '@utils/functions/SwalMessage/SwalMessage';
import { getMunicipio, getMunicipios } from '@root/actions/municipioActions';
import SwalConfirmacao from '@utils/functions/SwalConfirmacao/SwalConfirmacao';
import { getClientes } from '@root/actions/clienteActions';
import ItensContratoForm from '../ItensContratoForm/ItensContratoForm';
import { getAllEstados } from '@resources/api/estado';
import Navigation from '@components/Navigator/Navigation';
import { Card } from '@material-ui/core';
import { formatCurrency } from '@utils/functions/FormatCurrency/formatCurrency';
import Fade from '@material-ui/core/Fade';
import { getAllObservacao } from '@resources/api/observacao';
import OnExitConfirmation from '@components/OnExitConfirmation/OnExitConfirmation';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  Grid,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Input,
  Select
} from '@material-ui/core';
import { TextField, MaskedTextField, AutoComplete, DatePicker } from '@kepha/sumora-react-components';
import CardIcon from '@components/CardIcon/CardIcon';
import FabDiamondMenu from '@components/FabDiamondMenu/FabDiamondMenu';
import CircularProgress from '@material-ui/core/CircularProgress';
import swal from '@sweetalert/with-react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { updateContrato, findContratoById } from '@resources/api/contrato';
import Checkbox from '@material-ui/core/Checkbox';

class ContratoForm extends React.Component {
  constructor(props) {
    super(props);
    this.initialValues = {
      dsCondicaoPgto: '',
      stContrato: 1,
      cliente: '',
      email: '',
      telefone: '',
      whatsapp: '',
      dsLogradouro: '',
      nrLogradouro: '',
      dsBairro: '',
      dsComplemento: '',
      dsEnderecoEntrega: '',
      tpEnderecoEntrega: 1,
      uf: '',
      municipio: '',
      nrCep: '',
      dataInTable: [],
      dhCriacao: null,
      dhAnalise: null,
      dhAssinatura: null,
      dhEntregue: null,
      vendedor: {
        nmUsuario: ''
      },
      loja: {
        nmLoja: ''
      }
    };

    this.state = {
      checkEntrega: true,
      keyAutoCompleteCliente: '',
      keyAutoCompleteEstado: '',
      keyAutoCompleteCidade: '',
      clienteList: [],
      selected: undefined, // controla a linha selecionada
      dataAppSync: [], // dados que vem da api
      estadoList: [],
      municipioList: [],
      tabIndex: 0,
      isPropostaForm: true,
      isAmbienteForm: false,
      index:
        this.props.location.state &&
        (this.props.location.state.isFromCalendar || this.props.location.state.isFromProjetos)
          ? 1
          : 0,
      valorTotal: 0,
      dateMessage: 'Campo obrigatório',
      isToContract: false,
      dsValorTotal: '',
      canLeave: false,
      proposta: { ...this.initialValues },
      dataInTable: [],
      indexViewItensContrato:
        this.props.location.state &&
        (this.props.location.state.isFromCalendar || this.props.location.state.isFromProjetos)
          ? 1
          : 0,
      itemContratoFromCalendarToEdit: props.location.state ? props.location.state.itemContrato : null,
      cliente: '',
      loading: !!this.props.match.params.id,
      nrContrato: undefined,
      obsItemContratoList: []
    };

    this.tratarTitulo();
    this.onChangeList = this.onChangeList.bind(this);
    this.onChangeAutocompleteCliente = this.onChangeAutocompleteCliente.bind(this);
    this.itensButtons = [
      {
        name: 'Identificação',
        key: 0,
        select: true
      },
      {
        name: 'Itens do contrato',
        key: 1,
        select: false
      }
    ];
  }

  tratarTitulo = () => {
    if (this.props.match.params.id === undefined) {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: 'CADASTRAR CONTRATO'
      });
    } else {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: 'EDITAR CONTRATO'
      });
    }
  };
  tratarSituacao = situacao => {
    switch (parseInt(situacao)) {
      case 0:
        return 'Inativo';
      case 1:
        return 'Ativo';
      default:
        break;
    }
  };

  /**
   * Manipula a mudança de index das tabs
   *
   * @param {Number} index - Index selecionado
   */
  handleChangeIndex = async index => {
    this.itensButtons.forEach(item => {
      if (index === item.key) {
        item.select = true;
      } else {
        item.select = false;
      }
    });
    this.setState({ index }, () => {
      return Promise.resolve(true);
    });
  };

  handleCancelar = () => {
    SwalConfirmacao({
      text: 'Tem certeza que deseja cancelar esta edição?'
    }).then(res => {
      if (res) {
        this.setState(
          {
            canLeave: true
          },
          () => {
            this.props.history.push('/app/contratos');
          }
        );
      }
    });
  };
  onChangeAutocompleteEstado = value => {
    let errors = this.errors;
    this.reinitialize = false;

    value && this.loadSuggestionsAutocompleteMunicipios(value);
    this.setFieldValue('municipio', '');
    this.setFieldValue('uf', value || '');
    this.setState({ keyAutoCompleteCidade: Math.random(), keyAutoCompleteEstado: Math.random() }, () => {
      this.values.tpEnderecoEntrega === 1 && this.onMountEnderecoEntrega();
    });

    this.errors = errors;
  };

  onMountEnderecoEntrega(municipio = '') {
    this.setFieldValue(
      'dsEnderecoEntrega',
      this.values.dsLogradouro +
        ', ' +
        this.values.nrLogradouro +
        ', ' +
        this.values.dsBairro +
        ', ' +
        this.values.dsComplemento +
        ', ' +
        (this.values.municipio && !municipio ? this.values.municipio.nmMunicipio : municipio) +
        ' - ' +
        (this.values.uf ? this.values.uf.sgUf : '') +
        ', CEP: ' +
        this.values.nrCep
    );
    this.errors.dsEnderecoEntrega = false;
  }

  onChangeAutocompleteCidade = value => {
    let errors = this.errors;
    this.reinitialize = false;
    this.setFieldValue && this.setFieldValue('municipio', value);
    this.errors = errors;
    if (value) {
      this.values.tpEnderecoEntrega === 1 && this.onMountEnderecoEntrega(value.nmMunicipio);
    } else {
      this.values.tpEnderecoEntrega === 1 && this.onMountEnderecoEntrega(' ');
    }
  };

  // carrega os dados para colocar no select
  loadSuggestionsAutocompleteMunicipios = async uf => {
    return this.props.dispatch(getMunicipios(uf.idUf, this.props.municipioList)).then(municipios => {
      this.setState({
        municipioList: municipios
      });
    });
  };

  tratarEstado = id => {
    var uf = this.props.estadoList.find(el => {
      return el.idUf === id;
    });

    return uf;
  };

  tratarMunicipio = async (idEstado, idMunicipio) => {
    var municipioFiltrado;
    await this.props
      .dispatch(getMunicipio(idEstado, idMunicipio, this.props.municipioList))
      .then(municipio => {
        this.setState({
          municipioList: this.props.municipioList[idEstado]
        });
        municipioFiltrado = municipio;
      });
    return municipioFiltrado;
  };

  handleSubmit = async values => {
    if (!this.state.cliente) {
      this.setFieldTouched('cliente', true);
      return;
    }

    if (this.state.indexViewItensContrato === 1) {
      this.mensagemTermineEdicaoItemContrato();
      return;
    }

    values.vlTotal = this.state.dataAppSync.reduce((prev, curr) => prev + curr.vlItem, 0);
    values.cliente = this.state.cliente;
    values.idContrato = this.props.match.params.id;
    values.dsEnderecoEntrega = values.dsEnderecoEntrega.substring(0, 255);

    this.state.dataAppSync.forEach(item => (item.idItemContrato = item.idItemContrato || null));
    values.itemContratoList = this.state.dataAppSync;

    swal(
      <div>
        <CircularProgress style={{ color: '#EA6909' }} />
      </div>,
      {
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        title: 'Salvando'
      }
    );

    await new Promise(resolve => this.setState({ loading: true }, resolve));

    updateContrato(values)
      .then(res => {
        swal('Contrato salvo com sucesso', {
          icon: 'success',
          confirmButtonColor: '#8CD4F5',
          buttons: {
            confirm: {
              confirmButtonColor: '#8CD4F5',
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-Ok'
            }
          }
        });
        this.setState({ canLeave: true, loading: false }, () =>
          this.props.history.push('/app/contratos')
        );
      })
      .catch(err => {
        this.setState({ loading: false });
        swal('Ocorreu um erro ao salvar o contrato', {
          icon: 'error',
          confirmButtonColor: '#8CD4F5',
          buttons: {
            confirm: {
              confirmButtonColor: '#8CD4F5',
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-Ok'
            }
          }
        });
      });
  };

  mensagemTermineEdicaoItemContrato = () => {
    this.setState({ index: 1 }, () =>
      swal('Salve os dados do item do contrato', {
        icon: 'error',
        confirmButtonColor: '#8CD4F5',
        buttons: {
          confirm: {
            confirmButtonColor: '#8CD4F5',
            text: 'Ok',
            value: true,
            visible: true,
            closeModal: true,
            className: 'swal2-Ok'
          }
        }
      })
    );
  };

  tratarEstado = id => {
    var uf = this.props.estadoList.find(el => {
      return el.idUf === id;
    });

    return uf;
  };

  tratarMunicipio = async (idEstado, idMunicipio) => {
    var municipioFiltrado;
    await this.props
      .dispatch(getMunicipio(idEstado, idMunicipio, this.props.municipioList))
      .then(municipio => {
        this.setState({
          municipioList: this.props.municipioList[idEstado]
        });
        municipioFiltrado = municipio;
      });
    return municipioFiltrado;
  };

  async onChangeAutocompleteCliente(value, isMounting) {
    let errors = this.errors;
    this.reinitialize = false;

    if (value) {
      this.setFields(value, false);

      const uf = await this.tratarEstado(value.idUf);
      const municipio = await this.tratarMunicipio(value.idUf, value.idMunicipio);

      this.setFieldValue('uf', uf);
      this.setFieldValue('municipio', municipio);
      this.setFieldValue('nrCep', value.nrCep);
    } else {
      this.setFieldValue('email', '');
      this.setFieldValue('telefone', '');
      this.setFieldValue('whatsapp', '');
    }
    this.setFieldValue('cliente', value);
    this.setState({
      cliente: value,
      keyAutoCompleteCliente: Math.random(),
      keyAutoCompleteCidade: Math.random(),
      keyAutoCompleteEstado: Math.random()
    });

    this.errors = errors;
  }

  setFields = async (value, isMounting) => {
    return new Promise((resolve, reject) => {
      this.setFieldValue('cliente', value);
      if (!value) {
        this.setState({ keyAutoCompleteCliente: Math.random() });
        return;
      }

      this.setFieldValue('email', value.dsEmail);
      this.setFieldValue('telefone', value.nrTelefone);
      this.setFieldValue('whatsapp', value.nrWhatsapp);

      !this.values.dsLogradouro && this.setFieldValue('dsLogradouro', value.dsLogradouro);
      !this.values.nrLogradouro && this.setFieldValue('nrLogradouro', value.nrLogradouro);
      !this.values.dsBairro && this.setFieldValue('dsBairro', value.dsBairro);
      !this.values.dsComplemento && this.setFieldValue('dsComplemento', value.dsComplemento);

      if (isMounting) {
        this.initialValues = {
          ...this.initialValues,
          email: value.dsEmail,
          telefone: value.nrTelefone,
          whatsapp: value.nrWhatsapp,
          dsLogradouro: this.initialValues.dsLogradouro || value.dsLogradouro,
          nrLogradouro: this.initialValues.nrLogradouro || value.nrLogradouro,
          dsBairro: this.initialValues.dsBairro || value.dsBairro,
          dsComplemento: this.initialValues.dsComplemento || value.dsComplemento
        };
      }

      this.setState(
        {
          cliente: value,
          keyAutoCompleteCliente: Math.random()
        },
        resolve
      );
    });
  };

  loadClientes = async () => {
    await this.props.dispatch(getClientes(this.props.clienteList)).then(list => {
      this.setState({ clienteList: list });
    });
  };
  processValue = list => {
    var valorTotal = 0;
    for (var i = 0; i < list.length; i++) {
      valorTotal += list[i].vlItem;
    }
    return valorTotal;
  };
  async componentDidMount() {
    this._isMounted = true;

    await this.loadClientes();
    this.getAllEstados();
    this.getAllObservacoes();
    this.props.dispatch({ type: 'CLEAN_CHIPS' });

    await new Promise(resolve => this.setState({ loading: true }, resolve));

    if (this.props.location.state && this.props.location.state.isFromCalendar) {
      this.setData(this.props.location.state.itemContrato.contrato);
      return;
    }

    findContratoById(this.props.match.params.id)
      .then(res => {
        this.setData(res.data);
      })
      .catch(err => {
        this.setState({ loading: false });
        swal('Erro ao buscar dados do contrato', {
          icon: 'error',
          confirmButtonColor: '#8CD4F5',
          buttons: {
            confirm: {
              confirmButtonColor: '#8CD4F5',
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-Ok'
            }
          }
        });
      });
  }

  async setData(data) {
    const cliente = this.state.clienteList.find(cliente => cliente.idCliente === data.cliente.idCliente);
    await this.setFields(cliente, true);

    await this.loadSuggestionsAutocompleteMunicipios(data.uf);

    this.setFieldValue('uf', data.uf);
    this.setFieldValue('municipio', data.municipio);

    this.initialValues = {
      ...this.initialValues,
      ...data,
      dhCriacao: data.dhCriacao ? data.dhCriacao.split('[')[0] : null,
      dhAnalise: data.dhAnalise ? data.dhAnalise.split('[')[0] : null,
      dhAssinatura: data.dhAssinatura ? data.dhAssinatura.split('[')[0] : null,
      dhEntregue: data.dhEntregue ? data.dhEntregue.split('[')[0] : null,
      tpEnderecoEntrega: data.tpEnderecoEntrega,
      dsEnderecoEntrega: data.dsEnderecoEntrega || ''
    };

    const valorTotal = this.processValue(data.itemContratoList);
    this.setState(
      {
        nrContrato: data.nrContrato,
        dataAppSync: data.itemContratoList,
        valorTotal,
        dsValorTotal: this.formatToBrl(valorTotal),
        keyAutoCompleteCidade: Math.random(),
        keyAutoCompleteEstado: Math.random(),
        checkEntrega: data.tpEnderecoEntrega === 1
      },
      () => {
        this.setFieldValue('dsCondicaoPgto', data.dsCondicaoPgto);
        this.setFieldValue('dhCriacao', data.dhCriacao ? data.dhCriacao.split('[')[0] : null);
        this.setFieldValue('dhAnalise', data.dhAnalise ? data.dhAnalise.split('[')[0] : null);
        this.setFieldValue('dhAssinatura', data.dhAssinatura ? data.dhAssinatura.split('[')[0] : null);
        this.setFieldValue('dhEntregue', data.dhEntregue ? data.dhEntregue.split('[')[0] : null);
        this.setFieldValue('stContrato', data.stContrato);
        this.setFieldValue('nrCep', data.nrCep);
        this.setFieldValue('dsFotoAssinatura', data.dsFotoAssinatura);
        this.setFieldValue('dsEnderecoEntrega', data.dsEnderecoEntrega);

        this.setState({ loading: false });
      }
    );
  }

  formatToBrl = value => {
    return formatCurrency(parseFloat(value));
  };
  onChangeList(data, options) {
    var list = data;

    this.setState(
      {
        dataAppSync: list,
        dataInTable: FilterUtil.applyFilters([...list], [])
      },
      () => {
        if (options.valorTotal !== undefined) {
          this.setState({
            valorTotal: options.valorTotal,
            dsValorTotal: this.formatToBrl(options.valorTotal)
          });
        }
        if (options.isMounting) {
          this.initialValues = {
            ...this.initialValues,
            dataInTable: [...list]
          };
        }
      }
    );
  }
  handleDelete = () => {
    SwalConfirmacao({
      text: 'Tem certeza que deseja remover este item?'
    }).then(res => {
      if (res) {
        var data = this.state.dataAppSync;
        let index = data.indexOf(this.state.selected);
        if (index > -1) {
          data.splice(index, 1);
          data = [...data];

          this._isMounted &&
            this.setState(
              {
                selected: undefined,
                dataAppSync: data,
                dataInTable: FilterUtil.applyFilters(data, [])
              },
              () => {
                SwalMessage({
                  icon: 'success',
                  title: 'Sucesso',
                  text: 'Ambiente Removido com sucesso',
                  callback: () => {
                    this.forceUpdate();
                  }
                });
              }
            );
        }
      }
    });
  };
  goToAmbienteForm = () => {
    this.setState({ tabIndex: 1, isAmbienteForm: true });
  };

  getAllEstados = () => {
    getAllEstados().then(res => {
      var data = res.data;

      this.setState({
        estadoList: data
      });
    });
  };

  getAllObservacoes = () => {
    getAllObservacao().then(res => {
      var data = res.data;

      this.setState({
        obsItemContratoList: data
      });
    });
  };

  propostaForm = (classes, pagination) => {
    return (
      <Formik
        initialValues={{
          ...this.initialValues
        }}
        onSubmit={this.handleSubmit}
        validateOnBlur
        enableReinitialize={true}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          dsCondicaoPgto: Yup.string().required('Campo Obrigatório'),
          stContrato: Yup.string().required('Campo Obrigatório'),
          email: Yup.string().required('Campo Obrigatório'),
          telefone: Yup.string().required('Campo Obrigatório'),
          whatsapp: Yup.string().required('Campo Obrigatório'),
          dsLogradouro: Yup.string()
            .max(100, 'Limite máximo de caracteres atingido')
            .required('Campo Obrigatório'),
          nrLogradouro: Yup.string()
            .max(10, 'Limite máximo de caracteres atingido')
            .required('Campo Obrigatório'),
          dsBairro: Yup.string()
            .max(60, 'Limite máximo de caracteres atingido')
            .required('Campo Obrigatório'),
          dsComplemento: Yup.string()
            .max(200, 'Limite máximo de caracteres atingido')
            .required('Campo Obrigatório'),
          uf: Yup.string().required('Campo Obrigatório'),
          municipio: Yup.string().required('Campo Obrigatório'),
          dsEnderecoEntrega:
            this.initialValues.stContrato >= 2
              ? Yup.string()
              : Yup.string().required('Campo Obrigatório')
        })}
        render={({
          values,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          setFieldValue,
          setFieldTouched,
          handleChange
        }) => {
          this.values = { ...values };
          this.setFieldValue = setFieldValue;
          this.errors = errors;
          this.onSubmit = handleSubmit;
          this.setFieldTouched = setFieldTouched;

          return (
            <div
              style={{
                height: '860px',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <OnExitConfirmation
                values={{ ...this.values, dataInTable: this.state.dataInTable }}
                initialValues={this.initialValues}
                canLeave={this.state.canLeave}
              >
                <Card className={classes.cardTitle}>
                  <div>
                    <span className={classes.nrContrato}>
                      {values.nrContrato ? `Nº ${values.nrContrato}` : 'Nº ----'}
                    </span>

                    <Fade in={this.state.valorTotal > 0}>
                      <span className={classes.nrValor}>{this.state.dsValorTotal}</span>
                    </Fade>
                  </div>
                  <span className={classes.nrContrato}>
                    {this.state.cliente && this.state.cliente.nmCliente
                      ? this.state.cliente.nmCliente
                      : ''}
                  </span>
                </Card>

                <div style={{ height: 'calc(100vh - 162px)', overflowY: 'auto' }}>
                  <CardIcon
                    style={{ overflow: 'initial' }}
                    titulo='Dados do Cliente'
                    disableExpandButton={true}
                    cardContentStyle={{ paddingTop: 15 }}
                  >
                    <div className={classes.cardContent}>
                      <Grid item xs={5}>
                        <AutoComplete
                          itens={this.state.clienteList}
                          value={this.state.cliente}
                          campoOp='nmCliente'
                          valueAutoComplete={this.state.cliente}
                          campoChave='idCliente'
                          label='Cliente *'
                          name='cliente'
                          id='cliente'
                          error={!this.state.cliente && touched.cliente}
                          helperText={!this.state.cliente && touched.cliente ? 'Campo obrigatório' : ' '}
                          onChange={this.onChangeAutocompleteCliente}
                          onChangeAutoComplete={this.loadClientes}
                          onBlur={handleBlur('cliente')}
                          key={this.state.keyAutoCompleteCliente}
                          maxHeight='calc(100vh - 200px)'
                          disabled
                        />
                      </Grid>
                      <Grid item xs={3} className={classes.inputPadding}>
                        <TextField
                          disabled={true}
                          type='email'
                          name='email'
                          onChange={e => setFieldValue('email', e.target.value)}
                          onBlur={handleBlur}
                          value={values.email}
                          error={errors.email && touched.email}
                          helperText={errors.email && touched.email ? errors.email : null}
                          InputProps={{
                            classes: { underline: classes.underline }
                          }}
                          label='E-mail *'
                        />
                      </Grid>
                      <Grid item xs={2} className={classes.inputPadding}>
                        <MaskedTextField
                          disabled={true}
                          label='Tel. Residencial *'
                          tipoMascara='telefone'
                          userlang='pt-BR'
                          InputProps={{
                            classes: { underline: classes.underline }
                          }}
                          id='telefone'
                          value={values.telefone}
                          onChange={e => setFieldValue('telefone', e.target.value)}
                          onBlur={handleBlur}
                          error={errors.telefone && touched.telefone}
                          helperText={errors.telefone && touched.telefone && errors.telefone}
                        />
                      </Grid>
                      <Grid item xs={2} className={classes.inputPadding}>
                        <MaskedTextField
                          disabled={true}
                          label='WhatsApp *'
                          tipoMascara='telefone'
                          userlang='pt-BR'
                          InputProps={{
                            classes: { underline: classes.underline }
                          }}
                          id='whatsapp'
                          value={values.whatsapp}
                          onChange={e => setFieldValue('whatsapp', e.target.value)}
                          onBlur={handleBlur}
                          error={errors.whatsapp && touched.whatsapp}
                          helperText={errors.whatsapp && touched.whatsapp && errors.whatsapp}
                        />
                      </Grid>
                    </div>

                    <div className={classes.cardContent}>
                      <Grid item xs={2}>
                        <AutoComplete
                          disabled={
                            this.initialValues.stContrato >= 2 ||
                            (this.props.usuarioLogado || {}).tpUsuario === 3
                          }
                          itens={this.state.estadoList}
                          value={values.uf}
                          campoOp='sgUf'
                          valueAutoComplete={values.uf}
                          campoChave='idUf'
                          label='Estado *'
                          name='uf'
                          id='uf'
                          valueSelect={values.uf}
                          error={errors.uf && touched.uf}
                          helperText={errors.uf && touched.uf && 'Campo obrigatório'}
                          onChange={this.onChangeAutocompleteEstado}
                          onChangeAutoComplete={this.getAllEstados}
                          key={this.state.keyAutoCompleteEstado}
                          maxHeight='calc(100vh - 572px)'
                        />
                      </Grid>

                      <Grid item xs={3} className={classes.inputPadding}>
                        <AutoComplete
                          itens={this.state.municipioList}
                          value={values.municipio}
                          campoOp='nmMunicipio'
                          campoChave='idMunicipio'
                          disabled={
                            !values.uf ||
                            this.initialValues.stContrato >= 2 ||
                            (this.props.usuarioLogado || {}).tpUsuario === 3
                          }
                          label='Cidade *'
                          name='municipio'
                          id='municipio'
                          error={errors.municipio && touched.municipio}
                          helperText={errors.municipio && touched.municipio && 'Campo obrigatório'}
                          onChange={this.onChangeAutocompleteCidade}
                          key={this.state.keyAutoCompleteCidade}
                          valueAutoComplete={values.municipio}
                          maxHeight='calc(100vh - 572px)'
                        />
                      </Grid>

                      <Grid item xs={3} className={classes.inputPadding}>
                        <TextField
                          disabled={
                            this.initialValues.stContrato >= 2 ||
                            (this.props.usuarioLogado || {}).tpUsuario === 3
                          }
                          InputProps={{
                            classes: { underline: classes.underline }
                          }}
                          id='dsLogradouro'
                          name='dsLogradouro'
                          label='Endereço *'
                          value={values.dsLogradouro}
                          onChange={e => setFieldValue('dsLogradouro', e.target.value)}
                          onBlur={e => {
                            handleBlur(e);
                            this.values.tpEnderecoEntrega === 1 && this.onMountEnderecoEntrega();
                          }}
                          error={errors.dsLogradouro && touched.dsLogradouro}
                          helperText={errors.dsLogradouro && touched.dsLogradouro && errors.dsLogradouro}
                        />
                      </Grid>

                      <Grid item xs={2} className={classes.inputPadding}>
                        <TextField
                          disabled={
                            this.initialValues.stContrato >= 2 ||
                            (this.props.usuarioLogado || {}).tpUsuario === 3
                          }
                          InputProps={{
                            classes: { underline: classes.underline }
                          }}
                          id='nrLogradouro'
                          name='nrLogradouro'
                          label='Número *'
                          value={(values.nrLogradouro || '')
                            .split('e')
                            .join('')
                            .split('E')
                            .join('')}
                          onChange={e => {
                            if (isNaN(+e.target.value)) return;

                            e.target.value = +e.target.value
                              .split('e')
                              .join('')
                              .split('E')
                              .join('');
                            handleChange(e);
                          }}
                          onBlur={e => {
                            this.values.tpEnderecoEntrega === 1 && this.onMountEnderecoEntrega();
                            handleBlur(e);
                          }}
                          error={errors.nrLogradouro && touched.nrLogradouro}
                          helperText={errors.nrLogradouro && touched.nrLogradouro && errors.nrLogradouro}
                        />
                      </Grid>
                    </div>

                    <div className={classes.cardContent}>
                      <Grid item xs={2}>
                        <MaskedTextField
                          disabled={
                            this.initialValues.stContrato >= 2 ||
                            (this.props.usuarioLogado || {}).tpUsuario === 3
                          }
                          label='CEP'
                          tipoMascara='cep'
                          userlang='pt-BR'
                          InputProps={{
                            classes: { underline: classes.underline }
                          }}
                          id='nrCep'
                          value={values.nrCep}
                          onChange={e => setFieldValue('nrCep', e.target.value)}
                          onBlur={e => {
                            handleBlur(e);
                            this.values.tpEnderecoEntrega === 1 && this.onMountEnderecoEntrega();
                          }}
                          error={errors.nrCep && touched.nrCep}
                          helperText={errors.nrCep && touched.nrCep && errors.nrCep}
                        />
                      </Grid>

                      <Grid item xs={3} className={classes.inputPadding}>
                        <TextField
                          disabled={
                            this.initialValues.stContrato >= 2 ||
                            (this.props.usuarioLogado || {}).tpUsuario === 3
                          }
                          InputProps={{
                            classes: { underline: classes.underline }
                          }}
                          id='dsBairro'
                          name='dsBairro'
                          label='Bairro *'
                          value={values.dsBairro}
                          onChange={e => setFieldValue('dsBairro', e.target.value)}
                          onBlur={e => {
                            handleBlur(e);
                            this.values.tpEnderecoEntrega === 1 && this.onMountEnderecoEntrega();
                          }}
                          error={errors.dsBairro && touched.dsBairro}
                          helperText={errors.dsBairro && touched.dsBairro && errors.dsBairro}
                        />
                      </Grid>

                      <Grid item xs={5} className={classes.inputPadding}>
                        <TextField
                          disabled={
                            this.initialValues.stContrato >= 2 ||
                            (this.props.usuarioLogado || {}).tpUsuario === 3
                          }
                          InputProps={{
                            classes: { underline: classes.underline }
                          }}
                          id='dsComplemento'
                          name='dsComplemento'
                          label='Complemento *'
                          value={values.dsComplemento}
                          onChange={e => setFieldValue('dsComplemento', e.target.value)}
                          onBlur={e => {
                            handleBlur(e);
                            this.values.tpEnderecoEntrega === 1 && this.onMountEnderecoEntrega();
                          }}
                          error={errors.dsComplemento && touched.dsComplemento}
                          helperText={
                            errors.dsComplemento && touched.dsComplemento && errors.dsComplemento
                          }
                        />
                      </Grid>
                    </div>
                    <div className={classes.cardContent}>
                      <Grid item xs={10} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <FormControlLabel
                          style={{
                            minWidth: 270
                          }}
                          id='checkEntrega'
                          name='checkEntrega'
                          control={
                            <Checkbox
                              disabled={
                                this.initialValues.stContrato >= 2 ||
                                (this.props.usuarioLogado || {}).tpUsuario === 3
                              }
                              color='primary'
                              checked={this.state.checkEntrega}
                              onChange={e => {
                                setFieldValue('tpEnderecoEntrega', e.target.checked ? 1 : 0);
                                if (e.target.checked) {
                                  this.onMountEnderecoEntrega();
                                }
                                this.setState({
                                  checkEntrega: e.target.checked
                                });
                              }}
                            />
                          }
                          label={
                            <div
                              style={{
                                color: '#757575'
                              }}
                            >
                              Entregar no mesmo endereço
                            </div>
                          }
                        />
                      </Grid>
                    </div>

                    <div className={classes.cardContent}>
                      <Grid item xs={10} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <TextField
                          disabled={
                            this.initialValues.stContrato >= 2 ||
                            this.state.checkEntrega ||
                            (this.props.usuarioLogado || {}).tpUsuario === 3
                          }
                          InputProps={{
                            classes: { underline: classes.underline }
                          }}
                          id='dsEnderecoEntrega'
                          name='dsEnderecoEntrega'
                          label='Endereço de entrega *'
                          value={values.dsEnderecoEntrega}
                          onChange={e => setFieldValue('dsEnderecoEntrega', e.target.value)}
                          onBlur={handleBlur}
                          error={errors.dsEnderecoEntrega && touched.dsEnderecoEntrega}
                          helperText={
                            errors.dsEnderecoEntrega &&
                            touched.dsEnderecoEntrega &&
                            errors.dsEnderecoEntrega
                          }
                        />
                      </Grid>
                    </div>
                  </CardIcon>

                  <CardIcon
                    style={{ overflow: 'initial' }}
                    titulo='Identificação'
                    disableExpandButton={true}
                    extraStyles={{ marginTop: 15, marginBottom: 15 }}
                    cardContentStyle={{ paddingTop: 15 }}
                  >
                    <div className={classes.cardContent}>
                      <Grid item xs={6}>
                        <TextField
                          disabled={true}
                          type='loja'
                          name='loja'
                          value={values.loja.nmLoja}
                          InputProps={{
                            classes: { underline: classes.underline }
                          }}
                          label='Loja'
                        />
                      </Grid>
                      <Grid item xs={5} className={classes.inputPadding}>
                        <TextField
                          disabled={true}
                          type='consultor'
                          name='consultor'
                          value={values.vendedor.nmUsuario}
                          InputProps={{
                            classes: { underline: classes.underline }
                          }}
                          label='Consultor'
                        />
                      </Grid>
                    </div>
                    <div className={classes.cardContent}>
                      <Grid item xs={11}>
                        <TextField
                          disabled={
                            this.initialValues.stContrato >= 2 ||
                            (this.props.usuarioLogado || {}).tpUsuario === 3
                          }
                          InputProps={{
                            inputProps: { maxLength: 200 },
                            classes: { underline: classes.underline }
                          }}
                          id='dsCondicaoPgto'
                          label='Condições de pagamento *'
                          value={values.dsCondicaoPgto}
                          onChange={e => {
                            setFieldValue('dsCondicaoPgto', e.target.value);
                          }}
                          onBlur={handleBlur}
                          error={errors.dsCondicaoPgto && touched.dsCondicaoPgto}
                          helperText={
                            errors.dsCondicaoPgto && touched.dsCondicaoPgto && 'Campo Obrigatório'
                          }
                        />
                      </Grid>
                    </div>

                    <div className={classes.cardContent}>
                      <Grid item xs={2}>
                        <DatePicker
                          userlang='pt-BR'
                          name='dhCriacao'
                          label='Criação'
                          value={values.dhCriacao}
                          disabled
                        />
                      </Grid>

                      <Grid item xs={2} className={classes.inputPadding}>
                        <DatePicker
                          userlang='pt-BR'
                          label='Análise'
                          value={values.dhAnalise}
                          onChange={() => {}}
                          disabled
                        />
                      </Grid>

                      <Grid item xs={2} className={classes.inputPadding}>
                        <DatePicker
                          userlang='pt-BR'
                          label='Assinatura'
                          value={values.dhAssinatura}
                          onChange={() => {}}
                          disabled
                        />
                      </Grid>

                      <Grid item xs={2} className={classes.inputPadding}>
                        <DatePicker
                          userlang='pt-BR'
                          label='Entrega'
                          value={values.dhEntregue}
                          onChange={() => {}}
                          disabled
                        />
                      </Grid>

                      <Grid className={classes.inputPadding} item xs={3}>
                        <FormControl
                          fullWidth
                          style={{ marginTop: 1 }}
                          className={classes.formControl}
                          error={errors.filter && touched.filter}
                          disabled={(this.props.usuarioLogado || {}).tpUsuario === 3}
                        >
                          <InputLabel style={{ fontSize: 14 }} htmlFor='statusProposta'>
                            Situação
                          </InputLabel>
                          <Select
                            SelectDisplayProps={{
                              style: { backgroundColor: 'transparent' }
                            }}
                            input={<Input id='statusProposta' />}
                            value={values.stContrato}
                            name='stContrato'
                            onChange={e => {
                              if (+e.target.value === +values.stContrato) return;

                              SwalConfirmacao({
                                text:
                                  'Se mudar o status do contrato e salvar não será possível voltar atrás, deseja continuar?',
                                inverteButtonsColors: true
                              }).then(res => {
                                if (res) {
                                  setFieldValue('stContrato', e.target.value);
                                }
                              });
                            }}
                            onBlur={handleBlur}
                          >
                            <MenuItem
                              value={0}
                              disabled={
                                values.stContrato !== '' &&
                                +values.stContrato > 0 &&
                                this.initialValues.stContrato > 0
                              }
                            >
                              Pendente
                            </MenuItem>
                            <MenuItem
                              value={1}
                              disabled={
                                values.stContrato !== '' &&
                                +values.stContrato > 1 &&
                                this.initialValues.stContrato > 1
                              }
                            >
                              Em análise
                            </MenuItem>
                            <MenuItem
                              value={2}
                              disabled={
                                values.stContrato !== '' &&
                                +values.stContrato > 2 &&
                                this.initialValues.stContrato > 2
                              }
                            >
                              Aprovado e não assinado
                            </MenuItem>
                            <MenuItem
                              value={3}
                              disabled={
                                values.stContrato !== '' &&
                                +values.stContrato > 3 &&
                                this.initialValues.stContrato > 3
                              }
                            >
                              Aprovado e assinado
                            </MenuItem>
                            <MenuItem
                              value={4}
                              disabled={
                                values.stContrato !== '' &&
                                +values.stContrato > 4 &&
                                this.initialValues.stContrato > 4
                              }
                            >
                              Entregue
                            </MenuItem>
                          </Select>
                          <FormHelperText>
                            {errors.filter && touched.filter ? errors.filter : null}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </div>

                    {values.dsFotoAssinatura && (
                      <>
                        <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Assinatura:</span>
                        <div className={classes.cardContent} style={{ height: 140 }}>
                          <Grid item xs>
                            <img
                              onClick={() =>
                                window.open(
                                  values.dsFotoAssinatura,
                                  'Image',
                                  'width=largeImage.stylewidth,height=largeImage.style.height,resizable=1'
                                )
                              }
                              src={values.dsFotoAssinatura}
                              alt='Foto da assinatura'
                              style={{ objectFit: 'contain', maxHeight: 140, cursor: 'pointer' }}
                            />
                          </Grid>
                        </div>
                      </>
                    )}
                  </CardIcon>
                </div>
              </OnExitConfirmation>
            </div>
          );
        }}
      />
    );
  };

  render() {
    const { classes } = this.props;
    const pagination = {
      length: this.state.dataInTable.length,
      page: 0
    };
    return (
      <div className={classes.form} style={{ height: '100%' }}>
        {this.state.loading && (
          <LinearProgress
            style={{
              position: 'absolute',
              zIndex: 99999,
              height: 3.5,
              width: '100vw',
              top: 0,
              backgroundColor: 'white'
            }}
          />
        )}

        <Navigation
          index={this.state.index}
          handleChangeIndex={this.handleChangeIndex}
          buttons={this.itensButtons}
        >
          {[
            <div style={{ width: '100%' }}>{this.propostaForm(classes, pagination)}</div>,
            <div style={{ width: '100%', height: '100%' }}>
              <ItensContratoForm
                obsItemContratoList={this.state.obsItemContratoList}
                isFromContratos={true}
                list={this.state.dataInTable}
                listFromApi={this.state.dataAppSync}
                onChangeList={this.onChangeList}
                cliente={
                  this.state.cliente && this.state.cliente.nmCliente ? this.state.cliente.nmCliente : ''
                }
                valorTotal={this.state.valorTotal}
                index={this.state.indexViewItensContrato}
                onChangeIndexView={newIndex => this.setState({ indexViewItensContrato: newIndex })}
                nrContrato={this.state.nrContrato ? `Nº ${this.state.nrContrato}` : 'Nº ----'}
                stContrato={this.initialValues.stContrato}
                itemContratoFromCalendarToEdit={this.state.itemContratoFromCalendarToEdit}
                usuarioLogado={this.props.usuarioLogado || {}}
              />
            </div>
          ]}
        </Navigation>

        <div
          className={classes.fab}
          style={
            this.state.index === 1 && this.state.indexViewItensContrato === 1
              ? { display: 'none' }
              : undefined
          }
        >
          <FabDiamondMenu
            actionSalvar={{
              onClick: () => {
                if (this.state.index !== 0) {
                  this.handleChangeIndex(0).then(result => {
                    this.onSubmit();
                  });
                  return;
                }

                this.onSubmit();
              }
            }}
            actionCancelar={{ onClick: this.handleCancelar }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  usuarioLogado: state.usuarioReducer,
  itensState: state.toolbar,
  estadoList: state.estadoReducer,
  municipioList: state.municipioReducer,
  lojaList: state.lojaReducer,
  clienteList: state.clienteReducer
});
let enhanced = withStyles(style)(ContratoForm);
export default connect(mapStateToProps)(withRouter(enhanced));
