import * as React from 'react'

function SvgIconLojas(props) {
  return (
    <svg width="1.30em" height="1.30em" viewBox="0 0 24 24" {...props}>
      <path data-name="Path 2223" d="M0 0h24v24H0z" fill="none" />
      <path
        data-name="Path 2224"
        d="M20 4H4v2h16zm1 10v-2l-1-5H4l-1 5v2h1v6h10v-6h4v6h2v-6zm-9 4H6v-4h6z"
        fill={props.color}
      />
    </svg>
  )
}

export default SvgIconLojas