const style = () => ({
  fab: {
    position: 'fixed',
    right: '15px',
    bottom: '40px',
    zIndex: '2000'
  },
  containerTable: {
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'column'
  },
  card: {
    height: 70,
    width: '100%',
    marginTop: '30px'
  },
  mainDiv: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: 10
  },
  buttonGrid: {
    marginTop: 40,
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  divButtonFiltrar: {
    position: 'relative',
    float: 'right',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    left: '25px'
  },
  button: {
    marginTop: 20,
    minWight: 34,
    maxWight: 34,
    minHeight: 34,
    maxHeight: 34,
    marginRight: '25px',
    zIndex: 1
  }
});

export default style;
