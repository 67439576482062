import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import flattenMessages from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withStyles } from '@material-ui/core/styles';
import ptBR from './i18n/ptBR';
import head from '../ItensPropostaForm/tableHead';
import { TextField, AutoComplete, MaskedTextField, DatePicker } from '@kepha/sumora-react-components';
import swal from '@sweetalert/with-react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import { getAll as getAllAmbientes } from '@resources/api/ambiente';
import { getAll as getAllAcabamentos } from '@resources/api/acabamento';
import SwalConfirmacao from '@utils/functions/SwalConfirmacao/SwalConfirmacao';
import { DataTable, GroupButtonGrid } from '@kepha/sumora-react-components';
import Fade from '@material-ui/core/Fade';
import { formatCurrency } from '@utils/functions/FormatCurrency/formatCurrency';
import moment from 'moment';
import IconeFile from '@material-ui/icons/AttachFile';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import SwipeableViews from 'react-swipeable-views';
import InputAdornment from '@material-ui/core/InputAdornment';
import CardWithTabs from '@components/CardWithTabs/CardWithTabs';
import {
  MuiThemeProvider,
  createMuiTheme,
  FormControl,
  InputLabel,
  MenuItem,
  Input,
  FormHelperText,
  Select
} from '@material-ui/core';
import SwalMessage from '@utils/functions/SwalMessage/SwalMessage';

let messages = { 'pt-BR': ptBR };
let { provider } = new IntlProvider({});

const style = () => ({
  fab: {
    position: 'fixed',
    right: '15px',
    bottom: '40px',
    zIndex: '2000'
  },
  card: {
    height: 'auto',
    width: '100%',
    paddingTop: 10
  },
  containerTable: {
    display: 'flex',
    height: 'calc(100% - 120px)',
    width: '100%',
    flexDirection: 'column'
  },
  underline: {
    '&:hover:before': {
      borderBottomColor: ['#EA6909', '!important']
    },
    '&input:disabled': {
      cursor: 'pointer'
    }
  },
  iconButton: {
    height: 30,
    width: 30,
    color: 'rgba(0, 0, 0, 0.6)'
  },
  button: {
    width: 35,
    minWidth: 10,
    marginTop: 1,
    height: 30,
    padding: 0,
    marginRight: 10
  },
  cardTitle: {
    padding: 20,
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: 0
  },
  nrProposta: {
    color: '#EA6909',
    fontWeight: 'bold',
    fontSize: 20,
    marginRight: 30
  },
  nrValor: {
    color: '#8F7D73',
    fontWeight: 'bold',
    fontSize: 20
  },
  nmLoja: {
    color: 'rgba(95,76,62,0.54)',
    fontSize: 16
  },
  nmVendedor: {
    color: 'rgba(234,105,9,0.54)',
    fontSize: 16,
    marginLeft: 3
  },
  checkBox: {
    height: 70,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginLeft: -15,
    width: 'fit-content',
    cursor: 'pointer'
  },
  swipeable: {
    width: '100%',
    height: '100%'
  },
  buttonHover: {
    cursor: 'pointer',
    width: 30,
    height: 30,
    marginTop: 20,
    minWidth: '30px !important',
    color: 'rgb(0, 0, 0, 0.6)',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 5,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    }
  }
});

const headProposta = [
  {
    field: 'dsObsItemProposta',
    headerName: 'Descrição',
    col: 12,
    sort: 'asc'
  }
];

function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    if (file.size <= 10000000) {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(file);
    } else {
      reject();
    }
  });
}

class ItensPropostaForm extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const codigo = messagesLinguage['editarItem.cod'];
    const nome = messagesLinguage['editarItem.nome'];
    const campoObrigatorio = messagesLinguage['editarItem.campoObrigatorio'];
    const identificacao = messagesLinguage['editarItem.identificacao'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          codigo,
          nome,
          campoObrigatorio,
          identificacao
        }
      },
      {}
    );
    provider = intlProvider.getChildContext();

    this.state = {
      cod: '',
      nome: '',
      stItemProposta: '',
      situacao: 1,
      dtPrevisaoEntrega: null,
      ambienteList: [],
      acabamentoList: [],
      projetoExecutivo: { dsProjetoExecutivo: '', stProjetoExecutivo: 0, dsS3: '', dsArquivoBase64: '' },
      ambiente: '',
      acabamento: '',
      keyAutoCompleteAmbiente: '',
      keyAutoCompleteAcabamento: '',
      vlItem: undefined,
      dataTableKey: '',
      selected: undefined,
      vlTotal: 0,
      dsVlTotal: this.formatToBrl(0),
      isEditing: false,
      index: 0,
      dataTableList: [],
      dataTableObservacao: [],
      cardTabIndex: 0,
      nrDiasAcabamento: new Date(),
      observacaoItem: '',
      valueObservacao: '',
      selectedObservacao: undefined
    };

    this.inputFilesRef = null;

    this.cancelarEdicao = this.cancelarEdicao.bind(this);
    this.onPressed = this.onPressed.bind(this);
    this.handleCancelar = this.handleCancelar.bind(this);
    this.mensagemItemEditadoComSucesso = this.mensagemItemEditadoComSucesso.bind(this);

    this.mensagemItemSalvoComSucesso = this.mensagemItemSalvoComSucesso.bind(this);
    this.loadAmbientes = this.loadAmbientes.bind(this);
    this.loadAcabamentos = this.loadAcabamentos.bind(this);
  }

  mensagemItemEditadoComSucesso() {
    swal('Item da Proposta editado com sucesso', {
      icon: 'success',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-Ok'
        }
      }
    }).then(() => {
      this.cancelarEdicao();
    });
  }

  mensagemItemSalvoComSucesso() {
    swal('Item da Proposta salvo com sucesso', {
      icon: 'success',
      confirmButtonColor: '#8CD4F5',
      buttons: {
        confirm: {
          confirmButtonColor: '#8CD4F5',
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-Ok'
        }
      }
    }).then(() => {
      this.cancelarEdicao();
    });
  }
  handleCancelar() {
    SwalConfirmacao({
      text: 'Tem certeza que deseja cancelar esta edição?'
    }).then(res => {
      if (res) {
        this.props.handleChangeIndex(0);
      }
    });
  }
  async loadAmbientes() {
    await getAllAmbientes().then(res => {
      this.setState({ ambienteList: res.data.filter(item => item.stAmbiente === 1) });
    });
  }
  async loadAcabamentos() {
    await getAllAcabamentos().then(res => {
      this.setState({ acabamentoList: res.data.filter(item => item.stAcabamento === 1) });
    });
  }

  onChangeAutocompleteAmbiente = value => {
    let errors = this.errors;
    this.reinitialize = false;
    this.setFieldValue && this.setFieldValue('ambiente', value);
    this.errors = errors;
  };
  onChangeAutocompleteAcabamento = value => {
    let errors = this.errors;
    this.reinitialize = false;

    this.setFieldValue && this.setFieldValue('acabamento', value);

    if (value) {
      this.setFieldValue && this.setFieldValue('dtPrevisaoEntrega', moment().add('days', value.nrDias));
      let nrDiasAcabamento = new Date();
      nrDiasAcabamento.setDate(nrDiasAcabamento.getDate() + value.nrDias);
      this.setState({
        nrDiasAcabamento: nrDiasAcabamento
      });
    } else {
      this.setState({
        nrDiasAcabamento: new Date()
      });
    }

    this.errors = errors;
  };

  formatToBrl = value => {
    return formatCurrency(parseFloat(value));
  };

  onPressed(values, { resetForm }) {
    this.setState({ index: 0 });
    var item = this.state.selected;
    var list = [...this.state.dataTableList];
    var valorTotal = this.state.vlTotal;
    var dsValorTotal = this.state.dsVlTotal;
    const projetoExecutivo = this.state.projetoExecutivo.dsS3
      ? {
          ...this.state.projetoExecutivo,
          dsProjetoExecutivo: values.dsProjetoExecutivo,
          stProjetoExecutivo: values.stProjetoExecutivo
        }
      : null;
    const data = {
      ambiente: values.ambiente,
      acabamento: values.acabamento,
      nmAmbiente: values.ambiente.nmAmbiente,
      nmAcabamento: values.acabamento.nmAcabamento,
      vlItem: parseFloat(values.vlItem),
      dsValor: this.formatToBrl(values.vlItem),
      stItemProposta: values.stItemProposta,
      dtPrevisaoEntrega: moment(values.dtPrevisaoEntrega).format('YYYY-MM-DD'),
      obsItemPropostaList: this.state.dataTableObservacao,
      projetoExecutivo
    };

    if (item !== undefined) {
      var index = list.indexOf(item);
      dsValorTotal = this.formatToBrl(valorTotal + (data.vlItem - item.vlItem));
      valorTotal = valorTotal + (data.vlItem - item.vlItem);
      list[index] = data;
    } else {
      dsValorTotal = this.formatToBrl(valorTotal + data.vlItem);
      valorTotal = valorTotal + data.vlItem;
      list.push(data);
    }
    this.props.onChangeList(list, {
      valorTotal: valorTotal,
      isMounting: undefined
    });

    resetForm({
      ambiente: '',
      acabamento: '',
      vlItem: '',
      stItemProposta: '',
      dtPrevisaoEntrega: null,
      dsProjetoExecutivo: '',
      stProjetoExecutivo: 0
    });
    this.setState(
      {
        dataTableObservacao: [],
        ambiente: '',
        acabamento: '',
        vlItem: '',
        stItemProposta: '',
        dtPrevisaoEntrega: null,
        selected: undefined,
        dsVlTotal: dsValorTotal,
        vlTotal: valorTotal,
        isEditing: false,
        projetoExecutivo: {
          dsProjetoExecutivo: '',
          stProjetoExecutivo: 0,
          dsS3: '',
          dsArquivoBase64: ''
        },
        index: 0,
        cardTabIndex: 0
      },
      () => {
        this.updateKeys();
      }
    );
  }
  cancelarEdicao() {
    this.props.handleChangeIndex(0);
  }

  updateKeys = () => {
    this.setState({
      keyAutoCompleteAmbiente: Math.random(),
      keyAutoCompleteAcabamento: Math.random(),
      dataTableKey: Math.random()
    });
  };

  clearFields = () => {
    this.setState(
      {
        dataTableObservacao: [],
        ambiente: '',
        acabamento: '',
        vlItem: '',
        stItemProposta: '',
        dtPrevisaoEntrega: null,
        isEditing: false,
        selected: undefined,
        projetoExecutivo: {
          dsProjetoExecutivo: '',
          stProjetoExecutivo: 0,
          dsS3: '',
          dsArquivoBase64: ''
        }
      },
      () => {
        this.updateKeys();
      }
    );
  };

  getAmbiente = idAmbiente => {
    return this.state.ambienteList.find(ambiente => ambiente.idAmbiente === idAmbiente);
  };

  getAcabamento = idAcabamento => {
    return this.state.acabamentoList.find(acabamento => acabamento.idAcabamento === idAcabamento);
  };
  async componentDidMount() {
    this._isMounted = true;
    await this.loadAmbientes();
    await this.loadAcabamentos();

    this.setState(
      { dsVlTotal: this.formatToBrl(this.props.valorTotal), vlTotal: this.props.valorTotal },
      () => this.updateKeys()
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.index !== this.state.index) {
      this.props.onChangeIndexView(this.state.index);
    }

    if (JSON.stringify(prevProps.listFromApi) !== JSON.stringify(this.props.listFromApi)) {
      this.setState({
        dsVlTotal: this.formatToBrl(this.props.valorTotal),
        vlTotal: this.props.valorTotal,
        dataTableKey: Math.random() * 999,
        dataTableList: this.props.listFromApi.map(item => {
          var ambiente = this.getAmbiente(item.ambiente.idAmbiente) || item.ambiente;
          var acabamento = this.getAcabamento(item.acabamento.idAcabamento) || item.acabamento;

          return {
            ambiente: ambiente,
            acabamento: acabamento,
            nmAmbiente: ambiente.nmAmbiente,
            nmAcabamento: acabamento.nmAcabamento,
            vlItem: parseFloat(item.vlItem),
            dsValor: this.formatToBrl(item.vlItem),
            stItemProposta: item.stItemProposta,
            dtPrevisaoEntrega: item.dtPrevisaoEntrega,
            obsItemPropostaList: item.obsItemPropostaList,
            projetoExecutivo: item.projetoExecutivo ? { ...item.projetoExecutivo } : null
          };
        })
      });
    }
  }

  onClickInputProjetoExecutivoFile = async () => {
    if (this.state.projetoExecutivo.dsS3 && !this.state.projetoExecutivo.dsArquivoBase64) {
      const res = await fetch(this.state.projetoExecutivo.dsS3);
      const data = await res.blob();

      const anchor = document.createElement('a');
      anchor.href = window.URL.createObjectURL(data);
      anchor.download = this.state.projetoExecutivo.dsS3
        .split(process.env.REACT_APP_URL_IMAGES)
        .reverse()[0];
      anchor.click();
      return;
    }

    this.inputFilesRef.click();
  };

  handleEdit = () => {
    const data = this.state.selected;
    let nrDiasAcabamento = new Date();
    nrDiasAcabamento.setDate(nrDiasAcabamento.getDate() + data.acabamento.nrDias);

    this.setState(
      {
        ambiente: data.ambiente,
        acabamento: data.acabamento,
        vlItem: data.vlItem,
        stItemProposta: data.stItemProposta,
        isEditing: true,
        dtPrevisaoEntrega: data.dtPrevisaoEntrega,
        projetoExecutivo: { ...data.projetoExecutivo },
        index: 1,
        nrDiasAcabamento: nrDiasAcabamento,
        dataTableObservacao: [...data.obsItemPropostaList]
      },
      () => {
        this.updateKeys();
      }
    );
  };
  handleDelete = () => {
    SwalConfirmacao({
      text: 'Tem certeza que deseja remover este item?'
    }).then(res => {
      if (res) {
        let data = this.state.dataTableList;
        let index = data.indexOf(this.state.selected);
        if (index > -1) {
          var vlItem = this.state.vlTotal - data[index].vlItem;
          data.splice(index, 1);
          data = [...data];

          this._isMounted && this.props.onChangeList(data, { valorTotal: vlItem });

          this.setState(
            {
              selected: undefined,
              vlTotal: vlItem,
              dsVlTotal: this.formatToBrl(vlItem)
            },
            () => {
              this.updateKeys();
            }
          );
        }
      }
    });
  };

  onAddFiles = async (e, files) => {
    if (files.length === 0) return;

    return await fileToBase64(files[0])
      .then(dsArquivoBase64 => {
        this.setState({
          projetoExecutivo: {
            ...this.state.projetoExecutivo,
            dsArquivoBase64: dsArquivoBase64.split(';base64,')[1],
            dsS3: files[0].name
          }
        });
      })
      .catch(err => {
        this.setState({
          projetoExecutivo: {
            ...this.state.projetoExecutivo,
            dsArquivoBase64: '',
            dsS3: ''
          }
        });

        swal('O arquivo não pode ser maior que 10MB', {
          icon: 'error',
          confirmButtonColor: '#8CD4F5',
          buttons: {
            confirm: {
              confirmButtonColor: '#8CD4F5',
              text: 'Ok',
              value: true,
              visible: true,
              closeModal: true,
              className: 'swal2-Ok'
            }
          }
        });
      });
  };

  render() {
    const { classes } = this.props;
    const pagination = {
      length: this.props.list.length,
      page: 0
    };

    return (
      <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
        <div style={{ width: '100%', height: '100%' }}>
          <Formik
            initialValues={{
              dtPrevisaoEntrega: this.state.dtPrevisaoEntrega,
              ambiente: this.state.ambiente,
              acabamento: this.state.acabamento,
              vlItem: this.state.vlItem,
              stItemProposta: this.state.stItemProposta,
              dsProjetoExecutivo: this.state.projetoExecutivo.dsProjetoExecutivo,
              stProjetoExecutivo: this.state.projetoExecutivo.stProjetoExecutivo
            }}
            onSubmit={this.onPressed}
            validateOnBlur
            enableReinitialize={true}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              dtPrevisaoEntrega: Yup.date().required('Campo Obrigatório'),
              stItemProposta: Yup.string().required('Campo obrigatório'),
              ambiente: Yup.object()
                .nullable()
                .required(<FormattedMessage id='editarItem.campoObrigatorio' />),
              acabamento: Yup.object()
                .nullable()
                .required(<FormattedMessage id='editarItem.campoObrigatorio' />),
              vlItem: Yup.string().required(<FormattedMessage id='editarItem.campoObrigatorio' />),
              dsProjetoExecutivo: this.state.projetoExecutivo.dsS3
                ? Yup.string().required(<FormattedMessage id='editarItem.campoObrigatorio' />)
                : Yup.string()
            })}
            render={({ values, handleSubmit, errors, touched, handleBlur, setFieldValue }) => {
              this.values = { ...values };
              this.setFieldValue = setFieldValue;
              this.errors = errors;

              return (
                <MuiThemeProvider
                  theme={createMuiTheme({
                    palette: {
                      primary: {
                        main: '#EA6909'
                      }
                    },
                    typography: {
                      useNextVariants: true
                    }
                  })}
                >
                  <div style={{ width: '100%', height: '100%' }}>
                    <Card className={classes.cardTitle}>
                      <div>
                        <span className={classes.nrProposta}>{this.props.nrProposta}</span>

                        <Fade in={this.state.vlTotal > 0}>
                          <span className={classes.nrValor}>{this.state.dsVlTotal}</span>
                        </Fade>
                      </div>
                      <Fade in={this.props.cliente.length > 0}>
                        <span className={classes.nrProposta}>{this.props.cliente}</span>
                      </Fade>
                    </Card>

                    <SwipeableViews
                      className={classes.swipeable}
                      index={this.state.index}
                      onChangeIndex={index =>
                        this.setState({ index }, () => this.props.onChangeIndexView(index))
                      }
                      ignoreNativeScroll={true}
                      containerStyle={{ height: '100%' }}
                      slideStyle={{ overflow: 'hidden' }}
                    >
                      {[
                        <>
                          <GroupButtonGrid
                            showAdd={true}
                            showEdit={true}
                            showDelete={true}
                            disableDelete={
                              this.state.selected === undefined ||
                              this.state.isEditing ||
                              this.props.stProposta === 2
                            }
                            disableEdit={
                              this.state.selected === undefined ||
                              this.state.isEditing ||
                              this.props.stProposta === 2
                            }
                            disableAdd={this.state.isEditing || this.props.stProposta === 2}
                            onClickDelete={this.handleDelete}
                            onClickEdit={this.handleEdit}
                            onClickAdd={() => {
                              this.clearFields();
                              this.setState({ index: 1 });
                            }}
                            deleteColor='#5F4C3E'
                            editColor='#5F4C3E'
                            addColor='#5F4C3E'
                          />
                          <div className={classes.containerTable}>
                            <DataTable
                              key={this.state.dataTableKey}
                              marginTop
                              data={this.state.dataTableList}
                              columns={head}
                              userlang={getUserLang()}
                              selectChange={selected => this.setState({ selected })}
                              showPagination={true}
                              pagination={pagination}
                              handleChangePage={() => {}}
                              handleChangeRowsPerPage={() => {}}
                            />
                          </div>
                        </>,
                        <CardWithTabs
                          title='Item da proposta'
                          withoutHidden
                          containerSwipeableStyles={{ overflow: 'inherit' }}
                          styleContainer={{ marginTop: 15 }}
                          selectedIndex={this.state.cardTabIndex}
                          onChangeTabIndex={(e, cardTabIndex) => this.setState({ cardTabIndex })}
                          cardProps={{
                            customPadding: 15
                          }}
                          tabs={[
                            {
                              label: 'Identificação',
                              component: (
                                <div
                                  style={{
                                    width: '100%',
                                    marginBottom: -15
                                  }}
                                >
                                  <Grid container style={{ height: 70 }}>
                                    <Grid item xs={4} style={{ paddingRight: 15 }}>
                                      <AutoComplete
                                        itens={this.state.ambienteList}
                                        campoOp='nmAmbiente'
                                        campoChave='idAmbiente'
                                        label='Ambiente *'
                                        name='ambiente'
                                        id='ambiente'
                                        error={errors.ambiente && touched.ambiente}
                                        helperText={
                                          errors.ambiente && touched.ambiente && errors.ambiente
                                        }
                                        value={values.ambiente}
                                        valueAutoComplete={values.ambiente}
                                        onChange={this.onChangeAutocompleteAmbiente}
                                        key={this.state.keyAutoCompleteAmbiente}
                                        maxHeight='calc(100vh - 200px)'
                                      />
                                    </Grid>

                                    <Grid item xs={4} style={{ paddingRight: 15 }}>
                                      <AutoComplete
                                        itens={this.state.acabamentoList}
                                        value={values.acabamento}
                                        campoOp='nmAcabamento'
                                        valueAutoComplete={values.acabamento}
                                        campoChave='idAcabamento'
                                        label='Acabamento*'
                                        name='acabamento'
                                        id='acabamento'
                                        error={errors.acabamento && touched.acabamento}
                                        helperText={
                                          errors.acabamento && touched.acabamento && errors.acabamento
                                        }
                                        onChange={this.onChangeAutocompleteAcabamento}
                                        key={this.state.keyAutoCompleteAcabamento}
                                        maxHeight='calc(100vh - 200px)'
                                      />
                                    </Grid>

                                    <Grid item xs={2}>
                                      <MaskedTextField
                                        label='Valor (R$) *'
                                        tipoMascara='dinheiro'
                                        isAllowed={value =>
                                          value.formattedValue.length === 0 ||
                                          (value.floatValue >= 1 && value.floatValue <= 9999999.99)
                                        }
                                        userlang='pt-BR'
                                        InputProps={{
                                          classes: { underline: classes.underline }
                                        }}
                                        id='vlItem'
                                        value={values.vlItem}
                                        onChange={e => setFieldValue('vlItem', '' + e.target.value)}
                                        onBlur={handleBlur}
                                        error={errors.vlItem && touched.vlItem}
                                        helperText={
                                          errors.vlItem &&
                                          touched.vlItem &&
                                          provider.intl.formatMessage({
                                            id: 'campoObrigatorio'
                                          })
                                        }
                                      />
                                    </Grid>
                                  </Grid>

                                  <Grid container style={{ height: 60 }}>
                                    <Grid item xs={2} style={{ paddingRight: 15 }}>
                                      <DatePicker
                                        userlang='pt-BR'
                                        label='Prev. entrega *'
                                        value={values.dtPrevisaoEntrega}
                                        minDate={this.state.nrDiasAcabamento}
                                        onChange={date => {
                                          if (date !== null) {
                                            setFieldValue('dtPrevisaoEntrega', date._d);
                                          } else {
                                            setFieldValue('dtPrevisaoEntrega', null);
                                          }
                                        }}
                                        errorTouched={
                                          errors.dtPrevisaoEntrega && touched.dtPrevisaoEntrega
                                        }
                                        helperText={
                                          errors.dtPrevisaoEntrega && touched.dtPrevisaoEntrega
                                            ? 'Campo obrigatório'
                                            : ''
                                        }
                                        onBlur={e => {
                                          if (e.target.value.length === 0) {
                                            handleBlur('dtPrevisaoEntrega')(e);
                                            return;
                                          }

                                          let schema = Yup.date();
                                          let validation = schema.isValidSync(values.dtPrevisaoEntrega);

                                          if (!validation) {
                                            setFieldValue('dtPrevisaoEntrega', null);
                                          }
                                          handleBlur('dtPrevisaoEntrega')(e);
                                        }}
                                      />
                                    </Grid>

                                    <Grid item xs={2} style={{ paddingRight: 15 }}>
                                      <FormControl
                                        fullWidth
                                        style={{ marginTop: -2 }}
                                        className={classes.formControl}
                                        error={errors.stItemProposta && touched.stItemProposta}
                                        disabled={this.props.stContrato >= 2}
                                      >
                                        <InputLabel style={{ fontSize: 14 }} htmlFor='statusProposta'>
                                          Situação *
                                        </InputLabel>
                                        <Select
                                          SelectDisplayProps={{
                                            style: { backgroundColor: 'transparent' }
                                          }}
                                          input={<Input id='statusProposta' />}
                                          value={values.stItemProposta}
                                          name='stItemProposta'
                                          onChange={e => {
                                            setFieldValue('stItemProposta', e.target.value);
                                          }}
                                          onBlur={handleBlur}
                                          error={errors.stItemProposta && touched.stItemProposta}
                                        >
                                          <MenuItem value={0}>Aguardando contratação</MenuItem>
                                          <MenuItem value={1}>Contratado</MenuItem>
                                          <MenuItem value={2}>Não contratado</MenuItem>
                                        </Select>
                                        <FormHelperText>
                                          {errors.stItemProposta && touched.stItemProposta
                                            ? errors.stItemProposta
                                            : null}
                                        </FormHelperText>
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                </div>
                              )
                            },
                            {
                              label: 'Descrição',
                              component: (
                                <>
                                  <Grid container style={{ height: 60 }}>
                                    <Grid item xs={4} style={{ paddingRight: 15, zIndex: 1 }}>
                                      <AutoComplete
                                        itens={this.props.obsItemPropostaList}
                                        campoOp='dsObservacao'
                                        value={null}
                                        valueAutoComplete={null}
                                        valueSelect={null}
                                        campoChave='dsObservacao'
                                        label='Descrição'
                                        name='observacao'
                                        id='observacao'
                                        valueLabel={this.state.valueObservacao}
                                        onChange={item => {
                                          if (item) {
                                            this.setState({
                                              valueObservacao: item['dsObservacao']
                                            });
                                          }
                                        }}
                                        onChangeAutoComplete={item => {
                                          this.setState({
                                            valueObservacao: item.target.value
                                          });
                                        }}
                                        withoutClear={true}
                                        maxHeight='calc(100vh - 200px)'
                                      />
                                    </Grid>

                                    <Grid item xs={1} style={{ paddingRight: 15, zIndex: 1 }}>
                                      <div
                                        onClick={() => {
                                          if (!this.state.valueObservacao) return;
                                          let dataTableObservacao = this.state.dataTableObservacao;
                                          dataTableObservacao.push({
                                            dsObsItemProposta: this.state.valueObservacao,
                                            idObservacao: Math.random()
                                          });

                                          this.setState({
                                            dataTableKey: Math.random(),
                                            valueObservacao: ''
                                          });
                                        }}
                                        className={classes.buttonHover}
                                      >
                                        <Add />
                                      </div>
                                    </Grid>
                                  </Grid>
                                  <div
                                    style={{
                                      minHeight: 225,
                                      height: 'calc(100vh - 455px)',
                                      marginTop: -13
                                    }}
                                  >
                                    <GroupButtonGrid
                                      showAdd={false}
                                      showEdit={false}
                                      showDelete={true}
                                      disableDelete={!this.state.selectedObservacao}
                                      disableAdd={true}
                                      onClickDelete={() => {
                                        SwalConfirmacao({
                                          text: 'Tem certeza que deseja remover este item?'
                                        }).then(res => {
                                          if (res) {
                                            const item = this.state.dataTableObservacao.find(
                                              doc =>
                                                this.state.selectedObservacao.idObservacao ===
                                                doc.idObservacao
                                            );

                                            if (item) {
                                              const position = this.state.dataTableObservacao.indexOf(
                                                item
                                              );
                                              this.state.dataTableObservacao.splice(position, 1);

                                              this.setState({
                                                dataTableKey: Math.random()
                                              });

                                              SwalMessage({
                                                icon: 'success',
                                                title: 'Sucesso',
                                                text: 'Descrição removido com sucesso',
                                                callback: () => {
                                                  this.forceUpdate();
                                                }
                                              });
                                            }
                                          }
                                        });
                                      }}
                                      onClickEdit={this.handleEdit}
                                      deleteColor='#5F4C3E'
                                    />
                                    <div
                                      style={{
                                        width: 'calc(100% - 2px)',
                                        height: 'calc(100% - 38px)',
                                        marginLeft: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        borderTop: '1px solid rgba(0,0,0,0.2)'
                                      }}
                                    >
                                      <DataTable
                                        noPaddingHorizontal={true}
                                        borderRadius={true}
                                        noBox={true}
                                        noMargin={true}
                                        key={this.state.dataTableKey}
                                        marginTop
                                        data={this.state.dataTableObservacao}
                                        columns={headProposta}
                                        userlang={getUserLang()}
                                        selectChange={selectedObservacao =>
                                          this.setState({ selectedObservacao })
                                        }
                                        showPagination={false}
                                        handleChangePage={() => {}}
                                        handleChangeRowsPerPage={() => {}}
                                      />
                                    </div>
                                  </div>
                                </>
                              )
                            },
                            {
                              label: 'Projeto executivo',
                              component: (
                                <Grid container style={{ minHeight: 70 }}>
                                  <Grid item xs={6} style={{ paddingRight: 15 }}>
                                    <TextField
                                      InputProps={{
                                        classes: { underline: classes.underline }
                                      }}
                                      id='dsProjetoExecutivo'
                                      label='Descrição'
                                      value={values.dsProjetoExecutivo}
                                      onChange={value => {
                                        setFieldValue('dsProjetoExecutivo', value.target.value);
                                      }}
                                      error={errors.dsProjetoExecutivo && touched.dsProjetoExecutivo}
                                      helperText={
                                        errors.dsProjetoExecutivo &&
                                        touched.dsProjetoExecutivo &&
                                        provider.intl.formatMessage({
                                          id: 'campoObrigatorio'
                                        })
                                      }
                                    />
                                  </Grid>

                                  <Grid
                                    item
                                    xs={4}
                                    style={{ paddingRight: 15, height: 70, overflow: 'hidden' }}
                                    onDrop={e => this.onAddFiles(e, e.dataTransfer.files)}
                                    onDragOver={e => e.preventDefault()}
                                  >
                                    <input
                                      style={{ display: 'none' }}
                                      type='file'
                                      ref={ref => (this.inputFilesRef = ref)}
                                      onChange={e => this.onAddFiles(e, e.target.files)}
                                    />

                                    <TextField
                                      name='dsS3'
                                      onChange={() => {}}
                                      onBlur={handleBlur}
                                      value={
                                        (this.state.projetoExecutivo.dsS3 || '')
                                          .split(process.env.REACT_APP_URL_IMAGES)
                                          .reverse()[0]
                                      }
                                      label='Arquivo do projeto'
                                      readOnly
                                      InputProps={{
                                        classes: { underline: classes.underline },
                                        onClick: this.onClickInputProjetoExecutivoFile,
                                        endAdornment: (
                                          <InputAdornment position='end'>
                                            <Button color='primary' style={{ minWidth: 36 }}>
                                              <Tooltip
                                                title={
                                                  this.state.projetoExecutivo.dsS3 &&
                                                  !this.state.projetoExecutivo.dsArquivoBase64
                                                    ? 'Download do arquivo'
                                                    : 'Selecione um arquivo'
                                                }
                                              >
                                                {this.state.projetoExecutivo.dsS3 &&
                                                !this.state.projetoExecutivo.dsArquivoBase64 ? (
                                                  <ArrowDownward />
                                                ) : (
                                                  <IconeFile />
                                                )}
                                              </Tooltip>
                                            </Button>
                                          </InputAdornment>
                                        )
                                      }}
                                    />

                                    <div
                                      onClick={() => this.inputFilesRef.click()}
                                      style={{
                                        position: 'relative',
                                        top: -70,
                                        width: 'calc(100% - 40px)',
                                        height: 70,
                                        cursor: 'pointer',
                                        backgroundColor: 'transparent'
                                      }}
                                    />
                                  </Grid>

                                  {this.state.isEditing && (
                                    <Grid item xs={1} style={{ paddingRight: 15, paddingLeft: 5 }}>
                                      <div
                                        onClick={() =>
                                          setFieldValue(
                                            'stProjetoExecutivo',
                                            +values.stProjetoExecutivo === 1 ? 0 : 1
                                          )
                                        }
                                        className={classes.checkBox}
                                      >
                                        <div>
                                          <Checkbox
                                            checked={+values.stProjetoExecutivo === 1}
                                            value='checkedB'
                                            color='primary'
                                          />
                                          <label style={{ color: '#000000', cursor: 'pointer' }}>
                                            Aprovado
                                          </label>
                                        </div>
                                      </div>
                                    </Grid>
                                  )}
                                </Grid>
                              )
                            }
                          ]}
                          componentBelow={
                            <Grid
                              container
                              style={{
                                borderTop: '1px solid rgb(0, 0, 0, 0.1)',
                                marginTop: 30,
                                paddingTop: 15
                              }}
                            >
                              <Grid item xs />

                              <Grid
                                item
                                xs={3}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'flex-end'
                                }}
                              >
                                <Button
                                  style={{
                                    marginRight: 10,
                                    backgroundColor: '#5F4C3E',
                                    color: 'white'
                                  }}
                                  onClick={() => {
                                    this.clearFields();
                                    this.setState({ index: 0, cardTabIndex: 0 });
                                  }}
                                >
                                  Cancelar
                                </Button>

                                <Button
                                  style={{ color: 'white', backgroundColor: '#EA6909' }}
                                  onClick={handleSubmit}
                                >
                                  Salvar
                                </Button>
                              </Grid>
                            </Grid>
                          }
                        />
                      ]}
                    </SwipeableViews>
                  </div>
                </MuiThemeProvider>
              );
            }}
          />
        </div>
      </IntlProvider>
    );
  }
}
const mapStateToProps = state => ({
  UsuarioLogado: state.usuarioReducer,
  itensState: state.toolbar,
  menuState: state.menuOpen
});

let enhanced = withStyles(style)(ItensPropostaForm);
export default connect(mapStateToProps)(withRouter(enhanced));
