/*
    Autor: Bruno Eduardo
*/
import React from 'react';
import swal from '@sweetalert/with-react';
import './styles/SwalConfirmacao.css';
import classNames from 'classnames';
import getIntlProvider from './i18n/getIntlProvider';
import {
  containerSwalBodyStyle,
  tituloSwalBodyStyle,
  textSwalBodyStyle
} from './styles/styles';

const SwalConfirmacao = props => {
  props = props || {};
  const provider = getIntlProvider();

  const {
    text,
    titulo = provider.intl.formatMessage({ id: 'atencao' }),
    textConfirm,
    textCancel,
    inverteButtonsColors,
    classNameBtnConfirm,
    classNameBtnCancel,
    containerMsgStyle,
    config
  } = props;

  const swalBody = (
    <div style={{ ...containerSwalBodyStyle, ...containerMsgStyle }}>
      <span style={tituloSwalBodyStyle}>{titulo}</span>
      <div style={textSwalBodyStyle}>{text}</div>
    </div>
  );

  const swalConfig = {
    content: swalBody,
    className: 'swal-stretched',
    dangerMode: true,
    buttons: {
      cancel: {
        text: textCancel || provider.intl.formatMessage({ id: 'cancelar' }),
        value: false,
        closeModal: true,
        visible: true,
        className: classNames(
          inverteButtonsColors ? 'swal-botao-cancel-red' : 'swal-botao-cancel',
          classNameBtnCancel,
          {
            backgroundColor: 'transparent'
          }
        )
      },
      confirm: {
        text: textConfirm || provider.intl.formatMessage({ id: 'confirmar' }),
        value: true,
        className: classNames(
          inverteButtonsColors
            ? 'swal-botao-confirm-green'
            : 'swal-botao-confirm',
          classNameBtnConfirm
        )
      }
    },
    ...config
  };

  return swal(swalConfig);
};

export default SwalConfirmacao;
