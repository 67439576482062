const { getAll } = require('@resources/api/loja')
const getLojas = list => {
  return async dispatch => {
    if (list.length > 0) {
      return list
    }
    await getAll().then(res => {
      list = res.data
      dispatch({
        type: 'ADD_LOJAS',
        list: list,
      })
    })

    return list
  }
}
const refreshList = list => {
  return async dispatch => {
    await getAll().then(res => {
      list = res.data
      dispatch({
        type: 'ADD_LOJAS',
        list: list,
      })
    })

    return list
  }
}

export { getLojas, refreshList }
