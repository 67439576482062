import axios from 'axios'

const BASEAPI = process.env.REACT_APP_URL_API
const ENDPOINT = '/perfil'

const getPerfil = async () => {
  return await axios.get(`${BASEAPI + ENDPOINT}`)
}

export { getPerfil }
