import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import flattenMessages from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withStyles } from '@material-ui/core/styles';
import ptBR from './i18n/ptBR';
import CardIcon from '@components/CardIcon/CardIcon';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import SwalConfirmacao from '@utils/functions/SwalConfirmacao/SwalConfirmacao';
import FabDiamondMenu from '@components/FabDiamondMenu/FabDiamondMenu';
import { TextField, MaskedTextField, AutoComplete } from '@kepha/sumora-react-components';
import swal from '@sweetalert/with-react';
import Grid from '@material-ui/core/Grid';
import { insert, update } from '@resources/api/loja';
import CircularProgress from '@material-ui/core/CircularProgress';
import SwalMessage from '@utils/functions/SwalMessage/SwalMessage';
import { getMunicipioById } from '@resources/api/municipio';
import { getAllEstados } from '@resources/api/estado';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { refreshList } from '@root/actions/lojaActions';
import OnExitConfirmation from '@components/OnExitConfirmation/OnExitConfirmation';
import CNPJValidator from '@validators/CNPJValidator';
let messages = { 'pt-BR': ptBR };
let { provider } = new IntlProvider({});

const style = () => ({
  fab: {
    position: 'fixed',
    right: '15px',
    bottom: '40px',
    zIndex: '2000'
  },
  card: {
    height: 360,
    width: '100%',
    marginTop: '30px'
  },
  underline: {
    '&:after': {
      borderBottomColor: ['#EA6909', '!important']
    },
    '&:hover:before': {
      borderBottomColor: ['#EA6909', '!important'],
      borderBottomWidth: ['1px', '!important']
    }
  },
  closeButton: {
    position: 'absolute',
    right: 20,
    paddingBottom: '8px',
    height: '40px',
    width: '40px'
  }
});

class LojaForm extends Component {
  constructor(props) {
    super(props);

    const messagesLanguage = flattenMessages(messages[getUserLang()]);
    const nome = messagesLanguage['form.nome'];
    const campoObrigatorio = messagesLanguage['form.campoObrigatorio'];
    const identificacao = messagesLanguage['form.identificacao'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          nome,
          campoObrigatorio,
          identificacao
        }
      },
      {}
    );
    provider = intlProvider.getChildContext();

    this.initialValues = {
      nome: '',
      cidade: '',
      whatsapp: '',
      telefone: '',
      email: '',
      cep: '',
      endereco: '',
      estado: '',
      situacao: 1,
      codigo: '',
      nrCnpj: undefined,
      nrIe: '',
      canLeave: false
    };

    this.state = {
      estadoList: [],
      municipioList: [],
      keyAutoCompleteEstado: '',
      keyAutoCompleteCidade: '',
      loja: { ...this.initialValues }
    };
    let title;
    if (this.props.match.params.id === undefined) {
      title = 'CADASTRAR LOJA';
    } else {
      title = 'EDITAR LOJA';
    }

    if (this.props.itensState.name !== title) {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: title,
        name: title
      });
    }

    this.cancelarEdicao = this.cancelarEdicao.bind(this);
    this.tratarSituacao = this.tratarSituacao.bind(this);
    this.onPressed = this.onPressed.bind(this);

    this.mensagemLojaEditadoComSucesso = this.mensagemLojaEditadoComSucesso.bind(this);
    this.mensagemErroAoEditarLoja = this.mensagemErroAoEditarLoja.bind(this);
    this.mensagemProblemaInesperado = this.mensagemProblemaInesperado.bind(this);
    this.mensagemLojaSalvoComSucesso = this.mensagemLojaSalvoComSucesso.bind(this);
    this.mensagemErroAoSalvarLoja = this.mensagemErroAoSalvarLoja.bind(this);
    this.onChangeAutocompleteEstado = this.onChangeAutocompleteEstado.bind(this);
    this.onChangeAutocompleteCidade = this.onChangeAutocompleteCidade.bind(this);
    this.tratarEstado = this.tratarEstado.bind(this);
    this.tratarMunicipio = this.tratarMunicipio.bind(this);
    this.handleCancelar = this.handleCancelar.bind(this);
    this.cancelarEdicao = this.cancelarEdicao.bind(this);
    this.getAllEstados = this.getAllEstados.bind(this);
    this.loadSuggestionsAutocompleteMunicipios = this.loadSuggestionsAutocompleteMunicipios.bind(this);
  }

  getAllEstados() {
    getAllEstados().then(res => {
      var data = res.data;

      this.setState({
        estadoList: data
      });
    });
  }
  handleCancelar() {
    SwalConfirmacao({
      text: 'Tem certeza que deseja cancelar esta edição?'
    }).then(res => {
      if (res) {
        this.setState(
          {
            canLeave: true
          },
          () => {
            this.props.history.push('/app/lojas');
          }
        );
      }
    });
  }

  mensagemLojaEditadoComSucesso() {
    swal('Loja editada com sucesso', {
      icon: 'success',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-Ok'
        }
      }
    }).then(() => {
      this.cancelarEdicao();
    });
  }

  mensagemLojaSalvoComSucesso() {
    swal('Loja salva com sucesso', {
      icon: 'success',
      confirmButtonColor: '#8CD4F5',
      buttons: {
        confirm: {
          confirmButtonColor: '#8CD4F5',
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-Ok'
        }
      }
    }).then(() => {
      this.cancelarEdicao();
    });
  }

  mensagemErroAoEditarLoja() {
    swal('Falha ao editar o acabamento', {
      icon: 'error',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-error'
        }
      }
    });
  }

  mensagemErroAoSalvarLoja() {
    swal('Falha ao salvar o acabamento', {
      icon: 'error',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-error'
        }
      }
    });
  }

  mensagemProblemaInesperado() {
    swal('Ocorreu um problema inesperado', {
      icon: 'error',
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          closeModal: true,
          className: 'swal2-error'
        }
      }
    }).then(() => {
      this.cancelarEdicao();
    });
  }

  tratarSituacao(situacao) {
    switch (situacao) {
      case 'Inativo':
        return 0;
      case 'Ativo':
        return 1;
      default:
        break;
    }
  }

  updateLojaList = () => {
    this.props.dispatch(refreshList(this.props.lojaList));
  };

  //Trata a submissão do formulário
  onPressed(values) {
    swal(
      <div>
        <CircularProgress style={{ color: '#EA6909' }} />
      </div>,
      {
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        title: this.props.match.params.id !== undefined ? 'Atualizando' : 'Salvando'
      }
    );
    if (this.props.match.params.id !== undefined && this.props.match.params.id !== null) {
      const data = {
        idLoja: this.props.match.params.id,
        nrCodigo: values.codigo,
        nmLoja: values.nome,
        nrTelefone: values.telefone,
        nrWhatsapp: values.whatsapp,
        nrCep: values.cep,
        idUf: values.estado.idUf,
        idMunicipio: values.cidade.idMunicipio,
        dsLogradouro: values.endereco,
        dsEmail: values.email,
        stLoja: values.situacao,
        nrCnpj: values.nrCnpj,
        nrIe: values.nrIe
      };
      update(data)
        .then(res => {
          this.updateLojaList();
          return this.mensagemLojaEditadoComSucesso();
        })
        .catch(err => {
          if (err.response && err.response.status === 400) {
            swal('Código da loja já existente', {
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-error'
                }
              }
            });
            return;
          }
          return this.mensagemErroAoEditarLoja();
        });
    } else {
      const data = {
        nrCodigo: values.codigo,
        nmLoja: values.nome,
        nrTelefone: values.telefone,
        nrWhatsapp: values.whatsapp,
        nrCep: values.cep,
        idUf: values.estado.idUf,
        idMunicipio: values.cidade.idMunicipio,
        dsLogradouro: values.endereco,
        dsEmail: values.email,
        stLoja: values.situacao,
        nrCnpj: values.nrCnpj,
        nrIe: values.nrIe
      };
      insert(data)
        .then(res => {
          this.updateLojaList();
          return this.mensagemLojaSalvoComSucesso();
        })
        .catch(err => {
          if (err.response && err.response.status === 400) {
            swal('Código da loja já existente', {
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-error'
                }
              }
            });
            return;
          }
          return this.mensagemErroAoSalvarLoja();
        });
    }
  }

  //Volta para a listagem
  cancelarEdicao() {
    this.setState(
      {
        canLeave: true
      },
      () => {
        this.props.history.push('/app/lojas');
      }
    );
  }

  //Em caso de edição, carrega os campos no formulário
  async componentDidMount() {
    var data = this.props.location.state !== undefined ? this.props.location.state.loja : undefined;

    if (data !== undefined) {
      var estado = this.tratarEstado(data.idUf);
      var municipio = await this.tratarMunicipio(data.idUf, data.idMunicipio);
      this.initialValues = {
        nome: data.nmLoja,
        cidade: municipio,
        whatsapp: data.nrWhatsapp,
        telefone: data.nrTelefone,
        email: data.dsEmail,
        cep: data.nrCep,
        endereco: data.dsLogradouro,
        estado: estado,
        situacao: data.stLoja,
        codigo: data.nrCodigo,
        nrCnpj: data.nrCnpj,
        nrIe: data.nrIe.toString()
      };
      this.setState(
        {
          loja: { ...this.initialValues }
        },
        () => {
          this.setState({
            keyAutoCompleteEstado: Math.random(),
            keyAutoCompleteCidade: Math.random()
          });
        }
      );
    }
  }

  // trata quando um valor é selecionado enquanto o usuario digita no AutoComplete de estado
  onChangeAutocompleteEstado(value) {
    let errors = this.errors;
    this.reinitialize = false;
    value != null && this.loadSuggestionsAutocompleteMunicipios(value);
    this.setFieldValue && this.setFieldValue('estado', value);

    this.errors = errors;
  }

  // trata quando um valor é selecionado enquanto o usuario digita no AutoComplete de municipio
  onChangeAutocompleteCidade(value) {
    let errors = this.errors;
    this.reinitialize = false;
    this.setFieldValue && this.setFieldValue('cidade', value);
    this.errors = errors;
  }

  // carrega os dados para colocar no select
  loadSuggestionsAutocompleteMunicipios(estado) {
    getMunicipioById(estado.idUf)
      .then(res => {
        let municipios = res.data.map(item => {
          return {
            idMunicipio: item.idMunicipio,
            idUf: item.idUf,
            nmMunicipio: item.nmMunicipio
          };
        });

        this.setState({
          municipioList: municipios
        });
      })
      .catch(err => {
        SwalMessage({
          title: 'Erro',
          text: 'Erro ao carregar as cidades',
          err
        });
      });
  }

  //Retorna um estado a partir de um idUf
  tratarEstado(id) {
    var estado = this.props.estadoList.find(el => {
      return el.idUf === id;
    });

    return estado;
  }

  //Salva lista de municipios no state e retorna um municipio dado um idMunicipio
  async tratarMunicipio(idEstado, idMunicipio) {
    return await getMunicipioById(idEstado).then(res => {
      let municipios = res.data.map(item => {
        return {
          idMunicipio: item.idMunicipio,
          idUf: item.idUf,
          nmMunicipio: item.nmMunicipio
        };
      });

      this.setState({
        municipioList: municipios
      });
      var municipio = res.data.find(el => {
        return el.idMunicipio === idMunicipio;
      });

      return municipio;
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
        <div style={{ width: '100%', marginTop: 15 }}>
          <Formik
            initialValues={{
              ...this.initialValues
            }}
            onSubmit={this.onPressed}
            validateOnBlur
            enableReinitialize={true}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              nome: Yup.string().required(<FormattedMessage id='form.campoObrigatorio' />),

              cidade: Yup.string().required(<FormattedMessage id='form.campoObrigatorio' />),
              estado: Yup.string().required(<FormattedMessage id='form.campoObrigatorio' />),
              endereco: Yup.string().required(<FormattedMessage id='form.campoObrigatorio' />),
              cep: Yup.string().required(<FormattedMessage id='form.campoObrigatorio' />),
              whatsapp: Yup.string().required(<FormattedMessage id='form.campoObrigatorio' />),
              telefone: Yup.string().required(<FormattedMessage id='form.campoObrigatorio' />),
              email: Yup.string()
                .email('Email inválido')
                .required(<FormattedMessage id='form.campoObrigatorio' />),
              situacao: Yup.string().required(<FormattedMessage id='form.campoObrigatorio' />),
              codigo: Yup.string().required(<FormattedMessage id='form.campoObrigatorio' />),
              nrIe: Yup.string().required(<FormattedMessage id='form.campoObrigatorio' />),
              nrCnpj: Yup.string()
                .test('', 'CPF/CNPJ Inválido', function(value) {
                  if (value !== undefined) {
                    const validation = CNPJValidator.validate(value);

                    return validation;
                  }
                  return true;
                })
                .required(<FormattedMessage id='form.campoObrigatorio' />)
            })}
            render={({
              values,
              handleSubmit,
              errors,
              touched,
              handleBlur,
              handleChange,
              setFieldValue
            }) => {
              this.values = { ...values };
              this.setFieldValue = setFieldValue;
              this.errors = errors;

              return (
                <div>
                  <OnExitConfirmation
                    values={this.values}
                    initialValues={this.initialValues}
                    canLeave={this.state.canLeave}
                  >
                    <CardIcon
                      titulo={provider.intl.formatMessage({
                        id: 'identificacao'
                      })}
                      disableExpandButton={true}
                    >
                      <form autoComplete='off'>
                        <div className={classes.card}>
                          <Grid container spacing={3}>
                            <Grid
                              item
                              xs={3}
                              style={{
                                marginTop: 9
                              }}
                            >
                              <TextField
                                InputProps={{
                                  inputProps: { maxLength: 20 },
                                  classes: { underline: classes.underline }
                                }}
                                id='codigo'
                                label='Código *'
                                value={values.codigo}
                                onChange={e => setFieldValue('codigo', e.target.value)}
                                onBlur={handleBlur}
                                error={errors.codigo && touched.codigo}
                                helperText={
                                  errors.codigo &&
                                  touched.codigo &&
                                  provider.intl.formatMessage({
                                    id: 'campoObrigatorio'
                                  })
                                }
                              />
                            </Grid>
                            <Grid
                              item
                              xs={8}
                              style={{
                                marginTop: 9
                              }}
                            >
                              <TextField
                                InputProps={{
                                  inputProps: { maxLength: 200 },
                                  classes: { underline: classes.underline }
                                }}
                                id='nome'
                                label={provider.intl.formatMessage({
                                  id: 'nome'
                                })}
                                value={values.nome}
                                onChange={e => setFieldValue('nome', e.target.value)}
                                onBlur={handleBlur}
                                error={errors.nome && touched.nome}
                                helperText={
                                  errors.nome &&
                                  touched.nome &&
                                  provider.intl.formatMessage({
                                    id: 'campoObrigatorio'
                                  })
                                }
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <FormControl
                                className={classes.formControl}
                                error={errors.situacao && touched.situacao}
                              >
                                <FormControlLabel
                                  style={{
                                    marginTop: 25,
                                    position: 'absolute'
                                  }}
                                  id='situacao'
                                  name='situacao'
                                  control={
                                    <Checkbox
                                      color='primary'
                                      checked={values.situacao}
                                      onChange={e => {
                                        var situacao = e.target.checked ? 1 : 0;
                                        setFieldValue('situacao', situacao);
                                      }}
                                    />
                                  }
                                  label={
                                    <div
                                      style={{
                                        color: '#757575'
                                      }}
                                    >
                                      Ativo
                                    </div>
                                  }
                                />
                                {errors.situacao && touched.situacao && (
                                  <FormHelperText>
                                    {provider.intl.formatMessage({
                                      id: 'campoObrigatorio'
                                    })}
                                  </FormHelperText>
                                )}{' '}
                              </FormControl>
                            </Grid>

                            <Grid item xs={3} style={{ paddingRight: 15 }}>
                              <MaskedTextField
                                label='CPF/CNPJ *'
                                tipoMascara='cpfCpnj'
                                userlang='pt-BR'
                                InputProps={{
                                  classes: { underline: classes.underline }
                                }}
                                id='nrCnpj'
                                value={values.nrCnpj}
                                onChange={e => setFieldValue('nrCnpj', e.target.value)}
                                onBlur={handleBlur}
                                error={errors.nrCnpj && touched.nrCnpj}
                                helperText={errors.nrCnpj && touched.nrCnpj && errors.nrCnpj}
                              />
                            </Grid>

                            <Grid item xs={3}>
                              <TextField
                                InputProps={{
                                  inputProps: { maxLength: 100 },
                                  classes: { underline: classes.underline }
                                }}
                                type='number'
                                label='Inscrição estadual *'
                                value={values.nrIe ? parseFloat(values.nrIe) : ''}
                                onChange={e => {
                                  if (e.target.value !== '0') {
                                    setFieldValue('nrIe', e.target.value);
                                  } else {
                                    setFieldValue('nrIe', undefined);
                                  }
                                }}
                                onBlur={e => {
                                  if (e.target.value !== '0') {
                                    setFieldValue('nrIe', e.target.value);
                                  } else {
                                    setFieldValue('nrIe', undefined);
                                  }
                                }}
                                error={errors.nrIe && touched.nrIe}
                                helperText={
                                  errors.nrIe &&
                                  touched.nrIe &&
                                  provider.intl.formatMessage({
                                    id: 'campoObrigatorio'
                                  })
                                }
                              />
                            </Grid>

                            <Grid item xs={6} />

                            <Grid item xs={3}>
                              <MaskedTextField
                                label='Telefone *'
                                tipoMascara='telefone'
                                userlang='pt-BR'
                                InputProps={{
                                  classes: { underline: classes.underline }
                                }}
                                id='telefone'
                                value={values.telefone}
                                onChange={e => setFieldValue('telefone', e.target.value)}
                                onBlur={handleBlur}
                                error={errors.telefone && touched.telefone}
                                helperText={
                                  errors.telefone &&
                                  touched.telefone &&
                                  provider.intl.formatMessage({
                                    id: 'campoObrigatorio'
                                  })
                                }
                              />
                            </Grid>

                            <Grid item xs={3}>
                              <MaskedTextField
                                label='WhatsApp *'
                                tipoMascara='telefone'
                                userlang='pt-BR'
                                InputProps={{
                                  classes: { underline: classes.underline }
                                }}
                                id='whatsapp'
                                value={values.whatsapp}
                                onChange={e => setFieldValue('whatsapp', e.target.value)}
                                onBlur={handleBlur}
                                error={errors.whatsapp && touched.whatsapp}
                                helperText={
                                  errors.whatsapp &&
                                  touched.whatsapp &&
                                  provider.intl.formatMessage({
                                    id: 'campoObrigatorio'
                                  })
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                type='email'
                                name='email'
                                onChange={e => setFieldValue('email', e.target.value)}
                                onBlur={handleBlur}
                                value={values.email}
                                error={errors.email && touched.email}
                                helperText={errors.email && touched.email ? errors.email : null}
                                InputProps={{
                                  inputProps: { maxLength: 100 },
                                  classes: { underline: classes.underline }
                                }}
                                label='E-mail *'
                              />
                            </Grid>

                            <Grid item xs={3}>
                              <MaskedTextField
                                label='CEP *'
                                tipoMascara='cep'
                                userlang='pt-BR'
                                InputProps={{
                                  classes: { underline: classes.underline }
                                }}
                                id='cep'
                                value={values.cep}
                                onChange={e => setFieldValue('cep', e.target.value)}
                                onBlur={handleBlur}
                                error={errors.cep && touched.cep}
                                helperText={
                                  errors.cep &&
                                  touched.cep &&
                                  provider.intl.formatMessage({
                                    id: 'campoObrigatorio'
                                  })
                                }
                              />
                            </Grid>

                            <Grid item xs={3}>
                              <AutoComplete
                                itens={this.props.estadoList}
                                value={values.estado}
                                campoOp='sgUf'
                                valueAutoComplete={values.estado}
                                campoChave='idUf'
                                label='Estado *'
                                name='estado'
                                id='estado'
                                error={errors.estado && touched.estado}
                                helperText={
                                  errors.estado &&
                                  touched.estado &&
                                  provider.intl.formatMessage({
                                    id: 'campoObrigatorio'
                                  })
                                }
                                onChange={this.onChangeAutocompleteEstado}
                                onChangeAutoComplete={this.getAllEstados}
                                key={this.state.keyAutoCompleteEstado}
                                maxHeight='calc(100vh - 572px)'
                              />
                            </Grid>

                            <Grid item xs={6}>
                              <AutoComplete
                                itens={this.state.municipioList}
                                value={values.cidade}
                                campoOp='nmMunicipio'
                                campoChave='idMunicipio'
                                disabled={this.state.municipioList.length === 0}
                                label='Cidade *'
                                name='cidade'
                                id='cidade'
                                error={errors.cidade && touched.cidade}
                                helperText={
                                  errors.cidade &&
                                  touched.cidade &&
                                  provider.intl.formatMessage({
                                    id: 'campoObrigatorio'
                                  })
                                }
                                onChange={this.onChangeAutocompleteCidade}
                                key={this.state.keyAutoCompleteCidade}
                                valueAutoComplete={values.cidade}
                                maxHeight='calc(100vh - 572px)'
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                InputProps={{
                                  inputProps: { maxLength: 100 },
                                  classes: { underline: classes.underline }
                                }}
                                id='endereco'
                                label='Endereço *'
                                value={values.endereco}
                                onChange={e => setFieldValue('endereco', e.target.value)}
                                onBlur={handleBlur}
                                error={errors.endereco && touched.endereco}
                                helperText={
                                  errors.endereco &&
                                  touched.endereco &&
                                  provider.intl.formatMessage({
                                    id: 'campoObrigatorio'
                                  })
                                }
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </form>
                    </CardIcon>
                  </OnExitConfirmation>
                  <div className={classes.fab}>
                    <FabDiamondMenu
                      actionSalvar={{ onClick: handleSubmit }}
                      actionCancelar={{ onClick: this.handleCancelar }}
                    />
                  </div>
                </div>
              );
            }}
          />
        </div>
      </IntlProvider>
    );
  }
}
const mapStateToProps = state => ({
  UsuarioLogado: state.usuarioReducer,
  itensState: state.toolbar,
  estadoList: state.estadoReducer,
  lojaList: state.lojaReducer
});

let enhanced = withStyles(style)(LojaForm);
export default connect(mapStateToProps)(withRouter(enhanced));
