var municipioList = []

const adicionaListaDeMunicipio = (state = municipioList, action) => {
  switch (action.type) {
    case 'ADD_MUNICIPIOS':
      municipioList = action.list
      return municipioList
    default:
      return state
  }
}

export default adicionaListaDeMunicipio
