export default {
  vendedor: {
    title: 'CONSULTORES',
    tel: 'Telefone',
    nomeCol: 'Nome',
    email: 'E-mail',
    uf: 'Estado',
    cidade: 'Cidade'
  }
};
