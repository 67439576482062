import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { withStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
  controls: {
    margin: theme.spacing(3)
  },
  exampleWrapper: {
    position: 'relative',
    height: 0
  },
  radioGroup: {
    margin: `${theme.spacing()}px 0`
  },
  buttonDisabled: {
    backgroundColor: '#95989A',
    cursor: 'default'
  },
  fab: {
    position: 'fixed',
    bottom: 220,
    right: 30,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    zIndex: 1,
    marginBottom: 0
  },
  divBotao: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '53.74px !important',
    width: '53.74px !important',
    borderRadius: 10,
    boxShadow: '10px 10px 15px rgba(0, 0, 0, 0.2)',
    transform: 'rotate(45deg) !important'
  },
  centralIcon: {
    transform: 'rotate(-44deg)',
    padding: '5px 0px 0 0px'
  },
  buttonsFAB: {
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#FFFFFF'
    }
  },
  openIcon: {
    transform: 'rotate(49deg) !important'
  }
});

class FabDiamondMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      hidden: false
    };

    this.handleClickMenu = this.handleClickMenu.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleClickMenu() {
    this.setState(state => ({ open: !state.open }));
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleOpen() {
    this.setState({ open: true });
  }

  render() {
    const { classes } = this.props;
    const { hidden, open } = this.state;
    const direction = this.props.direction;
    const speedDialClassName = classnames(classes.speedDial);

    /*{ Objeto de configuração
      className,
      onClick,
      label,
      icon,
      iconStyle,
      disabled
    }*/
    const actions = [
      {
        icon: (
          <CheckIcon
            color='primary'
            style={{
              ...this.props.actionSalvar.iconStyle
            }}
          />
        ),
        label: 'Salvar',
        disabled: false,
        ...this.props.actionSalvar
      },
      {
        icon: (
          <CloseIcon
            color='primary'
            style={{
              ...this.props.actionCancelar.iconStyle
            }}
          />
        ),
        label: 'Cancelar',
        disabled: false,
        ...this.props.actionCancelar
      }
    ].concat(this.props.additionalActions || []);

    return (
      <div className={classnames(classes.fab, this.props.rootClassName)} style={this.props.customStyle}>
        <div className={classes.exampleWrapper}>
          <SpeedDial
            ariaLabel='SpeedDial example'
            className={speedDialClassName}
            hidden={hidden}
            icon={
              <SpeedDialIcon
                className={classes.centralIcon}
                icon={<MoreVertIcon />}
                openIcon={<CloseIcon />}
              />
            }
            onBlur={this.handleClose}
            onClick={this.handleClickMenu}
            onClose={this.handleClose}
            onFocus={this.handleOpen}
            onMouseEnter={this.handleOpen}
            onMouseLeave={this.handleClose}
            open={open}
            direction={direction}
            classes={{ fab: classes.divBotao }}
            ButtonProps={{
              color: 'primary'
            }}
          >
            {actions
              .filter(item => item.disabled !== true || item.disabled === undefined)
              .map((action, i) => (
                <SpeedDialAction
                  ButtonProps={{
                    disableRipple: action.disabled,
                    disableFocusRipple: action.disabled
                  }}
                  key={i}
                  icon={action.icon}
                  className={classnames({
                    button: action.disabled
                      ? classes.buttonDisabled
                      : classnames(classes.buttonsFAB, action.className),
                    buttonClosed: action.disabled
                      ? classes.buttonDisabled
                      : classnames(classes.buttonsFAB, action.className)
                  })}
                  tooltipTitle={action.disabled ? '' : action.label}
                  onClick={e => {
                    if (!action.disabled) {
                      this.handleClickMenu();
                      action.onClick(e);
                    }
                  }}
                />
              ))}
          </SpeedDial>
        </div>
      </div>
    );
  }
}

FabDiamondMenu.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FabDiamondMenu);
