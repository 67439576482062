import axios from 'axios';

const BASEAPI = process.env.REACT_APP_URL_API;
const ENDPOINT = '/meta';

const getMetasByVendedor = async id => {
  return await axios.get(BASEAPI + ENDPOINT + `/vendedor?id=${id}`);
};

const getMetasByGerente = async id => {
  return await axios.get(BASEAPI + ENDPOINT + `/gerente?id=${id}`);
};

const getMetasAcompanhamento = async id => {
  return await axios.get(BASEAPI + ENDPOINT + `/acompanhamento`);
};

const getById = async id => {
  return await axios.get(`${BASEAPI + ENDPOINT}/${id}`);
};

const getAll = async id => {
  return await axios.get(`${BASEAPI + ENDPOINT}`);
};

const insert = async data => {
  return await axios.post(BASEAPI + ENDPOINT, data);
};

const update = async data => {
  return await axios.put(BASEAPI + ENDPOINT, data);
};

const deleteMeta = async id => {
  return await axios.delete(`${BASEAPI + ENDPOINT}/${id}`);
};

export {
  getMetasByVendedor,
  getById,
  insert,
  update,
  deleteMeta,
  getMetasByGerente,
  getAll,
  getMetasAcompanhamento
};
