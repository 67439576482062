var gerenteList = []

const adicionaListaDeGerente = (state = gerenteList, action) => {
  switch (action.type) {
    case 'ADD_GERENTES':
      gerenteList = action.list
      return gerenteList
    default:
      return state
  }
}

export default adicionaListaDeGerente
