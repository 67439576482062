//@author Katia Miglioli
//string,number,date
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import { SvgIconAdd } from '@components/svg-components/index';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import '@styles/CardFilter.css';
import Chips from './FilterChip';
import flattenMessages from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import { FormattedMessage } from 'react-intl';
import * as moment from 'moment';
import { TextField, DatePicker } from '@kepha/sumora-react-components';
import Select from '@material-ui/core/Select';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '200%',
    marginTop: 15
  },
  formControl: {
    marginRight: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
    width: 204
  },
  divTitulo: {
    fontSize: 18,
    color: '#666666',
    position: 'relative',
    marginLeft: '65px'
  },
  divSelects: {
    display: 'flex'
  },
  divChips: {
    display: 'flex',
    minHeight: 50
  },
  button: {
    marginTop: 20,
    margin: theme.spacing.unit,
    minWight: 34,
    maxWight: 34,
    minHeight: 34,
    maxHeight: 34,
    marginRight: '25px',
    zIndex: 1
  },
  iconButton: {
    marginLeft: 0,
    marginRight: 0
  },
  divChipsText: {
    alignItems: 'center',
    color: '#7F7F7F',
    fontSize: 14,
    display: 'flex'
  },
  divButtonFiltrar: {
    position: 'relative',
    float: 'right',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    left: '25px'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    fontWeight: '300 !important'
  },
  textField: {
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    marginTop: 0,
    width: '204px !important'
  },
  underline: {
    '&:after': {
      borderBottomColor: '#EA6909'
    },
    '&:hover:before': {
      borderBottomColor: ['#EA6909', '!important']
    }
  }
});

class CardFiltersOpen extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      mensagemChip: false,
      typeTextField: 'string',
      valueDate: null,
      filterDefault: true,
      relationshipSelects: 0,
      selectValue: '',
      selectFilter: '',
      currentEvent: '',
      operationTypeNumbers: [
        {
          label: <FormattedMessage id='filtrosDeListagem.igual' />,
          field: 'Igual',
          type: '=='
        },
        {
          label: <FormattedMessage id='filtrosDeListagem.maiorOuIgual' />,
          field: 'Maior ou igual',
          type: '>='
        },
        {
          label: <FormattedMessage id='filtrosDeListagem.maior' />,
          field: 'Maior',
          type: '>'
        },
        {
          label: <FormattedMessage id='filtrosDeListagem.menorOuIgual' />,
          field: 'Menor ou igual',
          type: '<='
        },
        {
          label: <FormattedMessage id='filtrosDeListagem.menor' />,
          field: 'Menor',
          type: '<'
        },
        {
          label: <FormattedMessage id='filtrosDeListagem.diferente' />,
          field: 'Diferente',
          type: '!='
        }
      ],
      operationTypeString: [
        {
          label: <FormattedMessage id='filtrosDeListagem.contendo' />,
          field: 'Contendo',
          type: '%%'
        }
      ],
      operationTypeEnum: [
        {
          label: <FormattedMessage id='filtrosDeListagem.igual' />,
          field: 'Igual',
          type: '=='
        }
      ]
    };
    this.updateStatePreviousPage();
  }
  validarEntrada(newChip) {
    var i;
    var a = 0;
    var chipDataSelf = this.treatChipsOutput(this.props.chipData);
    if (chipDataSelf[0] !== undefined) {
      for (i = 0; i < chipDataSelf.length; i++) {
        if (
          chipDataSelf[i].operation === newChip.operation &&
          chipDataSelf[i].filter.label === newChip.filter.label
        )
          a++;
      }
    }
    return a;
  }

  handleSubmit(values, { resetForm }) {
    var newChip = {
      filter: values.filter,
      operation: values.operation,
      value: values.value
    };

    var a = this.validarEntrada(newChip);
    if (a === 0) {
      this.setState(state => ({
        mensagemChip: false
      }));
      this.props.dispatch({
        type: 'ADD_CHIPS',
        id: this.props.viewKey,
        newChip
      });
    } else {
      this.setState(() => ({
        mensagemChip: true
      }));
    }
    if (!this.state.mensagemChip) {
      resetForm({ value: '', filter: '', operation: '' });
      this.setState(state => ({
        valueDate: null,
        selectValue: '',
        selectFilter: '',
        typeTextField: 'string'
      }));
    }
    if (values.isSecondButton) {
      this.props.onFilter(this.treatChipsOutput(this.props.chipData));
    } else {
      this.props.onFilterChanged(this.treatChipsOutput(this.props.chipData));
    }
  }

  labelTypeDate(label) {
    const tempTipoDeFiltro = this.props.filtros;
    var i;
    for (i = 0; i < this.props.filtros.length; i++) {
      if (tempTipoDeFiltro[i].field === label) {
        return tempTipoDeFiltro[i].type;
      }
    }
  }

  dateSelects(label) {
    var type = this.labelTypeDate(label);
    if (type === 'number' || type === 'date') {
      this.TypeSelect = 1;
    } else if (type === 'string') {
      this.TypeSelect = 0;
    } else if (type === 'enum') {
      this.TypeSelect = 2;
    }
    this.setState(() => ({
      relationshipSelects: this.TypeSelect
    }));
    return this.TypeSelect;
  }

  verificarInput(label) {
    // INSERIR O SELECT PARA ENUM
    var type = this.labelTypeDate(label);
    this.setState(() => ({
      typeTextField: type
    }));
  }

  operationType() {
    switch (this.state.relationshipSelects) {
      case 1:
        return this.state.operationTypeNumbers;
      case 2:
        return this.state.operationTypeEnum;
      default:
        return this.state.operationTypeString;
    }
  }

  selectOptionsEnum() {
    var tempFiltros = this.props.filtros;
    var i = 0;
    for (i; i < tempFiltros.length; i++) {
      if (tempFiltros[i].field === this.state.currentEvent) {
        return tempFiltros[i].options;
      }
    }
    return null;
  }

  validarEnum(nameEnum) {
    //retorna o obj options a partir do field do select
    var i;
    var tempSelectOptionsEnum = this.selectOptionsEnum();
    for (i = 0; i < tempSelectOptionsEnum.length; i++) {
      if (tempSelectOptionsEnum[i].field === nameEnum) {
        return tempSelectOptionsEnum[i];
      }
    }
    return null;
  }

  validarFilter(filter) {
    var i;
    var tempFiltros = this.props.filtros;
    for (i = 0; i < tempFiltros.length; i++) {
      if (tempFiltros[i].field === filter) {
        return tempFiltros[i];
      }
    }
    return null;
  }

  updateStatePreviousPage() {
    //alterar a pagina anterior no redux
    const pagAnterior = this.props.paginaAnterior;
    if (!(pagAnterior === this.props.viewKey || pagAnterior === 'paginaAnterior')) {
      this.props.dispatch({
        type: 'CLEAN_CHIPS',
        id: this.props.paginaAnterior
      });
    }
    this.props.dispatch({
      type: 'UPDATE_PREVIOUS_PAGE',
      idClean: this.props.viewKey
    });
  }

  treatChipsOutput(dataChips) {
    var filterChips = [];
    for (var i = 0; i < dataChips.length; i++) {
      if (dataChips[i].id === this.props.viewKey) {
        //ALTERA PARA O ID DOS FILTROS DA TELA
        filterChips = dataChips[i].content;
      }
    }
    return filterChips;
  }

  render() {
    const { classes } = this.props;
    const filtros = this.props.filtros;
    const typeTextField = this.state.typeTextField;

    return (
      <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
        <Formik
          initialValues={{
            filter: '',
            operation: '',
            value: '',
            isSecondButton: false
          }}
          onSubmit={this.handleSubmit}
          validationSchema={Yup.object().shape({
            value: Yup.string().required(<FormattedMessage id='filtrosDeListagem.campoObrigatorio' />),
            filter: Yup.string().required(<FormattedMessage id='filtrosDeListagem.campoObrigatorio' />),
            operation: Yup.string().required(
              <FormattedMessage id='filtrosDeListagem.campoObrigatorio' />
            )
          })}
          render={({
            values,
            handleSubmit,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue
          }) => (
            <form>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <div>
                    <div className={classes.divSelects}>
                      <div className={classes.divSelectsText}>
                        <form className={classes.root} autoComplete='off'>
                          <FormControl
                            className={classes.formControl}
                            error={errors.filter && touched.filter}
                          >
                            <InputLabel style={{ fontSize: 14 }} htmlFor='tipo-filtro'>
                              <FormattedMessage id='filtrosDeListagem.tipoDeFiltro' />
                            </InputLabel>
                            <Select
                              SelectDisplayProps={{
                                style: { backgroundColor: 'transparent' }
                              }}
                              input={<Input id='tipo-filtro' />}
                              value={this.state.selectFilter}
                              name='filter'
                              onChange={event => {
                                handleChange(event);
                                this.verificarInput(event.target.value);
                                if (this.dateSelects(event.target.value) === 1) {
                                  setFieldValue('operation', '');
                                } else if (this.dateSelects(event.target.value) === 0) {
                                  setFieldValue('operation', '%%');
                                } else if (this.dateSelects(event.target.value) === 2) {
                                  setFieldValue('operation', '==');
                                }
                                setFieldValue('filter', this.validarFilter(event.target.value));
                                setFieldValue('value', '');
                                this.setState(() => ({
                                  mensagemChip: false,
                                  valueDate: null,
                                  currentEvent: event.target.value,
                                  selectFilter: event.target.value
                                }));
                              }}
                              onBlur={handleBlur}
                            >
                              {filtros.map(filtro => (
                                <MenuItem key={filtros.indexOf(filtro)} value={filtro.field}>
                                  {filtro.label}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText
                              error={true}
                              style={{
                                position: 'absolute',
                                marginTop: '30%',
                                display: this.state.mensagemChip ? 'flex' : 'none'
                              }}
                            >
                              <FormattedMessage id='filtrosDeListagem.filtroDuplicado' />
                            </FormHelperText>
                            <FormHelperText>
                              {errors.filter && touched.filter ? errors.filter : null}
                            </FormHelperText>
                          </FormControl>
                          <FormControl
                            className={classes.formControl}
                            error={errors.operation && touched.operation}
                          >
                            <InputLabel style={{ fontSize: 14 }} htmlFor='tipo-comparacao'>
                              <FormattedMessage id='filtrosDeListagem.tipoDeComparacao' />
                            </InputLabel>
                            <Select
                              SelectDisplayProps={{
                                style: { backgroundColor: 'transparent' }
                              }}
                              name='operation'
                              value={values.operation}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              input={<Input id='tipo-comparacao' />}
                            >
                              {this.operationType().map(operation => (
                                <MenuItem
                                  key={this.operationType().indexOf(operation)}
                                  value={operation.type}
                                >
                                  {operation.label}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>
                              {errors.operation && touched.operation ? errors.operation : null}
                            </FormHelperText>
                          </FormControl>
                          <div className={classes.container} noValidate>
                            {(typeTextField === 'date' && (
                              <DatePicker
                                userlang='pt-BR'
                                style={{ marginTop: 1 }}
                                label={<FormattedMessage id='filtrosDeListagem.pesquisa' />}
                                value={this.state.valueDate}
                                errorTouched={errors.value && touched.value}
                                helperText={<FormattedMessage id='filtrosDeListagem.campoObrigatorio' />}
                                onBlur={date => {
                                  let validation = moment(date.target.value, 'DD/MM/YYYY').isValid();

                                  if (!validation) {
                                    setFieldValue('value', null);
                                  }
                                }}
                                onChange={date => {
                                  let validation = moment(date._d, 'DD/MM/YYYY').isValid();
                                  if (!validation) {
                                    setFieldValue('value', null);
                                  } else {
                                    setFieldValue('value', date._d);
                                    this.setState(() => ({
                                      valueDate: date._d
                                    }));
                                  }
                                }}
                              />
                            )) ||
                              (typeTextField === 'enum' && (
                                <FormControl
                                  className={classes.formControl}
                                  error={errors.value && touched.value}
                                >
                                  <InputLabel style={{ fontSize: 14 }} htmlFor='tipo-pesquisa'>
                                    <FormattedMessage id='filtrosDeListagem.pesquisa' />
                                  </InputLabel>
                                  <Select
                                    SelectDisplayProps={{
                                      style: { backgroundColor: 'transparent' }
                                    }}
                                    name='value'
                                    value={this.state.selectValue}
                                    onChange={event => {
                                      handleChange(event);

                                      this.setState(state => ({
                                        selectValue: event.target.value
                                      }));

                                      setFieldValue('value', this.validarEnum(event.target.value));
                                    }}
                                    onBlur={handleBlur}
                                    input={<Input id='tipo-pesquisa' />}
                                  >
                                    {this.selectOptionsEnum().map(select => (
                                      <MenuItem
                                        key={this.selectOptionsEnum().indexOf(select)}
                                        value={select.field}
                                      >
                                        {select.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  <FormHelperText>
                                    {errors.value && touched.value ? errors.value : null}
                                  </FormHelperText>
                                </FormControl>
                              )) ||
                              ((typeTextField === 'string' || typeTextField === 'number') && (
                                <TextField
                                  InputProps={{
                                    classes: { underline: classes.underline }
                                  }}
                                  id='date'
                                  label={<FormattedMessage id='filtrosDeListagem.pesquisa' />}
                                  type={typeTextField}
                                  name='value'
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.value}
                                  error={errors.value && touched.value}
                                  helperText={
                                    errors.value && touched.value ? (
                                      <FormattedMessage id='filtrosDeListagem.campoObrigatorio' />
                                    ) : null
                                  }
                                  margin='normal'
                                  className={classes.textField}
                                />
                              ))}
                          </div>
                          <Button
                            className={classes.button}
                            id='button'
                            onClick={e => {
                              setFieldValue('isSecondButton', false);
                              handleSubmit(e);
                            }}
                          >
                            <div className={classes.iconButton}>
                              <SvgIconAdd />
                            </div>
                          </Button>
                        </form>
                      </div>
                    </div>
                    <div className={classes.divChips}>
                      <div className={classes.divChipsText}>
                        <FormattedMessage id='filtrosDeListagem.filtrosAdicionados' />:
                      </div>
                      <Chips
                        marginBottomImg={false}
                        viewKey={this.props.viewKey}
                        onFilterChanged={this.props.onFilterChanged}
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.divButtonFiltrar}>
                  <div>
                    <Button
                      color='primary'
                      className={classes.button}
                      onClick={e => {
                        setFieldValue('isSecondButton', false);
                        handleSubmit(e);
                      }}
                    >
                      <div style={{ position: 'relative' }}>
                        <FormattedMessage id='filtrosDeListagem.filtrar' />
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          )}
        />
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => ({
  chipData: state.chips.chipData,
  paginaAnterior: state.previousPage.paginaAnterior
});

CardFiltersOpen.propTypes = {
  classes: PropTypes.object.isRequired
};

let EnhacedComponent = connect(mapStateToProps)(CardFiltersOpen);
export default withStyles(styles)(EnhacedComponent);
