const containerSwalBodyStyle = {
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  fontFamily: 'Roboto',
  textAlign: 'left',
  fontWeight: 'bold'
};

const tituloSwalBodyStyle = { fontSize: 24, color: 'rgba(0,0,0,0.6)', marginBottom: 20 };

const textSwalBodyStyle = { width: '100%', fontSize: 20, color: 'rgba(0,0,0,0.7)' };

export { containerSwalBodyStyle, tituloSwalBodyStyle, textSwalBodyStyle };
