import { getAll as getAllVendedores } from '@resources/api/vendedor'
const getVendedores = list => {
  return async dispatch => {
    if (list.length > 0) {
      return list
    }

    await getAllVendedores().then(res => {
      list = res.data
      dispatch({
        type: 'ADD_VENDEDORES',
        list: list,
      })
    })

    return list
  }
}

const getVendedor = (list, id) => {
  return async dispatch => {
    var vendedor

    if (list.length > 0) {
      vendedor = list.find(el => {
        return el.idUsuario === id
      })
    }

    if (vendedor === undefined) {
      await getAllVendedores().then(res => {
        vendedor = res.data.find(el => {
          return el.idUsuario === id
        })
        list = res.data
        dispatch({
          type: 'ADD_VENDEDORES',
          list: list,
        })
      })
    }
    return vendedor.nmUsuario
  }
}

const refreshVendedorList = list => {
  return async dispatch => {
    await getAllVendedores().then(res => {
      list = res.data
      dispatch({
        type: 'ADD_VENDEDORES',
        list: list,
      })
    })

    return list
  }
}

export { getVendedor, getVendedores, refreshVendedorList }
