/* 
    autor: Gabriela Farias
*/
import React, { Component } from 'react';
import List from '@material-ui/core/List';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import './MenuLateral.css';
import { withRouter } from 'react-router-dom';
import flattenMessages from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import logoWithWrite from '@images/logo-white2.png';
import logo from '@images/logo-simple-white.png';
import Collapse from '@material-ui/core/Collapse';
import ItensMenu from './itensMenu';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

const styles = theme => ({
  fundo: {
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  itens: {
    borderRadius: 10,
    maxHeight: 33,
    display: 'inline'
  },
  drawerPaper: {
    position: 'absolute',
    whiteSpace: 'nowrap',
    backgroundImage: theme.palette.menu.background,
    width: 270,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: 200
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    position: 'fixed',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: 200
    }),
    width: 54,
    [theme.breakpoints.up('sm')]: {
      width: 54
    }
  },
  formIcon: {
    marginRight: 19,
    marginTop: 8,
    marginLeft: 2
  },
  listItem: {
    padding: '15px 15px 15px 12px'
  },
  link: {
    color: 'transparent',
    display: 'inline',
    whiteSpace: 'nowrap'
  },
  icon: {
    backgroundColor: '#EA6909',
    backgroundPosition: 'bottom -1px center',
    backgroundRepeat: 'no-repeat',
    maxHeight: '78px !important'
  },
  logoWithWrite: {
    height: 55,
    width: 154,
    marginBottom: '23px',
    marginTop: '5px',
    marginLeft: '25px'
  },
  logo: {
    height: 30,
    width: 30,
    marginLeft: '10px',
    marginBottom: '30px'
  }
});

const url = '/app'; /*  Link inicial */

/* 
    Menu lateral customizado
*/

class MenuLateral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
    this.itensDoMenu = this.itensDoMenu.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.goToBaseUrl = this.goToBaseUrl.bind(this);
  }
  animacaoSeta = '';
  corPrimeiroSubmenu = '#4E3B2D';
  corDemaisSubmenus = '#3D2A1C';
  controleCor = 0;
  itemSelecionadoAtual = '';

  itensDoMenu(itensMenu, cor) {
    var left = 0;
    const { classes } = this.props;
    return (
      <div className={classes.fundo}>
        {itensMenu.map((post, i) => {
          this.controleCor = 0;
          return this.celulaMenu(post, i, left, cor);
        })}
      </div>
    );
  }
  itemSub(itensMenu) {
    var cor = '';
    var left = '';
    if (this.controleCor === 0) {
      cor = this.corPrimeiroSubmenu;
      left = '55px';
    } else if (this.controleCor > 0) {
      cor = this.corDemaisSubmenus;
      left = '70px';
    }
    return (
      <div style={{ backgroundColor: cor }}>
        {itensMenu.map((post, i) => {
          this.controleCor++;

          return post.categoria >= this.props.permissaoUsuario && this.celulaMenu(post, i, left);
        })}
      </div>
    );
  }

  async openMenuIcone(menu) {
    await this.props.dispatch({ type: 'OPEN_MENU' });
    menu.selecionado = true;
  }

  async onSelect(menu) {
    menu.selecionado = !menu.selecionado;
    this.setState({
      open: Math.random()
    });

    if (!this.props.itensState.open) {
      if (menu.children !== undefined) {
        await this.openMenuIcone(menu);
      }
    } else {
      if (menu.children === undefined) {
        if (menu.parent !== undefined) {
          this.itemSelecionadoAtual = menu.parent;
        } else {
          this.itemSelecionadoAtual = menu.id;
        }
      }
      if (menu.children !== undefined && !this.props.itensState.open) {
        this.props.dispatch({
          type: 'OPEN_MENU'
        });
      } else if (menu.children === undefined && this.props.itensState.open) {
        this.props.dispatch({
          type: 'CLOSE_MENU'
        });
      }
    }
  }

  checkSelectedItem = id => {
    const info = window.location.pathname;
    var actualRoute = info.split(/[\s/]+/, 3);
    actualRoute = actualRoute[actualRoute.length - 1].toUpperCase();

    return id === actualRoute;
  };
  celulaMenu(post, i, left, cor) {
    const { classes } = this.props;

    return (
      <List className={classes.itens} component='span' key={i}>
        <div
          onClick={() => {
            if (post.children === undefined) {
              this.props.dispatch({
                type: 'SELECIONAR_ITEM',
                itemSelecionado: post.id
              });
              this.props.history.push(`${url}${post.route}`);
            }
          }}
          className={classes.link}
        >
          <ListItem
            button
            selected={false}
            onClick={() => this.onSelect(post)}
            className={classes.listItem}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div
              style={{
                width: post.icon === undefined ? '100%' : 'none',
                display: 'flex'
              }}
            >
              {post.icon !== undefined && (
                <InputAdornment className={classes.formIcon}>
                  {<post.icon color={this.checkSelectedItem(post.id) ? '#EA6909' : '#fff'} />}
                </InputAdornment>
              )}
              <div
                className={this.props.itensState.open ? 'run-animation-open' : 'run-animation-close'}
                style={{
                  marginLeft: post.icon === undefined && left,
                  color: this.checkSelectedItem(post.id) ? '#EA6909' : '#fff',
                  fontWeight: 400,
                  fontSize: 14,
                  fontFamily: "'Roboto', sans-serif",
                  whiteSpace: 'nowrap',
                  width: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
                key={i}
              >
                {post.name}
              </div>
            </div>
            {post.children !== undefined && (
              <div>
                <InputAdornment
                  style={{ textAlign: 'right' }}
                  id={post.selecionado && this.props.itensState.open ? 'iconDown' : 'iconRight'}
                >
                  <img src={require('../../assets/images/seta.png')} alt='icone' />
                </InputAdornment>
              </div>
            )}
          </ListItem>
          {post.selecionado && this.props.itensState.open && (
            <Collapse in={true} timeout='auto' unmountOnExit>
              {post.children !== undefined && this.itemSub(post.children)}
            </Collapse>
          )}
        </div>
      </List>
    );
  }
  closeMenu(e) {
    if (e.target.id !== 'buttonMenu' && e.target.id !== 'botaoMenu') {
      this.props.dispatch({
        type: 'CLOSE_MENU'
      });
    }
  }

  goToBaseUrl() {
    this.props.dispatch({
      type: 'SELECIONAR_ITEM',
      itemSelecionado: 'HOME'
    });
    this.props.history.push('/app');
  }

  render() {
    if (this.props.theme && this.props.theme.menu) {
      this.corPrimeiroSubmenu = this.props.theme.palette.menu.itemBackground;
      this.corDemaisSubmenus = this.props.theme.palette.menu.subitemBackground;
    }

    const categoria = this.props.permissaoUsuario;
    const itensMenu = ItensMenu.filter(menu => {
      return menu.categoria >= categoria && (categoria !== 0 || menu.route !== '/cotacoes');
    });
    const { classes } = this.props;
    return (
      <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
        <Drawer
          variant='permanent'
          open={!this.props.itensState.open}
          classes={{
            paper: classNames(
              classes.drawerPaper,
              !this.props.itensState.open && classes.drawerPaperClose
            )
          }}
        >
          <div onClick={this.goToBaseUrl} className={classes.icon} id='logo-gava'>
            <img
              src={logo}
              id={this.props.itensState.open ? 'run-animation-open-logo' : 'run-animation-close-logo'}
              className={classes.logo}
              alt='logo'
            />
            <img
              src={logoWithWrite}
              id={this.props.itensState.open ? 'run-animation-close-logo' : 'run-animation-open-logo'}
              className={classes.logoWithWrite}
              alt='logo'
            />
          </div>
          {this.itensDoMenu(itensMenu)}
        </Drawer>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => ({
  itensState: state.menuOpen,
  itemSelecionado: state.itemSelecionado,
  usuarioLogado: state.adicionarUsuarioLogado
});

MenuLateral.propTypes = {
  classes: PropTypes.object.isRequired
};

let EnhacedComponent = withStyles(styles)(MenuLateral);
export default connect(mapStateToProps)(withRouter(EnhacedComponent));
