import CardFilters from '@components/CardFilters/CardFilters';
import FabDiamond from '@components/FABDiamond/FABDiamond';
import { SvgIconeContratos } from '@components/svg-components';
import { ButtonGrid, DataTable, GroupButtonGrid } from '@kepha/sumora-react-components';
import { withStyles } from '@material-ui/core/styles';
import { aprovarProposta, findAllPropostas } from '@resources/api/proposta';
import '@root/App.css';
import swal from '@sweetalert/with-react';
import SwalConfirmacao from '@utils/functions/SwalConfirmacao/SwalConfirmacao';
import SwalMessage from '@utils/functions/SwalMessage/SwalMessage';
import { getUserLang } from '@utils/localeUtils';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FilterUtil from '../filterUtil';
import style from './style/style';
import { head, headAdmin, headGerente } from './tableHead';

const iconAdd = require('@images/ic_add_white.png');
class Proposta extends React.Component {
  constructor(props) {
    super(props);

    this.operationValues = [
      {
        label: 'Cliente',
        field: 'nmCliente',
        type: 'string'
      },
      {
        label: 'Valor (R$)',
        field: 'vlTotal',
        type: 'number'
      },
      {
        label: 'Situação',
        field: 'stProposta',
        type: 'enum',
        options: [
          {
            label: 'Aberta',
            field: 'Aberta',
            value: '0'
          },
          {
            label: 'Perdida',
            field: 'Perdida',
            value: '1'
          },
          {
            label: 'Contrato',
            field: 'Contrato',
            value: '2'
          }
        ]
      }
    ];

    const consultor = {
      label: 'Consultor',
      field: 'nmUsuario',
      type: 'string'
    };

    this.operationAdmin = [
      {
        label: 'Loja',
        field: 'nmLoja',
        type: 'string'
      },
      consultor
    ];

    this.operationGerente = [consultor];

    this.operationNumero = {
      label: 'Número',
      field: 'nrProposta',
      type: 'string'
    };

    this.state = {
      operationValues: [this.operationNumero, ...this.operationValues],
      selected: undefined, // controla a linha selecionada
      dataAppSync: [], // dados que vem da api
      dataInTable: []
    };

    this.props.dispatch({
      type: 'UPDATE_TOOLBAR',
      toolbar: 'PROPOSTAS'
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleAdd = () => {
    this.props.history.push('/app/propostas/novo');
  };

  handleEdit = () => {
    this.props.history.push({
      pathname: '/app/propostas/editar/' + this.state.selected.idProposta,
      state: { proposta: this.state.selected }
    });
  };
  handleContract = () => {
    if (!this.state.selected) return;

    if (this.state.selected.vlTotal < 1) {
      swal('Não é possível contratar uma proposta sem itens', {
        icon: 'error',
        confirmButtonColor: '#8CD4F5',
        buttons: {
          confirm: {
            confirmButtonColor: '#8CD4F5',
            text: 'Ok',
            value: true,
            visible: true,
            closeModal: true,
            className: 'swal2-Ok'
          }
        }
      });
      return;
    }

    SwalConfirmacao({ text: 'Deseja aprovar esta proposta?', inverteButtonsColors: true }).then(res => {
      if (res) {
        aprovarProposta(this.state.selected.idProposta)
          .then(res => {
            swal('Proposta aprovada com sucesso', {
              icon: 'success',
              confirmButtonColor: '#8CD4F5',
              buttons: {
                confirm: {
                  confirmButtonColor: '#8CD4F5',
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-Ok'
                }
              }
            });
            this.onFilter();
          })
          .catch(err => {
            swal('Erro ao aprovar proposta', {
              icon: 'error',
              confirmButtonColor: '#8CD4F5',
              buttons: {
                confirm: {
                  confirmButtonColor: '#8CD4F5',
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-Ok'
                }
              }
            });
          });
      }
    });
  };

  onFilter = async dataChips => {
    dataChips = dataChips || [];
    findAllPropostas()
      .then(async res => {
        var data = res.data.map(async item => {
          item.nmSituacao = ['Aberta', 'Perdida', 'Contrato'][item.stProposta];
          item.nmCliente = item.cliente.nmCliente;
          item.nmLoja = item.loja.nmLoja;
          item.nmUsuario = item.vendedor.nmUsuario;
          return item;
        });
        const propostas = await Promise.all(data);
        this.setState({
          dataInTable: FilterUtil.applyFilters(propostas, dataChips),
          dataAppSync: propostas
        });
      })
      .catch(err => {
        SwalMessage({
          title: 'Falha',
          text: 'Falha ao carregar página',
          err,
          callback: () => {
            this._isMounted && this.setState({ dataAppSync: [] });
          }
        });
      });
  };

  onSelect = selected => {
    if (this._isMounted === true) {
      this.setState({
        selected: selected
      });
    }
  };
  onFilterChanged = dataChips => {
    this.setState({
      dataInTable: FilterUtil.applyFilters(this.state.dataAppSync, dataChips)
    });
  };
  componentDidMount() {
    this.props.dispatch({
      type: 'CLEAN_CHIPS'
    });
    this._isMounted = true;
    this.onFilter();
  }

  render() {
    const { classes } = this.props;
    const pagination = {
      length: this.state.dataInTable.length,
      page: 0
    };

    let operationValues = this.state.operationValues;
    let headData = head;
    let keyData = 0;
    if (this.props.usuarioLogado && this.props.usuarioLogado.tpUsuario === 0) {
      headData = headAdmin;
      operationValues = [this.operationNumero, ...this.operationAdmin, ...this.operationValues];
      keyData = 1;
    } else if (this.props.usuarioLogado && this.props.usuarioLogado.tpUsuario === 1) {
      headData = headGerente;
      operationValues = [this.operationNumero, ...this.operationGerente, ...this.operationValues];
      keyData = 2;
    }

    return (
      <div className={classes.mainDiv}>
        <div>
          <CardFilters
            filtros={operationValues}
            onFilter={this.onFilter}
            onFilterChanged={this.onFilterChanged}
            usePaddings={false}
            viewKey='lojas'
          ></CardFilters>
        </div>
        <div className={classes.buttonGrid}>
          <GroupButtonGrid
            customButtons={
              <ButtonGrid
                show={true}
                icon={<SvgIconeContratos color='#fff' />}
                color={'#5F4C3E'}
                disabled={this.state.selected === undefined || this.state.selected.stProposta === 2}
                onClick={this.handleContract}
              />
            }
            showAdd={false}
            showEdit={true}
            showDelete={false}
            disableEdit={this.state.selected === undefined}
            onClickEdit={this.handleEdit}
            deleteColor='#5F4C3E'
            editColor='#5F4C3E'
          />
          <DataTable
            key={keyData}
            marginTop
            data={this.state.dataInTable}
            columns={headData}
            userlang={getUserLang()}
            selectChange={this.onSelect}
            showPagination={true}
            pagination={pagination}
            handleChangePage={() => {}}
            handleChangeRowsPerPage={() => {}}
          />
        </div>

        {this.props.usuarioLogado && this.props.usuarioLogado.tpUsuario !== 0 && (
          <div className={classes.fab} onClick={this.handleAdd}>
            <FabDiamond
              url={iconAdd}
              imageStyle={{
                height: '28px',
                width: '28px',
                padding: '5px 5px 0px 0px'
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  usuarioLogado: state.usuarioReducer,
  itensState: state.toolbar,
  estadoList: state.estadoReducer,
  municipioList: state.municipioReducer,
  getMunicipio: state.getMunicipio,
  lojaList: state.lojaReducer
});
let enhanced = withStyles(style)(Proposta);
export default connect(mapStateToProps)(withRouter(enhanced));
