export default {
  editarItem: {
    title: 'Editar Ambiente',
    cod: 'Código*',
    nome: 'Nome*',
    observacoes: 'Observações *',
    campoObrigatorio: 'Campo Obrigatório',
    identificacao: 'Identificação',
  },
}
