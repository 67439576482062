/*
    Autor: Bruno Eduardo
*/
import ptBR from './ptBR';
import enUS from './enUS';
import esES from './esES';
import flattenMessages from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';

function getIntlProvider() {
  const messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

  const messagesLinguage = flattenMessages(messages[getUserLang()]);
  const atencao = messagesLinguage['atencao'];
  const confirmar = messagesLinguage['confirmar'];
  const cancelar = messagesLinguage['cancelar'];

  const intlProvider = new IntlProvider(
    {
      locale: getUserLang(),
      messages: { atencao, confirmar, cancelar }
    },
    {}
  );

  return intlProvider.getChildContext();
}

export default getIntlProvider;
