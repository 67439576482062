export default {
  menu: {
    municipio: "City",
    comunidades: "Community",
    nivel: "Technological level",
    tecnicos: "Technical",
    tecnico: "Technical",
    produtores: "Producers",
    nematicidasMicrobiologicos: "Microbiological nematicides",
    foliares: "Foliar",
    comparacao: "Comparison between technicians",
    acompanhamento: "monitoring",
    culturas: "Cultures",
    cultivares: "Cultivars",
    fungicidas: "Fungicides",
    fungicidasMicroBio: "Microbiological fungicides",
    inseticidas: "Insecticides",
    inseticidasMicroBio: "Microbiological insecticides",
    cotacoes: "Quotation",
    planos: "Plans",
    bactericidasMicrobiologicos: "Microbiological bactericides",
    nematicidas: "Nematicides",
    agentesBiologicosControle: "Biological agents",
    clientes: "Customers",
    herbicidas: "Herbicides",
    acaricidas: "Acaricides",
    bactericidas: "Bactericidal",
    acaricidasMicrobio: "Microbiological acaricides",
    marcas: "Brands",
    cadastro: "Register",
    fertilizantes: "Leaf and Adjuvant",
    analise: "Analyzes",
    pessoas: "People",
    localizacao: "Location",
    produtos: "Products",
    acaros: "Mites",
    bacterias: "Bacteria",
    ervasDaninhas: "Weeds",
    fungos: "Fungi",
    insetos: "Insects",
    nematoides: "Nematodes",
    agenda: "Schedule",
    admin: "Administration",
    pesticidas: "Pesticides",
    acompanhamentoProdutor: "Follow-up to the producer",
    produtor: "Producer",
    financeiro: "Financial",
    revenda: "Manager",
    oleosAdjuvantes: "Oils and Adjuvants",
    agendaVisitas: "Visits Schedule"
  }
};
