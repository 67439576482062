import axios from 'axios'

const BASEAPI = process.env.REACT_APP_URL_API
const ENDPOINT = '/parceiro'

const getAll = async () => {
  return await axios.get(BASEAPI + ENDPOINT)
}

const getById = async id => {
  return await axios.get(`${BASEAPI + ENDPOINT}/${id}`)
}

const insert = async data => {
  return await axios.post(BASEAPI + ENDPOINT, data)
}

const update = async data => {
  return await axios.put(BASEAPI + ENDPOINT, data)
}

const deleteParceiro = async id => {
  return await axios.delete(`${BASEAPI + ENDPOINT}/${id}`)
}

export { getAll, getById, insert, update, deleteParceiro }
