export default {
    filtrosDeListagem: {
        filtros:'Filtros',
        tipoDeFiltro: 'Tipo de filtro', 
        tipoDeComparacao: 'Tipo de Comparación',
        pesquisa: 'Búsqueda',
        filtrosAdicionados: 'Filtros agregados',
        filtrar: 'Filtro',
        igual:'Igual',
        maiorOuIgual: 'Mayor o igual',
        maior: 'Mayor',
        menorOuIgual:'Menor o igual',
        menor:'Menor',
        diferente: 'No es igual',
        contendo:'Contiene',
        campoObrigatorio: 'Campo obligatorio',
        filtroDuplicado: 'Filtro duplicado',
    }
    
}