const { getPerfil } = require('@resources/api/perfil')

const getUsuarioLogado = usuario => {
  return async dispatch => {
    await getPerfil().then(res => {
      usuario = res.data
      dispatch({
        type: 'ADD_USER_INFO',
        info: usuario,
      })
    })

    return usuario
  }
}
export { getUsuarioLogado }
