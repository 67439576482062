export default {
  menu: {
    municipio: "Municípios",
    comunidades: "Comunidades",
    nivel: "Nível tecnologico",
    tecnicos: "Técnicos",
    tecnico: "Técnico",
    produtores: "Produtores",
    nematicidasMicrobiologicos: "Nematicidas microbiológicas",
    foliares: "Hoja",
    comparacao: "Comparación entre técnicos",
    acompanhamento: "Acompañamiento",
    culturas: "Culturas",
    cultivares: "Cultivares",
    fungicidas: "Fungicidas",
    inseticidasMicroBio: "Insecticidas microbiológicas",
    fungicidasMicroBio: "Fungicidas Microbiológicos",
    inseticidas: "Insecticidas",
    cotacoes: "Cotações",
    planos: "Planes",
    bactericidasMicrobiologicos: "Bactericidas microbiológicos",
    nematicidas: "Nematicidas",
    agentesBiologicosControle: "Agentes biológicos",
    clientes: "Clientes",
    herbicidas: "Herbicidas",
    acaricidas: "Acaricidas",
    bactericidas: "Bactericidas",
    acaricidasMicrobio: "Acaricidas microbiológicas",
    marcas: "Marcas",
    cadastro: "Catastro",
    fertilizantes: "Hoja y adyuvante",
    analise: "Análisis",
    pessoas: "Personas",
    localizacao: "Ubicación",
    produtos: "Productos",
    acaros: "Ácaros",
    bacterias: "Bacterias",
    ervasDaninhas: "Malas hierbas",
    fungos: "Hongos",
    insetos: "Insectos",
    nematoides: "Nematodos",
    agenda: "Orden del día",
    admin: "Administración",
    pesticidas: "Pesticidas",
    acompanhamentoProdutor: "Acompañamiento al productor",
    produtor: "Productor",
    financeiro: "Financeiro",
    revenda: "Gerente",
    oleosAdjuvantes: "Aceites y Adyuvantes",
    agendaVisitas: "Horario de visitas"
  }
};
