import axios from 'axios';

const BASEAPI = process.env.REACT_APP_URL_API;
const ENDPOINT = '/observacao';

const getAllObservacao = async id => {
  return await axios.get(`${BASEAPI + ENDPOINT}`);
};

export { getAllObservacao };
