import { getAll as getAllTecnicos } from '@resources/api/tecnico';

const getTecnico = (list, id) => {
  return async dispatch => {
    var tecnico;
    if (list.length > 0) {
      tecnico = list.find(el => {
        return el.idUsuario === id;
      });
    }

    if (tecnico === undefined) {
      await getAllTecnicos().then(res => {
        tecnico = res.data.find(el => {
          return el.idUsuario === id;
        });
        list = res.data;
        dispatch({
          type: 'ADD_TECNICOS',
          list: list
        });
      });
    }

    return tecnico.nmUsuario;
  };
};

const getTecnicos = list => {
  return async dispatch => {
    if (list.length > 0) {
      return list;
    }

    await getAllTecnicos().then(res => {
      list = res.data;
      dispatch({
        type: 'ADD_TECNICOS',
        list: list
      });
    });

    return list;
  };
};

const refreshTecnicoList = list => {
  return async dispatch => {
    await getAllTecnicos().then(res => {
      list = res.data;
      dispatch({
        type: 'ADD_TECNICOS',
        list: list
      });
    });
    return list;
  };
};
export { getTecnico, getTecnicos, refreshTecnicoList };
