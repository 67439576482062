import moment from 'moment';
import { formatCurrency } from '@utils/functions/FormatCurrency/formatCurrency';

const contrato = {
  field: 'nrContrato',
  headerName: 'Nº',
  col: 1,
  sort: 'desc'
};
const dhEntrega = {
  valueGetter: args => {
    if (!args.node.data.dhEntregue) return '';

    return moment(args.node.data.dhEntregue.split('T')[0]).format('DD/MM/YY');
  },
  headerName: 'Entrega',
  col: 2
};
const valor = {
  valueGetter: args =>
    formatCurrency(parseFloat(args.node.data.vlTotal))
      .replace('R$', '')
      .trim(),
  headerName: 'Valor',
  col: 2
};
const situacao = {
  field: 'nmSituacao',
  headerName: 'Situação',
  col: 2
};

const head = [
  contrato,
  {
    valueGetter: args => {
      if (!args.node.data.dhCriacao) return '';

      return moment(args.node.data.dhCriacao.split('T')[0]).format('DD/MM/YY');
    },
    headerName: 'Data',
    col: 2
  },
  dhEntrega,
  {
    valueGetter: args => args.node.data.cliente.nmCliente,
    headerName: 'Cliente',
    col: 2
  },
  valor,
  situacao
];

const headGerente = [
  contrato,
  {
    valueGetter: args => args.node.data.vendedor.nmUsuario,
    headerName: 'Consultor',
    col: 2
  },
  {
    valueGetter: args => {
      if (!args.node.data.dhCriacao) return '';

      return moment(args.node.data.dhCriacao.split('T')[0]).format('DD/MM/YY');
    },
    headerName: 'Data',
    col: 1
  },
  dhEntrega,
  {
    valueGetter: args => args.node.data.cliente.nmCliente,
    headerName: 'Cliente',
    col: 2
  },
  valor,
  situacao
];

const headAdmin = [
  contrato,
  {
    valueGetter: args => args.node.data.loja.nmLoja,
    headerName: 'Loja',
    col: 1
  },
  {
    valueGetter: args => args.node.data.vendedor.nmUsuario,
    headerName: 'Consultor',
    col: 2
  },
  {
    valueGetter: args => {
      if (!args.node.data.dhCriacao) return '';

      return moment(args.node.data.dhCriacao.split('T')[0]).format('DD/MM/YY');
    },
    headerName: 'Data',
    col: 1
  },
  {
    valueGetter: args => {
      if (!args.node.data.dhEntregue) return '';

      return moment(args.node.data.dhEntregue.split('T')[0]).format('DD/MM/YY');
    },
    headerName: 'Entrega',
    col: 1
  },
  {
    valueGetter: args => args.node.data.cliente.nmCliente,
    headerName: 'Cliente',
    col: 2
  },
  valor,
  situacao
];

export { head, headGerente, headAdmin };
