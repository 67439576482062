var clienteList = []

const adicionaListaDeCliente = (state = clienteList, action) => {
  switch (action.type) {
    case 'ADD_CLIENTES':
      clienteList = action.list
      return clienteList
    default:
      return state
  }
}

export default adicionaListaDeCliente
