export default {
  parceiro: {
    title: 'PARCEIROS',
    tel: 'Telefone',
    nomeCol: 'Nome',
    email: 'E-mail',
    uf: 'Estado',
    cidade: 'Cidade',
  },
}
