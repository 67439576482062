export default {
  menu: {
    fabrica: 'Fábrica',
    contratos: 'Contratos',
    propostas: 'Propostas',
    visitas: 'Visitas',
    metas: 'Metas',
    ambientes: 'Ambientes',
    acabamentos: 'Acabamentos',
    clientes: 'Clientes',
    parceiros: 'Parceiros',
    lojas: 'Lojas',
    gerentes: 'Gerentes',
    consultor: 'Consultores',
    resumoContrato: 'Resumo contrato',
    admnistradores: 'Administradores',
    resumoProposta: 'Resumo de proposta',
    acompanhamentoMetas: 'Acompanhamento de metas',
    configuracaoAgenda: 'Configuração',
    projetos: 'Projetos',
    tecnicos: 'Técnicos'
  }
};
