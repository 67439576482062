var usuarioLogado = null

const adicionaUsuarioLogado = (state = usuarioLogado, action) => {
  switch (action.type) {
    case 'ADD_USER_INFO':
      usuarioLogado = action.info
      return usuarioLogado
    default:
      return state
  }
}

export default adicionaUsuarioLogado
