import * as moment from 'moment'

const toDate = date => {
  return moment(date).toDate()
}

const toLocalDate = date => {
  var format = 'YYYY-MM-DD'
  var dateUtc = moment.utc(date)
  var localDate = dateUtc.local()
  return localDate.format(format)
}

const formatDate = date => {
  return moment(date).format('DD/MM/YYYY')
}

const formatDateToHhMm = date => {
  const split = date.split('[')[0]
  return moment(split).format('HH:mm')
}

const millisecondsToDate = milliseconds => {
  var date = new Date(milliseconds)
  return moment(date).format('DD/MM/YYYY')
}

export { toDate, toLocalDate, formatDate, formatDateToHhMm, millisecondsToDate }
