import axios from 'axios'

const BASEAPI = process.env.REACT_APP_URL_API
const ENDPOINT = '/visita'

const getAll = async () => {
  return await axios.get(BASEAPI + ENDPOINT)
}
const getById = async id => {
  return await axios.get(`${BASEAPI + ENDPOINT}/${id}`)
}

export { getAll, getById }
