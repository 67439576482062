import axios from 'axios'

const BASEAPI = process.env.REACT_APP_URL_API
const ENDPOINT = '/uf'

const getAllEstados = async () => {
  return await axios.get(BASEAPI + ENDPOINT)
}

export { getAllEstados }
