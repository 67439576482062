import moment from 'moment';

const contrato = {
  field: 'nrContrato',
  headerName: 'Contrato',
  col: 1
};
const ambiente = {
  field: 'nmAmbiente',
  headerName: 'Ambiente',
  col: 2
};
const acabamento = {
  field: 'nmAcabamento',
  headerName: 'Acabamento',
  col: 2
};

const dhEntrega = {
  valueGetter: args => {
    if (!args.node.data.dtPrevisaoEntrega) return '';

    return moment(args.node.data.dtPrevisaoEntrega).format('DD/MM/YYYY');
  },
  headerName: 'Data',
  col: 2
};

const situacao = {
  field: 'nmItemContrato',
  headerName: 'Aprov.',
  col: 1
};

const head = [
  {
    valueGetter: args => args.node.data.nmCliente,
    headerName: 'Cliente',
    col: 4
  },
  contrato,
  dhEntrega,
  ambiente,
  acabamento,
  situacao
];

const headGerente = [
  {
    valueGetter: args => args.node.data.nmCliente,
    headerName: 'Cliente',
    col: 2
  },
  {
    valueGetter: args => args.node.data.nmUsuario,
    headerName: 'Consultor',
    col: 2
  },
  contrato,
  dhEntrega,
  ambiente,
  acabamento,
  situacao
];

const headAdmin = [
  {
    valueGetter: args => args.node.data.nmLoja,
    headerName: 'Loja',
    col: 1
  },
  {
    valueGetter: args => args.node.data.nmCliente,
    headerName: 'Cliente',
    col: 2
  },
  {
    valueGetter: args => args.node.data.nmUsuario,
    headerName: 'Consultor',
    col: 2
  },
  contrato,
  {
    valueGetter: args => {
      if (!args.node.data.dtPrevisaoEntrega) return '';

      return moment(args.node.data.dtPrevisaoEntrega).format('DD/MM/YYYY');
    },
    headerName: 'Data',
    col: 1
  },
  ambiente,
  acabamento,
  situacao
];

export { head, headGerente, headAdmin };
